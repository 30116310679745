import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {ErrorMessage} from './errors.types';
import MedeinaVariables from '@/util/variables';

// Values are mapped to string keys for easier translation
// common.json
const ERROR_MESSAGES: {[key: number]: ErrorMessage} = {
    429: {
        learnMoreText: 'Errors.LearnMoreCapacityMaxedOut',
        message: 'Errors.CapacityMaxedOut',
        learnMoreUri: MedeinaVariables.ManageCapacityLearnUri,
    },
    403: {
        message: 'Errors.NoPermissionForFeature',
    },
    // ... Add other status codes as needed
};

export function getErrorMessage(statusCode: number) {
    if (statusCode && ERROR_MESSAGES[statusCode]) {
        return ERROR_MESSAGES[statusCode];
    }

    // Default error message
    return {message: 'Errors.DefaultPromptSubmitError'};
}
// Returns a localized version of the error message based on the status code
export function useErrorMessages() {
    const {t} = useTranslation('common');

    const getLocaleMessage = useCallback(
        (statusCode: number) => {
            const errorMessage = getErrorMessage(statusCode);
            errorMessage.message = t(errorMessage.message);
            if (errorMessage.learnMoreText) {
                errorMessage.learnMoreText = t(errorMessage.learnMoreText);
            }
            // Default error message
            return errorMessage;
        },
        [t],
    );

    return {
        getErrorMessage: getLocaleMessage,
    };
}
