import {makeStaticStyles, makeStyles, shorthands, tokens} from '@fluentui/react-components';
export const useStaticStyles = makeStaticStyles([
    {
        body: {
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            margin: 0,
            overflowX: 'hidden',
            backgroundColor: tokens.colorNeutralBackground2,
        },
    },
]);

export const SidePanelWidth = 590;
export const NavigationBarHeight = 42;

export default makeStyles({
    root: {
        backgroundColor: tokens.colorNeutralBackground2,
    },
    navigation: {
        ...shorthands.flex(0, 0, 'auto'),
        position: 'fixed',
        width: '100%',
        top: 0,
    },
    mainWrapper: {
        width: '100%',
        height: `calc(100vh - 48px)`,
        position: 'fixed',
        marginTop: `${NavigationBarHeight}px`,
    },
    contentWrapper: {
        width: '100%',
        height: `calc(100vh - 48px)`,
        position: 'relative',
    },
    untrustedWrapper: {
        height: `calc(100vh - 98px)`,
    },
    untrustedMainWrapper: {
        marginTop: `98px`,
    },
    main: {
        flexGrow: 1,
        overflowY: 'auto' /* Make content area scrollable */,
        height: `100%`,
        backgroundColor: 'transparent',
        position: 'relative',
        flexShrink: 1,

        '@media (max-width: 479px)': {
            paddingLeft: tokens.spacingHorizontalXS,
            paddingRight: tokens.spacingHorizontalXS,
        },
    },
    sidePanel: {
        backgroundImage: `linear-gradient(180deg, ${tokens.colorNeutralBackground1} 0%, ${tokens.colorNeutralBackground2} 100%)`,
        position: 'fixed',
        top: `${NavigationBarHeight}px`,
        right: 0,
        width: `${SidePanelWidth}px`,
        height: `calc(100vh - 48px)`,
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.overflow('hidden'),
    },
    controls: {
        position: 'absolute',
        bottom: '30px',
        right: '30px',
        zIndex: 2,
        display: 'flex',
        alignItems: 'center',
    },
    gradientBackground: {
        backgroundColor: 'transparent',
    },
    firstRunMain: {
        height: '100vh',
        position: 'absolute',
        marginTop: 0,
    },
    firstRunMainWrapper: {
        marginTop: 0,
        height: '100vh',
    },
    helpMenu: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 'auto',
        justifyContent: 'flex-end',
        position: 'fixed',
        right: tokens.spacingHorizontalXXXL,
        bottom: tokens.spacingVerticalXXXL,
        zIndex: 4,
    },
    listBoxHeight: {
        maxHeight: '200px',
        height: 'auto',
    },
});
