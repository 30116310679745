import React from 'react';
import {InfoLabel, InfoLabelProps} from '@fluentui/react-components';
import useClasses from './MedeinaInfoLabel.styles';

export interface MedeinaInfoLabelProps {
    labelContent: React.ReactNode;
    infoContent: React.ReactNode;
    defaultButtonWrap?: boolean;
    size?: InfoLabelProps['size'];
}

/**
 * Wraps the InfoLabel component with accessibility attributes.
 * @param props {labelContent, infoContent, defaultButtonWrap, size}
 * @returns React Node
 */
export const MedeinaInfoLabel = ({
    labelContent,
    infoContent,
    defaultButtonWrap = false,
    size = 'medium',
}: MedeinaInfoLabelProps) => {
    const classes = useClasses();

    return (
        <InfoLabel
            role="infoLabel"
            className={defaultButtonWrap ? undefined : classes.infoLabel}
            info={
                <div aria-live="polite" className={classes.infoContent}>
                    {infoContent}
                </div>
            }
            size={size}
        >
            {labelContent}
        </InfoLabel>
    );
};
