import {IVerticalStackedChartProps} from '@fluentui/react-charting';
import {tokens} from '@fluentui/react-components';
import {
    CAPACITY_BUCKETS,
    CAPACITY_THRESHOLD_PERCENTAGE,
    DATETIME_FORMAT,
    UNIT_CAPACITY_STATUS,
    UNIT_PLURAL_TEXT,
} from './UsageDashboard.constants';
import {HourlyCapacityUsageStatus} from './UsageDashboard.types';
import {HourlyCapacityUsage} from '@/api/capacities/capacities.types';
import {formatLocaleDate as format} from '@/util/locale';
import {addHours} from 'date-fns';
import {TFunction} from 'i18next';

export function transformDataForGraph(
    data: HourlyCapacityUsage[],
    translate: TFunction,
): IVerticalStackedChartProps[] {
    return data.map((item) => {
        const usageData = calculateUsage(item);
        return createGraphItem(item, usageData, translate);
    });
}

export function transformDataForDataTable(
    data: HourlyCapacityUsage[],
): HourlyCapacityUsageStatus[] {
    return data?.map((item) => {
        const usageData = calculateUsage(item);
        return createDataTableItem(item, usageData.usageBucket);
    });
}

const getThresholdCapacity = (maxAllowedCapacity: number): number =>
    CAPACITY_THRESHOLD_PERCENTAGE * maxAllowedCapacity;

const calculateUsage = (item: HourlyCapacityUsage) => {
    const thresholdCapacity = roundToOneDecimal(getThresholdCapacity(item.maxAllowedCapacity));

    let usageBelowThreshold;
    let usageAboveThreshold;
    let usageThrottled;
    let usageBucket;

    // If usage within threshold
    if (item.usedCapacity < thresholdCapacity) {
        usageBelowThreshold = roundToOneDecimal(item.usedCapacity);
        usageAboveThreshold = 0;
        usageThrottled = 0;
        usageBucket = CAPACITY_BUCKETS.BELOW_THRESHOLD;
    }
    // If usage between threshold and max allowed capacity
    else if (
        item.usedCapacity >= thresholdCapacity &&
        item.usedCapacity < item.maxAllowedCapacity
    ) {
        usageBelowThreshold = thresholdCapacity;
        usageAboveThreshold = roundToOneDecimal(item.usedCapacity - thresholdCapacity);
        usageThrottled = 0;
        usageBucket = CAPACITY_BUCKETS.ABOVE_THRESHOLD;
    }
    // If usage exceeds max allowed capacity
    else {
        usageBelowThreshold = thresholdCapacity;
        usageAboveThreshold = roundToOneDecimal(item.maxAllowedCapacity - thresholdCapacity);
        usageThrottled = roundToOneDecimal(item.usedCapacity - item.maxAllowedCapacity);
        usageBucket = CAPACITY_BUCKETS.THROTTLED;
    }

    return {usageBelowThreshold, usageAboveThreshold, usageThrottled, usageBucket};
};

// Graph data - representing each of three bars (teal, yellow, red)
const createGraphItem = (
    item: HourlyCapacityUsage,
    usageData: ReturnType<typeof calculateUsage>,
    translate: TFunction,
): IVerticalStackedChartProps => {
    const {usageBelowThreshold, usageAboveThreshold, usageThrottled, usageBucket} = usageData;

    // Convert input UTC date to user timezone date
    const zonedDate = new Date(getZonedDate(item.aggregateStartTime));
    const stackAccessibilityData = getStackAccessibilityData(
        usageData,
        zonedDate,
        item.assignedCapacity,
        translate,
    );

    return {
        chartData: [
            {
                legend: CAPACITY_BUCKETS.BELOW_THRESHOLD,
                data: usageBelowThreshold,
                color: tokens.colorBrandForegroundLink,
            },
            {
                legend: CAPACITY_BUCKETS.ABOVE_THRESHOLD,
                data: usageAboveThreshold,
                color: tokens.colorPalettePeachBorderActive,
            },
            {
                legend: CAPACITY_BUCKETS.THROTTLED,
                data: usageThrottled,
                color: tokens.colorStatusDangerBackground3,
            },
        ],
        xAxisPoint: zonedDate, // BarChart doesn't respect string for custom formatting
        xAxisCalloutData: usageBucket, // Using as a placeholder to show usage status on custom callout
        lineData: [
            {
                y: item.assignedCapacity,
                legend: CAPACITY_BUCKETS.ASSIGNED_CAPACITY,
                color: tokens.colorStrokeFocus2,
            },
        ],
        // Override is required as xAxisCalloutData, used as a placeholder for the custom hover card component, also gets read.
        // The usageBucket, being a key for translation text rather than suitable display text, needs to be replaced.
        stackCallOutAccessibilityData: {
            ariaLabel: stackAccessibilityData,
        },
    };
};

// Table data - representing each row and used as key to identify unit status amongst below, within or above threshold
const createDataTableItem = (
    item: HourlyCapacityUsage,
    usageBucket: string,
): HourlyCapacityUsageStatus => {
    // Convert input UTC date to user timezone date
    const zonedDate = new Date(getZonedDate(item.aggregateStartTime));
    const matchingStatus = Object.entries(UNIT_CAPACITY_STATUS).find(
        ([key, value]) => key === usageBucket,
    );

    return {
        startTime: zonedDate,
        usageValue: roundToOneDecimal(item.usedCapacity),
        usageStatus: {
            icon: (matchingStatus?.[1].icon as JSX.Element) ?? '',
            status: matchingStatus?.[1].status ?? '',
        },
    };
};

const getZonedDate = (date: string): string => {
    const zonedDate = format(date, DATETIME_FORMAT.ISO_STRING);

    return zonedDate;
};

export const getDayName = (date: Date): string => {
    return format(date, DATETIME_FORMAT.BARCHART_HOVERCARD_DAYNAME);
};

export const getHourlyTimeframe = (date: Date): string => {
    const formattedHour = format(date, DATETIME_FORMAT.BARCHART_HOVERCARD_TIMEFRAME);
    const nextHour = format(addHours(date, 1), DATETIME_FORMAT.BARCHART_HOVERCARD_TIMEFRAME);

    return `${formattedHour} - ${nextHour}`;
};

function getStackAccessibilityData(
    usageData: ReturnType<typeof calculateUsage>,
    date: Date,
    assignedCapacity: number,
    t: TFunction,
): string {
    const {usageBelowThreshold, usageAboveThreshold, usageThrottled, usageBucket} = usageData;

    const matchingStatus = Object.entries(UNIT_CAPACITY_STATUS).find(
        ([key, value]) => key === usageBucket,
    );

    const usageBucketText = `${t(matchingStatus?.[1].ariaLabel ?? '')}`;
    const usageBelowThresholdText = `${t(
        CAPACITY_BUCKETS.BELOW_THRESHOLD,
    )} ${usageBelowThreshold} ${t(UNIT_PLURAL_TEXT)}`;
    const usageAboveThresholdText = `${t(
        CAPACITY_BUCKETS.ABOVE_THRESHOLD,
    )} ${usageAboveThreshold} ${t(UNIT_PLURAL_TEXT)}`;
    const usageThrottledText = `${t(CAPACITY_BUCKETS.THROTTLED)} ${usageThrottled} ${t(
        UNIT_PLURAL_TEXT,
    )}`;
    const assignedCapacityText = `${t(CAPACITY_BUCKETS.ASSIGNED_CAPACITY)} ${assignedCapacity} ${t(
        UNIT_PLURAL_TEXT,
    )}`;

    const dayName = getDayName(date);
    const timeframe = getHourlyTimeframe(date);

    // Dots added to delay narrator.
    return `${usageBucketText}. ${dayName} from ${timeframe} . ${usageBelowThresholdText}. ${usageAboveThresholdText}. ${usageThrottledText}. ${assignedCapacityText}`;
}

// Number.Epsilon adds a small value to the number to avoid floating point arithmetic errors
export const roundToOneDecimal = (value: number): number =>
    Math.round((value + Number.EPSILON) * 10) / 10;
