import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    titleText: {
        marginTop: tokens.spacingVerticalXXL,
        marginInlineStart: tokens.spacingHorizontalL,
        marginInlineEnd: tokens.spacingHorizontalXL,
        fontSize: tokens.fontSizeBase600,
        lineHeight: tokens.lineHeightBase600,
        fontWeight: tokens.fontWeightSemibold,
        color: tokens.colorNeutralForeground1,
    },
    modalButton: {
        position: 'absolute',
        top: tokens.spacingVerticalXXXL,
        left: tokens.spacingHorizontalNone,
    },
    infoLabel: {
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase300,
        paddingBottom: tokens.spacingVerticalXS,
    },
    input: {
        marginLeft: tokens.spacingHorizontalM,
        paddingBottom: tokens.spacingVerticalM,
        maxHeight: '72px',
    },
    content: {
        width: '100%',
    },
    dialogContent: {
        width: '100%',
        maxHeight: '72%',
    },
    grid: {
        width: '100%',
    },
    textWrapper: {
        display: 'flex',
        marginLeft: tokens.spacingHorizontalM,
    },
    textareaWrapper: {
        fontSize: tokens.fontSizeBase300,
        ...shorthands.flex(1, 1, 'auto'),
        width: '70%',
        marginLeft: tokens.spacingHorizontalM,

        '@media (min-width: 480px) and (max-width: 639px)': {
            width: '90%',
        },
        '@media (max-width: 479px)': {
            width: '90%',
        },
    },
    longTextareaWraper: {
        fontSize: tokens.fontSizeBase300,
        ...shorthands.flex(1, 1, 'auto'),
        width: '95%',
        marginLeft: tokens.spacingHorizontalM,
    },
    form: {
        ...shorthands.flex(1, 1, 'auto'),
        display: 'flex',
        flexDirection: 'row',
        columnGap: tokens.spacingHorizontalM,
        paddingBottom: tokens.spacingVerticalM,
    },
    sectionHeading: {
        ...shorthands.borderBottom('1px', 'solid', tokens.colorNeutralBackground1),
        ...shorthands.borderRadius(tokens.borderRadiusXLarge, tokens.borderRadiusXLarge, 0, 0),
        display: 'flex',
        backgroundColor: tokens.colorNeutralBackground6,
        paddingLeft: tokens.spacingHorizontalL,
        paddingRight: tokens.spacingHorizontalM,
        paddingTop: tokens.spacingVerticalS,
        paddingBottom: tokens.spacingVerticalS,
    },
    sectionBody: {
        paddingTop: tokens.spacingVerticalL,
        paddingLeft: tokens.spacingHorizontalM,
        paddingRight: tokens.spacingHorizontalM,
        backgroundColor: tokens.colorNeutralBackground5,
        ...shorthands.borderRadius(0, 0, tokens.borderRadiusXLarge, tokens.borderRadiusXLarge),

        '@media (min-width: 480px) and (max-width: 639px)': {
            paddingLeft: tokens.spacingHorizontalS,
            paddingRight: tokens.spacingHorizontalS,
        },
        '@media (max-width: 479px)': {
            paddingLeft: tokens.spacingHorizontalS,
            paddingRight: tokens.spacingHorizontalS,
        },
    },
    headingTextCol: {
        width: '80%',
        marginTop: tokens.spacingVerticalXS,
        marginLeft: tokens.spacingHorizontalS,
    },
    headingText: {
        fontSize: '20px',
        lineHeight: tokens.lineHeightBase500,
        color: tokens.colorNeutralForeground1,
        fontWeight: tokens.fontWeightSemibold,
        paddingTop: tokens.spacingVerticalS,
        paddingBottom: tokens.spacingVerticalS,
    },
    configText: {
        marginLeft: tokens.spacingHorizontalM,
        marginBottom: tokens.spacingVerticalXS,
        color: tokens.colorNeutralForeground1,
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase300,
        fontWeight: tokens.fontWeightSemibold,
    },
    configSubtext: {
        marginLeft: tokens.spacingHorizontalM,
        fontSize: '16px',
        lineHeight: tokens.lineHeightBase400,
        fontWeight: tokens.fontWeightRegular,
        color: tokens.colorNeutralForeground2,
    },
    button: {
        ...shorthands.flex(1, 1, 'auto'),
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
        ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalXL),
        minWidth: '128px',
        minHeight: '48px',
    },
    cancelButton: {
        ...shorthands.flex(1, 1, 'auto'),
        ...shorthands.border('1px', 'solid', tokens.colorNeutralBackground6),
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        marginLeft: tokens.spacingHorizontalS,
    },
    triggerResetButton: {
        ...shorthands.flex(1, 1, 'auto'),
        ...shorthands.border('1px', 'solid', tokens.colorNeutralBackground5Selected),
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        marginLeft: tokens.spacingHorizontalS,
    },
    saveButton: {
        ...shorthands.flex(1, 1, 'auto'),
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        marginLeft: tokens.spacingHorizontalS,
    },
    topRow: {
        display: 'flex',
        ...shorthands.padding(0, 0, 0, tokens.spacingHorizontalXL),
    },
    subTextRow: {
        display: 'flex',
        marginTop: tokens.spacingVerticalMNudge,
        marginBottom: tokens.spacingVerticalMNudge,
    },
    subText: {
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightRegular,
        lineHeight: tokens.lineHeightBase400,
    },
    authType: {
        width: 'max-content',
    },
    pluginButtons: {
        display: 'flex',
        width: 'max-content',
        alignSelf: 'flex-start',
        position: 'absolute',
        bottom: tokens.spacingVerticalXL,
    },
    buttons: {
        display: 'flex',
        width: '-webkit-fill-available',
        alignSelf: 'flex-end',
        position: 'absolute',
        bottom: tokens.spacingVerticalXL,
        justifyContent: 'space-between',
        paddingLeft: tokens.spacingHorizontalXXL,
        paddingRight: tokens.spacingHorizontalXXL,

        '@media (min-width: 480px) and (max-width: 639px)': {
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: tokens.spacingVerticalXXL,
            paddingBottom: tokens.spacingVerticalXL,
            marginTop: 'auto',
            position: 'relative',
        },
        '@media (max-width: 479px)': {
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: tokens.spacingVerticalXXL,
            paddingBottom: tokens.spacingVerticalXL,
            marginTop: 'auto',
            position: 'relative',
        },
    },
    setupButtons: {
        justifyContent: 'space-between',
    },
    icon: {
        display: 'flex',
        marginTop: tokens.spacingVerticalXXL,
        marginLeft: tokens.spacingHorizontalL,
        marginRight: tokens.spacingHorizontalM,
    },
    loading: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        justifyContent: 'center',
    },
    savedButton: {
        color: tokens.colorPaletteGreenBackground3,
        alignSelf: 'center',
        height: tokens.lineHeightHero800,
        width: tokens.lineHeightHero800,
        marginBottom: tokens.spacingVerticalS,
    },
    failedButton: {
        color: tokens.colorPaletteRedBackground3,
        alignSelf: 'center',
        height: tokens.lineHeightHero800,
        width: tokens.lineHeightHero800,
        marginBottom: tokens.spacingVerticalS,
    },
    confirmationModal: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: '10%',
        paddingRight: '10%',
    },
    connectionIcon: {
        alignSelf: 'center',
        marginBottom: tokens.spacingVerticalM,
    },
    header: {
        width: '100%',
        minHeight: '120px',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 'auto',
    },
    returnButton: {
        height: tokens.spacingVerticalXXL,
        width: tokens.spacingHorizontalXXL,
        position: 'absolute',
        left: tokens.spacingHorizontalXXL,
        top: tokens.spacingHorizontalXXL,
    },
    titleFrame: {
        display: 'flex',
        width: '100%',
        minHeight: '40px',
        minWidth: '490px',
        paddingLeft: tokens.spacingHorizontalXXL,
        marginTop: tokens.spacingVerticalM,
        marginBottom: tokens.spacingVerticalM,
    },
    titleIcon: {
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalS),
    },
    oauthFrame: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '237px',
    },
    oauthFrameIcon: {
        backgroundColor: tokens.colorNeutralBackground4,
        minHeight: '237px',
        width: '100%',
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        marginBottom: tokens.spacingHorizontalMNudge,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    oauthFrameIconWhite: {
        backgroundColor: tokens.colorNeutralBackgroundInverted,
        height: '141px',
        width: '108px',
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    oauthFramIconGreySquare: {
        backgroundColor: tokens.colorNeutralForeground4,
        height: tokens.spacingVerticalXXXL,
        width: tokens.spacingHorizontalXXXL,
        ...shorthands.borderRadius(tokens.borderRadiusSmall),
        marginTop: tokens.spacingVerticalM,
        marginBottom: tokens.spacingVerticalM,
    },
    oauthFramIconGreyLine: {
        backgroundColor: tokens.colorNeutralForeground4,
        height: tokens.spacingVerticalSNudge,
        width: '84px',
        ...shorthands.borderRadius(tokens.borderRadiusSmall),
        marginBottom: tokens.spacingVerticalXS,
    },
    ouathFrameCheckIcon: {
        backgroundColor: tokens.colorBrandBackground,
        height: '18px',
        width: '84px',
        alignItems: 'center',
        alignSelf: 'bottom',
        justifySelf: 'bottom',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 'auto',
        ...shorthands.borderRadius(tokens.borderRadiusSmall),
        marginBottom: tokens.spacingVerticalM,
    },
    oauthFrameText: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: tokens.spacingVerticalL,
        paddingLeft: '10%',
        paddingRight: '10%',
    },
    configurationModalRoot: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    stickyTop: {
        //position: 'sticky',
    },
    nonStickySection: {
        overflowY: 'auto',

        '@media (min-width: 480px) and (max-width: 639px)': {
            overflowY: 'unset',
        },

        '@media (max-width: 479px)': {
            overflowY: 'unset',
        },
    },
    authModal: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '15%',
        paddingRight: '15%',
        height: '100%',
    },
    authSelect: {
        display: 'flex',
        flexDirection: 'column',
    },
    authFrame: {
        justifyContent: 'center',
        textAlign: 'center',
    },
    authText: {
        marginBottom: tokens.spacingVerticalXXXL,
        display: 'flex',
        flexDirection: 'column',
    },
    authTitleText: {
        fontSize: tokens.fontSizeBase500,
        lineHeight: tokens.lineHeightHero700,
        fontWeight: tokens.fontWeightRegular,
        marginBottom: tokens.spacingVerticalS,
        textAlign: 'center',
    },
    authSubText: {
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightRegular,
        lineHeight: tokens.lineHeightBase200,
        textAlign: 'center',
        color: tokens.colorNeutralForeground3,
    },
    authCompoundButton: {
        display: 'flex',
        width: '100%',
        height: '80px',
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        marginBottom: tokens.spacingVerticalL,
        backgroundColor: tokens.colorNeutralBackground5,
        ...shorthands.border('1px', 'solid', tokens.colorNeutralBackground6),
        justifyContent: 'left',
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground5Hover,
        },
    },
    authCompountButtonBase: {
        display: 'flex',
        width: '80%',
    },
    authIconPlaceholder: {
        height: '48px',
        width: '48px',
        backgroundColor: '#292929',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
    },
    authIcon: {
        color: tokens.colorBrandBackground2,
    },
    authButtonTextContainer: {
        width: '100%',
        textAlign: 'left',
        marginLeft: tokens.spacingHorizontalM,
    },
    authButtonText: {
        fontSize: '16px',
        fontWeight: tokens.fontWeightMedium,
        lineHeight: tokens.lineHeightBase400,
    },
    authSubButtonText: {
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightRegular,
        lineHeight: tokens.lineHeightBase300,
        color: tokens.colorNeutralForeground3,
    },
    resetIcon: {
        alignSelf: 'center',
        marginBottom: tokens.spacingVerticalL,
    },
    resetTitleText: {
        fontSize: tokens.fontSizeBase500,
        fontWeight: tokens.fontWeightRegular,
        marginBottom: tokens.spacingVerticalL,
        textAlign: 'center',
    },
    resetText: {
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightRegular,
        marginBottom: tokens.spacingVerticalL,
        textAlign: 'center',
        color: tokens.colorNeutralForeground3,
    },
    resetButtons: {
        marginTop: tokens.spacingVerticalL,
        alignSelf: 'center',
    },
    resetButton: {
        ...shorthands.flex(1, 1, 'auto'),
        ...shorthands.border('1px', 'solid', tokens.colorNeutralBackground6),
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        marginLeft: tokens.spacingHorizontalS,
        backgroundColor: tokens.colorPaletteRedBackground3,
        color: tokens.colorNeutralForegroundOnBrand,
    },
    deleteButton: {
        paddingTop: tokens.spacingVerticalXS,
        paddingBottom: tokens.spacingVerticalXS,
        marginRight: tokens.spacingHorizontalL,
        backgroundColor: tokens.colorPaletteRedBackground3,
        color: tokens.colorNeutralForegroundOnBrand,
    },
    uploadButton: {
        ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
        paddingTop: tokens.spacingVerticalXS,
        paddingBottom: tokens.spacingVerticalXS,
        backgroundColor: tokens.colorNeutralBackground1,
    },
    successText: {
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightRegular,
        color: tokens.colorNeutralForeground3,
    },
    pluginInfo: {
        marginTop: tokens.spacingVerticalM,
    },
    pluginInput: {
        marginLeft: tokens.spacingHorizontalM,
        paddingBottom: tokens.spacingVerticalL,
    },
    pluginTerms: {
        display: 'flex',
        width: 'max-content',
        alignSelf: 'flex-start',
        position: 'absolute',
        bottom: tokens.spacingVerticalM,
    },
    pluginTermsSubText: {
        fontSize: tokens.fontSizeBase200,
        fontWeight: tokens.fontWeightRegular,
        lineHeight: tokens.lineHeightBase400,
    },
});
