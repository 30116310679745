import {ScreenSize, useViewportSize} from '@/components/ui/Grid';
import MedeinaFeatures from '@/util/features';

const NON_RESPONSIVE_BAR_WIDTH = 10;
const LARGE_SCREEN_BAR_WIDTH = 15.17;
const SMALL_SCREEN_BAR_WIDTH = 8.17;
const BAR_RADIUS = 2;
const NON_RESPONSIVE_BAR_GAP_MAX = 2;
const RESPONSIVE_BAR_GAP_MAX = 4;

export default function useGetBarChartMeasurements() {
    const screenSize = useViewportSize();
    const showLargeBarWidth = screenSize.isXXLargeScreen || screenSize.isXXXLargeScreen;

    // The width of the bars is set to 10 for non-responsive, 15.17 for large screens, and 8.17 for small screens
    let barWidth = 0.0;
    if (MedeinaFeatures.UsageMonitoringV2) {
        barWidth = showLargeBarWidth ? LARGE_SCREEN_BAR_WIDTH : SMALL_SCREEN_BAR_WIDTH;
    } else {
        barWidth = NON_RESPONSIVE_BAR_WIDTH;
    }

    // The radius of the bars is set to 2
    const barRadius = BAR_RADIUS;

    // The gap between bars is set to 2 for non-responsive and 4 for responsive
    const barGapMax = MedeinaFeatures.UsageMonitoringV2
        ? RESPONSIVE_BAR_GAP_MAX
        : NON_RESPONSIVE_BAR_GAP_MAX;

    // The reflow is enabled if the feature flag is enabled
    const enableReflow = MedeinaFeatures.UsageMonitoringV2;

    return {
        barWidth,
        barRadius,
        barGapMax,
        enableReflow,
    };
}
