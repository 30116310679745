import {useMutation, useQueryClient} from '@tanstack/react-query';
import {GetWorkspaceByNameResponse, Workspace} from '.';
import MedeinaVariables from '@/util/variables';
import {customFetch} from '../api';
import {RESOURCE_SCOPES} from '../api.constants';
import {UpdateWorkspaceRequest} from './workspaces.types';

export default function useUpdateWorkspace() {
    const queryClient = useQueryClient();

    const getUrl = (workspaceName: string) =>
        `${MedeinaVariables.FidelisSecCopUri}/account/workspaces/${workspaceName}?api-version=2023-12-01-preview`;

    return useMutation({
        mutationFn: async (request: UpdateWorkspaceRequest) => {
            try {
                let workspaceData: Workspace;
                try {
                    workspaceData = await customFetch<GetWorkspaceByNameResponse>(
                        getUrl(request.name),
                        {
                            method: 'GET',
                            scopes: RESOURCE_SCOPES.FIDELIS,
                        },
                    );
                } catch (error) {
                    throw error;
                }

                if (!workspaceData) {
                    throw new Error('Workspace not found');
                }

                // We are explicity checking for an undefined value here because
                //we want to allow the user to set different attribute values to null if need be
                let updateRequest: Partial<Workspace> = {
                    capacity:
                        request.capacity === undefined ? workspaceData.capacity : request.capacity,
                    workspaceOptInConfig:
                        request.workspaceOptInConfig === undefined
                            ? workspaceData.workspaceOptInConfig
                            : request.workspaceOptInConfig,
                    crossGeoCompute:
                        request.crossGeoCompute === undefined
                            ? workspaceData.crossGeoCompute
                            : request.crossGeoCompute,
                };

                return await customFetch<GetWorkspaceByNameResponse>(getUrl(request.name), {
                    method: 'PUT',
                    body: updateRequest,
                    scopes: RESOURCE_SCOPES.FIDELIS,
                });
            } catch (e) {
                throw e;
            }
        },
        onSuccess: (data, variables, context) => {},
        onSettled: (data, error, variables, context) => {
            queryClient.removeQueries(['workspaces']);
            queryClient.removeQueries(['workspaces', variables.name]);
        },
        onError: (error, variables) => {},
        retry: 0,
    });
}
