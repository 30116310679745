import useClasses from './UsageDashboard.styles';
import {Button, Divider, Link, mergeClasses, Spinner, Tooltip} from '@fluentui/react-components';
import VStackedBarChart from './BarChart/VStackedBarChart';
import {
    ButtonLabels,
    ApiResponseDisplays,
    LEARN_MORE_LINK,
    USAGE_MONITORING_DESCRIPTION,
    UNIT_SINGLE_TEXT,
    UNIT_PLURAL_TEXT,
    PAGE_LOADING,
    USAGE_DATA_LOADING,
    BASE_UNITS_PER_HOUR,
    UsageMonitoringFilterLabels,
    DEFAULT_PAGE_NUMBER,
    CUSTOM_CAPACITY_USAGE_SEARCH_DATE_RANGE,
    DEFAULT_DATA_ORDER,
} from './UsageDashboard.constants';
import DateFilterDropdown from './DateFilterDropdown/DateFilterDropdown';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {DateRange} from './UsageDashboard.types';
import {useGetUserInfo} from '@/api/app';
import {useGetCapacityByName} from '@/api/capacities';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {ApiResponseDisplay} from './ApiResponseDisplay/ApiResponseDisplay';
import {subDays} from 'date-fns';
import {useTranslation} from 'react-i18next';
import MedeinaVariables from '@/util/variables';
import ChangeCapacity from '../ChangeCapacityDialog/ChangeCapacity';
import DataTableV2 from './DataTable/DataTableV2';
import {NumericDisplayEmphasis, ParagraphContent, SmallTitle} from '@/components/ui/Text';
import {GridContainer} from '@/components/ui/Grid';
import UsageDashboardTitle from './UsageDashboardTitle';
import MedeinaFeatures from '@/util/features';
import {FilterIcon} from '@/components/ui/icons';
import UsageDashboardFilterContainer from './Filter/UsageDashboardFilterContainer';
import useManageUsageDashboard from './useManageUsageDashboard';
import {DateFilterChangedProps} from './DateFilterDropdown/DateFilterDropdown.types';
import UsageDashboardDataExport from './UsageDashboardDataExport';
import useNavActions from '@/components/Navigation/useNavActions';

//TODO: Handle scenario when no capacity is linked to workspace
export default function UsageDashboardV2() {
    const classes = useClasses();
    const {t: tCommon} = useTranslation('common');
    const {t: tAdmin} = useTranslation('admin');
    const {data: user} = useGetUserInfo();

    const [filterStartDate, setFilterStartDate] = useState<Date>(new Date());
    const [isFilterUpdated, setIsFilterUpdated] = useState<boolean>(false);

    // To get the capacity name from workspace and
    const {capacityName, isProviderLoaded, isWorkspaceCapacityEAP} = useWorkspaceState();
    // Start and end time for fetching usage data
    const [dateRange, setDateRange] = useState<DateRange>({
        startDate: subDays(new Date(), 1),
        endDate: new Date(),
    });

    const onPageRefresh = () => {
        setDateRange({
            startDate: subDays(new Date(), 1),
            endDate: new Date(),
        });
    };

    // Used to open and close the change capacity dialog
    const changeCapacityButtonRef = useRef<HTMLButtonElement>(null);
    const [isChangeCapacityDialogOpen, setChangeCapacityDialogOpen] = useState<boolean>(false);
    const performActionsOnCapacityDialogClosure = () => {
        setChangeCapacityDialogOpen(false);
        changeCapacityButtonRef.current?.focus();
    };

    const {
        data: capacity,
        isFetching: isGetCapacityNameFetching,
        isError: isGetCapacityNameError,
        isFetched: isGetCapacityNameFetched,
    } = useGetCapacityByName(capacityName || '', {
        enabled: !!capacityName,
    });
    const capacityUnits = useMemo(() => {
        return capacity?.properties?.numberOfUnits ?? 0;
    }, [capacity]);

    const {
        getState,
        hourlyDataModel,
        dimensionalDataModel,
        facetDataModel,
        updateTimePeriod,
        updatePageNumber,
        updateFilters,
        pageLoading,
        isEvaluationAggregateDataLoading,
        pageError,
        lastUpdatedAt,
        dateRangeOption,
        updateSortDirection,
    } = useManageUsageDashboard({
        capacityName: capacityName!!,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        pageNumber: DEFAULT_PAGE_NUMBER,
        sortDirection: DEFAULT_DATA_ORDER,
    });

    const isCapacityUsageError = !capacityName || isGetCapacityNameError || pageError;
    const isCapacityUsageDataPresent =
        !pageLoading && !pageError && (hourlyDataModel?.value?.length ?? 0) > 0;

    // This will remove a flicker of loading state when the data is loaded for the first time
    const shouldShowLoadingState = !isProviderLoaded || pageLoading;

    const showChangeUnitsComponents = isProviderLoaded && !isWorkspaceCapacityEAP;

    // Check if the page is loaded completely. This takes into account all the dependencies
    // and ensures that the page is loaded only when all the data is fetched.
    const isDashboardLoaded = useMemo((): boolean => {
        return !isDashboardLoaded && isProviderLoaded && isGetCapacityNameFetched && !pageLoading;
    }, [isGetCapacityNameFetched, pageError, pageLoading, isEvaluationAggregateDataLoading]);

    const getCapacityUnitsText = () => {
        if (!capacityUnits) {
            return '';
        }
        const unitText = capacityUnits === 1 ? tAdmin(UNIT_SINGLE_TEXT) : tAdmin(UNIT_PLURAL_TEXT);
        return `${capacityUnits} ${unitText}`;
    };

    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const toggleOpen = () => {
        setIsFilterOpen(!isFilterOpen);
    };

    const filterButtonRef = useRef<HTMLButtonElement>(null);

    const getApiStatusClasses = useCallback(() => {
        if (shouldShowLoadingState || isCapacityUsageError || !isCapacityUsageDataPresent) {
            return mergeClasses(classes.apiStatusMessage, classes.apiStatusWithContent);
        } else {
            return mergeClasses(classes.apiStatusMessage, classes.apiStatusEmpty);
        }
    }, [
        shouldShowLoadingState,
        isCapacityUsageError,
        pageLoading,
        hourlyDataModel,
        classes.apiStatusMessage,
        classes.apiStatusWithContent,
        classes.apiStatusEmpty,
    ]);

    const dateRangeOptionChanged = (option: DateFilterChangedProps) => {
        updateTimePeriod(option.startDate, option.endDate, option.title);
        setFilterStartDate(dateRange.startDate);
    };

    const shouldShowUsageDashboardV2Page = MedeinaFeatures.UsageMonitoringV2 && user?.isAdmin;

    const {setNavActions} = useNavActions();

    useEffect(() => {
        const actions = [];
        const stateValues = getState();
        actions.push(<UsageDashboardDataExport {...stateValues}></UsageDashboardDataExport>);
        setNavActions(actions);
    }, [getState]);

    return (
        <>
            {shouldShowUsageDashboardV2Page && (
                <GridContainer className={classes.gridClasses}>
                    <div className={classes.root}>
                        <UsageDashboardTitle lastUpdatedAt={lastUpdatedAt} />
                        <div className={classes.infoContainer}>
                            <ParagraphContent>
                                {tAdmin(USAGE_MONITORING_DESCRIPTION)}{' '}
                                <Link
                                    href={MedeinaVariables.ManageCapacityLearnUri}
                                    target="_blank"
                                >
                                    {tAdmin(LEARN_MORE_LINK)}
                                </Link>
                            </ParagraphContent>
                        </div>
                        {!isDashboardLoaded && (
                            <div className={classes.loadingContainer}>
                                <Spinner
                                    labelPosition="below"
                                    aria-live="polite"
                                    aria-label={tAdmin(PAGE_LOADING)}
                                    size="medium"
                                    label={`${tAdmin(PAGE_LOADING)}...`}
                                />
                            </div>
                        )}
                        {isDashboardLoaded && (
                            <>
                                {showChangeUnitsComponents && (
                                    <>
                                        <div>
                                            <SmallTitle>{tAdmin(BASE_UNITS_PER_HOUR)}</SmallTitle>
                                        </div>
                                        <div className={classes.capacityValueContainer}>
                                            <NumericDisplayEmphasis>
                                                {getCapacityUnitsText()}
                                            </NumericDisplayEmphasis>
                                        </div>
                                    </>
                                )}
                                <div className={classes.actionBtns}>
                                    <div data-testid="abc">
                                        {showChangeUnitsComponents ? (
                                            <Button
                                                appearance="secondary"
                                                onClick={() => setChangeCapacityDialogOpen(true)}
                                                ref={changeCapacityButtonRef}
                                                disabled={isGetCapacityNameFetching}
                                            >
                                                {tCommon(ButtonLabels.CHANGE_CAPACITY)}
                                            </Button>
                                        ) : (
                                            <div />
                                        )}
                                    </div>
                                    <div className={classes.leftControlsElement}>
                                        {/* The 'isVisible' prop is used to conditionally hide the component without resetting the state to default of Last24Hours*/}
                                        <DateFilterDropdown
                                            className={classes.dateFilterDropdown}
                                            startDate={filterStartDate}
                                            dateRange={dateRange}
                                            setDateRange={setDateRange}
                                            isVisible={!pageLoading && !isCapacityUsageError}
                                            onOptionChanged={dateRangeOptionChanged}
                                            selectedOptionText={dateRangeOption}
                                            minSearchStartDate={
                                                CUSTOM_CAPACITY_USAGE_SEARCH_DATE_RANGE.LAST_90_DAYS
                                            }
                                        />
                                        {MedeinaFeatures.UsageMonitoringV2 &&
                                            !pageLoading &&
                                            !isCapacityUsageError &&
                                            isCapacityUsageDataPresent && (
                                                <>
                                                    <Divider vertical={true} />

                                                    <Tooltip
                                                        content={tAdmin(
                                                            UsageMonitoringFilterLabels.Tooltip,
                                                        )}
                                                        relationship="label"
                                                    >
                                                        <Button
                                                            data-testid="filter-open-button"
                                                            appearance="transparent"
                                                            icon={<FilterIcon />}
                                                            onClick={toggleOpen}
                                                            ref={filterButtonRef}
                                                        />
                                                    </Tooltip>
                                                    <UsageDashboardFilterContainer
                                                        filterData={facetDataModel?.value ?? []}
                                                        isOpen={isFilterOpen}
                                                        onFilterClose={() => {
                                                            setIsFilterOpen(false);
                                                            filterButtonRef.current?.focus();
                                                        }}
                                                        onFiltersUpdate={(filters) => {
                                                            updateFilters(filters);
                                                            updatePageNumber(DEFAULT_PAGE_NUMBER);
                                                            setIsFilterUpdated(true);
                                                        }}
                                                    ></UsageDashboardFilterContainer>
                                                </>
                                            )}
                                    </div>
                                </div>
                                <div className={getApiStatusClasses()}>
                                    {shouldShowLoadingState ? (
                                        <Spinner
                                            labelPosition="below"
                                            aria-live="polite"
                                            aria-label={tAdmin(USAGE_DATA_LOADING)}
                                            size="medium"
                                            label={`${tAdmin(USAGE_DATA_LOADING)}`}
                                        />
                                    ) : isCapacityUsageError ? (
                                        <ApiResponseDisplay
                                            title={tAdmin(ApiResponseDisplays.ERROR.title)}
                                            subtitle={tAdmin(ApiResponseDisplays.ERROR.subtitle)}
                                            onRefreshButtonClick={onPageRefresh}
                                            isError
                                        />
                                    ) : (
                                        !isCapacityUsageDataPresent && (
                                            <ApiResponseDisplay
                                                title={tAdmin(ApiResponseDisplays.NO_DATA.title)}
                                                subtitle={tAdmin(
                                                    ApiResponseDisplays.NO_DATA.subtitle,
                                                )}
                                                isError={false}
                                            />
                                        )
                                    )}
                                </div>
                                {isCapacityUsageDataPresent &&
                                    !pageLoading &&
                                    !isCapacityUsageError && (
                                        <>
                                            <VStackedBarChart
                                                data={hourlyDataModel?.value ?? []}
                                                onGraphBarClick={(startDate, endDate) => {
                                                    setDateRange({startDate, endDate});
                                                }}
                                                onGraphBarBlur={() => {
                                                    setDateRange({
                                                        startDate: subDays(new Date(), 1),
                                                        endDate: new Date(),
                                                    });
                                                }}
                                            />
                                            <DataTableV2
                                                data={dimensionalDataModel?.value ?? []}
                                                onPageNumberChange={(pageNumber) => {
                                                    //Code to subscribe to the page number change will come in a future PR
                                                    updatePageNumber(pageNumber);
                                                    setIsFilterUpdated(false);
                                                }}
                                                // The actual total page count will come in a forthcoming PR
                                                totalDataCount={dimensionalDataModel?.count ?? 0}
                                                resetPagination={isFilterUpdated}
                                                isTableLoading={isEvaluationAggregateDataLoading}
                                                sortDirection={getState().sortDirection}
                                                onDateSortChanging={async (sortDirection) => {
                                                    updateSortDirection(sortDirection);
                                                }}
                                                currentPage={getState().pageNumber}
                                            />
                                        </>
                                    )}
                                {showChangeUnitsComponents && (
                                    <ChangeCapacity
                                        isChangeCapacityDialogOpen={isChangeCapacityDialogOpen}
                                        onChangeCapacityDialogClose={
                                            performActionsOnCapacityDialogClosure
                                        }
                                    />
                                )}
                            </>
                        )}
                    </div>
                </GridContainer>
            )}
        </>
    );
}
