import React, {useState, useMemo, useContext, useEffect, createContext} from 'react';
import {WorkspaceStateObject, WorkspaceStateParams} from './workspaceProvider.types';
import {useGetTenantInfo} from '@/api/tenant';
import {useGetWorkspaces} from '@/api/workspaces';
import {useGetCapacities, useGetCapacityByName} from '@/api/capacities';
import {useCheckTenant} from '@/api/accounts';
import {useQueryClient} from '@tanstack/react-query';
import MedeinaFeatures from '@/util/features';
import {useFeatureFlag} from '@/api/user';
import {useLocation} from 'react-router-dom';
import {ErrorResponse} from '@/api/api';

export interface WorkspaceStateProviderProps {
    children?: React.ReactNode;
}

export const WorkspaceStateContext = createContext<WorkspaceStateObject>(undefined!);

export function useWorkspaceState() {
    return useContext(WorkspaceStateContext);
}

const defaultUserStateValue: WorkspaceStateObject = {
    capacityId: undefined!,
    capacityName: undefined!,
    workspaceName: undefined!,
    geoName: undefined!,
    isProviderLoaded: false,
    isWorkspaceDetached: false,
    areCapacitiesRemoved: false,
    isInvalidRegion: false,
    isSetupInvalid: false,
    isWorkspaceCapacityEAP: false,
    capacityExpiryDate: null,
    update: () => undefined!,
};

export function WorkspaceStateProvider(props: WorkspaceStateProviderProps) {
    const location = useLocation();
    const [workspaceStateParams, setWorkspaceStateParams] =
        useState<WorkspaceStateParams>(defaultUserStateValue);

    const [areCapacitiesRemovedInTenant, setAreCapacitiesRemovedInTenant] = useState(false);
    const [isFreCompleted, setIsFreCompleted] = useState(false);
    const [capacityName, setCapacityName] = useState<string>(undefined!);
    const [isWorkspaceDetachedFromCapacity, setIsWorkspaceDetachedFromCapacity] =
        useState<boolean>(false);
    const [isInvalidRegion, setIsInvalidRegion] = useState(false);
    const [isProviderLoaded, setIsProviderLoaded] = useState<boolean>(false);
    const [isSetupInvalid, setIsSetupInvalid] = useState<boolean>(false);
    const [isExpiryDateAvailable, setIsExpiryDateAvailable] = useState<boolean>(false);
    const [isCapacityExpiryDateAvailable, setIsCapacityExpiryDateAvailable] =
        useState<boolean>(false);
    const queryClient = useQueryClient();
    const isFidelisEnabled = useFeatureFlag(MedeinaFeatures.LaunchExperienceFidelisGA) as boolean;
    const debugEnabled = useFeatureFlag(MedeinaFeatures.Debug);

    //Get User tenant info to be fetched only if cache has expired & tenant data isn't available
    const {
        data: tenantInfo,
        isSuccess: isTenantInfoSuccess,
        isFetched: isTenantInfoFetched,
        isRefetching: isTenantInfoRefetching,
    } = useGetTenantInfo({
        enabled: isFidelisEnabled,
    });

    // Check if the tenant info is fetched and the FRE requirements are met
    // If the FRE requirements are met, update the workspace state
    // on route change
    useEffect(() => {
        if (tenantInfo?.freRequirements?.freRequirementsMet === true) {
            workspaceState?.update();
        }
    }, [location, tenantInfo]);

    //None of the API's to be fetched on re-render when the cache value hasn't expired
    //CheckTenant to be fetched when cache has expired & the API response isn't already available
    const {
        data: checkTenant,
        isError: isCheckTenantError,
        isSuccess: isCheckTenantSuccess,
        isFetched: isCheckTenantFetched,
        isRefetching: isCheckTenantRefetching,
    } = useCheckTenant({
        enabled: isFidelisEnabled && isFreCompleted && isTenantInfoFetched,
    });

    // Workspaces to be fetched only if cache value has expired, V2 FRE isn't complete
    // and existing workspaces aren't already available
    const {
        data: workspaces,
        isFetched: isWorkspacesFetched,
        isRefetching: isRefetchingWorkspaces,
    } = useGetWorkspaces({
        enabled: isFidelisEnabled && isFreCompleted && Boolean(checkTenant?.tenantGeo),
    });

    // All capacities to be fetched when new FRE isn't
    // complete & all capacities aren't loaded
    const {
        data: capacities,
        isFetched: isCapacitiesFetched,
        isRefetching: isCapacitiesRefetching,
    } = useGetCapacities({
        enabled: isFidelisEnabled && isFreCompleted && Boolean(checkTenant?.tenantGeo),
    });

    //Capacity record for capacity name to be fetched when all the following are true
    //a.workspaces are available and not loaded, and a capacity is available with the default workspace
    //b.capacities for account are not loaded up,
    const {
        data: capacity,
        isSuccess: isCapacitySuccess,
        isFetched: isCapacityFetched,
        isRefetching: isCapacityRefetching,
        isLoading: isCapacityLoading,
        error: capacityByNameError,
    } = useGetCapacityByName(capacityName, {
        enabled:
            isFidelisEnabled &&
            isFreCompleted &&
            isCapacitiesFetched &&
            isWorkspacesFetched &&
            Boolean(checkTenant?.tenantGeo) &&
            Boolean(capacityName),
    });

    if (debugEnabled) {
        console.log({isFreCompleted, isTenantInfoFetched, isCapacitiesFetched});
    }

    //Actions that should happen when tenant info is fetched
    useEffect(() => {
        if (isTenantInfoFetched) {
            if (debugEnabled) console.log('Tenant Info Fetched');
            let requirementsMet = tenantInfo?.freRequirements?.freRequirementsMet ?? false;
            //temporary workaround for testing This will be removed once the freV2RequirementsMet appears from API
            // requirementsMet = true;
            if (isTenantInfoSuccess) {
                setIsFreCompleted(requirementsMet);
            }

            if (!requirementsMet) {
                setIsProviderLoaded(true);
            }
        }
    }, [isTenantInfoFetched, isTenantInfoRefetching]);

    //Actions that should happen when check tenant is resolved
    useEffect(() => {
        if ((isFreCompleted && isCheckTenantSuccess) || isCheckTenantError) {
            if (debugEnabled)
                console.log('Check Tenant Fetched', {
                    isFreCompleted,
                    isCheckTenantSuccess,
                    isCheckTenantError,
                });
            if (isCheckTenantSuccess) {
                if (!!checkTenant?.tenantGeo) {
                    setWorkspaceStateParams({
                        ...workspaceStateParams,
                        geoName: checkTenant.tenantGeo,
                    });
                } else {
                    //Invalid region
                    setIsInvalidRegion(true);
                    setIsProviderLoaded(true);
                }
            } else if (isCheckTenantError) {
                // If the check tenant API throws an error, we need to show up the region specific error
                // The code additionally needs to be updated ensure for a valid parameter from Fidelis to show
                // determine the region specific error.
                setIsInvalidRegion(true);
                setIsProviderLoaded(true);
            }
        }
    }, [isCheckTenantFetched, isCheckTenantRefetching]);

    useEffect(() => {
        if (isWorkspacesFetched && isCapacitiesFetched) {
            let capacityWorkspaceData: Partial<{
                capacityId: string | undefined;
                capacityName: string | undefined;
                capacityExpiryDate: Date | null;
                workspaceName: string | undefined;
            }> = {
                capacityId: undefined,
                capacityName: undefined,
                capacityExpiryDate: null,
                workspaceName: undefined,
            };

            //If there are no capacities associated with the workspaces, then the workspace is deemed to be detached
            const capacityCount = capacities?.count ?? 0;
            const workspaceName = workspaces?.value?.[0]?.name;
            if (workspaceName) {
                capacityWorkspaceData.workspaceName = workspaceName;
            }

            if (capacityCount === 0) {
                // If there are no capacities, then the workspace is deemed to be detached
                if (debugEnabled) {
                    console.log('No Capacities Found');
                }

                setAreCapacitiesRemovedInTenant(true);
                setCapacityName(null!);
                setIsWorkspaceDetachedFromCapacity(true);

                setIsProviderLoaded(true);
            } else if (capacityCount > 0) {
                if (debugEnabled) {
                    console.log('Capacities are available with the tenant');
                }
                // If there are capacities, then the workspace may not be detached
                setAreCapacitiesRemovedInTenant(false);
                const workspace = workspaces?.value?.[0];
                const capacityInWorkspace = workspace?.capacity?.referenceName;

                if (!capacityInWorkspace) {
                    if (debugEnabled) {
                        console.log('No Capacity Found in Workspace');
                    }
                    // If the capacity is not found in the workspace, then the workspace is deemed to be detached
                    setIsWorkspaceDetachedFromCapacity(true);
                    setCapacityName(null!);
                    setIsProviderLoaded(true);
                } else {
                    if (debugEnabled) {
                        console.log('Capacity Found in Workspace:', capacityInWorkspace);
                        console.log('Will now probe for capacity by name');
                    }
                    // If the capacity is found in the workspace, then the workspace is not deemed to be detached, and the capacity may be available
                    setIsWorkspaceDetachedFromCapacity(false);
                    setCapacityName(capacityInWorkspace);
                    setIsProviderLoaded(false);
                }
            } else if (!workspaceName) {
                // If the workspace name is not found, then the setup is invalid
                setIsSetupInvalid(true);
                setIsProviderLoaded(true);
            }

            setWorkspaceStateParams({
                ...workspaceStateParams,
                ...capacityWorkspaceData,
            });
        }
    }, [isWorkspacesFetched, isRefetchingWorkspaces, isCapacitiesFetched, isCapacitiesRefetching]);

    useEffect(() => {
        if (isWorkspacesFetched && isCapacitiesFetched && isCapacityFetched) {
            if (isCapacitySuccess) {
                if (!!capacity?.name) {
                    // If the capacity is found in the workspace, then the workspace is deemed to be attached, and the capacity may be available
                    if (debugEnabled) {
                        console.log('Capacity Found for:', capacity?.name);
                    }
                    setIsWorkspaceDetachedFromCapacity(false);
                    setCapacityName(capacity?.name);

                    let expiryDate: Date | null = workspaceState?.capacityExpiryDate || null;
                    if (debugEnabled) console.log('isExpiryDateAvailable', isExpiryDateAvailable);
                    if (!isExpiryDateAvailable && capacity.id === '') {
                        if (debugEnabled)
                            console.log('Setting setIsCapacityExpiryDateAvailable as true');

                        setIsCapacityExpiryDateAvailable(true);
                        if (debugEnabled) {
                            console.log(
                                'expires at api does not have value for capacity id: ',
                                capacity?.id,
                            );
                            console.log('Capacity id is empty string. Determining this as EAP');
                        }
                        const expiresAt = capacity?.properties?.expiresAt;

                        if (expiresAt) {
                            expiryDate = new Date(expiresAt);
                        }
                    }

                    setWorkspaceStateParams({
                        ...workspaceStateParams,
                        capacityId: capacity?.id,
                        capacityName: capacity?.name,
                        capacityExpiryDate: expiryDate,
                    });
                } else {
                    if (debugEnabled) {
                        console.log('No Capacity Found for:', capacityName);
                    }
                    // If the capacity is not found in the workspace, then the workspace is deemed to be detached
                    setIsWorkspaceDetachedFromCapacity(true);
                    setCapacityName(null!);
                    setWorkspaceStateParams({
                        ...workspaceStateParams,
                        capacityId: undefined,
                        capacityName: undefined,
                        capacityExpiryDate: null,
                    });
                }
            } else {
                // If Capacity by name errored out
                if (debugEnabled) {
                    console.log('Capacity by name threw an error');
                }

                const capacityError = capacityByNameError as ErrorResponse;
                if (capacityError.code === 404) {
                    // If the capacity is not found in the workspace, then the workspace is deemed to be detached
                    if (debugEnabled) {
                        console.log('Capacity by name threw an error 404');
                    }
                    setIsWorkspaceDetachedFromCapacity(true);
                    setCapacityName(null!);
                }
                // Decide what to do capacity API throws error
                // Could be that the capacities API for a workspace is throwing an independent error
                // so we let the process go through
                setWorkspaceStateParams({
                    ...workspaceStateParams,
                    capacityId: undefined,
                    capacityName: undefined,
                    capacityExpiryDate: null,
                });
            }
            setIsProviderLoaded(true);
        }
    }, [
        isWorkspacesFetched,
        isRefetchingWorkspaces,
        isCapacitiesFetched,
        isCapacitiesRefetching,
        isCapacityLoading,
        isCapacityRefetching,
        capacityName,
    ]);

    const workspaceState = useMemo<WorkspaceStateObject>(() => {
        return {
            ...workspaceStateParams,

            isWorkspaceDetached:
                isFreCompleted && isProviderLoaded && isWorkspaceDetachedFromCapacity,

            isInvalidRegion: isFreCompleted && isInvalidRegion,

            isSetupInvalid: isFreCompleted && isSetupInvalid,

            areCapacitiesRemoved: isFreCompleted && areCapacitiesRemovedInTenant,

            // This is determined by the expiry date of the capacity and the presence of the expiry date feature flag on an environment
            isWorkspaceCapacityEAP:
                isFreCompleted && (isExpiryDateAvailable || isCapacityExpiryDateAvailable),

            update: () =>
                new Promise<void>(async (resolve) => {
                    setIsExpiryDateAvailable(false);
                    setIsCapacityExpiryDateAvailable(false);
                    setIsProviderLoaded(false);
                    // Refetch datashare, checktenant & workspaces
                    const queries = [['settings', 'datashare'], ['checkTenant']];

                    queries.forEach(async (key) => {
                        if (debugEnabled) console.log('Refetching', key);
                        await queryClient.refetchQueries(key, {exact: true});
                    });

                    //Ensure all the fetch queries associated with workspaces are deleted
                    queryClient.removeQueries({
                        predicate: (query) => query.queryKey[0] === 'workspaces',
                    });

                    // Rehydrate the workspaces queri(es)
                    await queryClient.refetchQueries(['workspaces'], {exact: true});

                    //Ensure all the fetch queries associated with capacities are deleted
                    queryClient.removeQueries({
                        predicate: (query) => query.queryKey[0] === 'capacities',
                    });

                    // Rehydrate the capacities queri(es)
                    await queryClient.refetchQueries(['capacities'], {exact: true});

                    //Since we are refreshing the queries, it does'nt make sense to retain the capacity name on the next cycle
                    setCapacityName(undefined!);

                    resolve();
                }),

            isProviderLoaded: isProviderLoaded,
        };
    }, [
        workspaceStateParams,
        isWorkspaceDetachedFromCapacity,
        areCapacitiesRemovedInTenant,
        isFreCompleted,
        isProviderLoaded,
        isExpiryDateAvailable,
        isCapacityExpiryDateAvailable,
    ]);
    return (
        <WorkspaceStateContext.Provider value={workspaceState}>
            {props?.children}
        </WorkspaceStateContext.Provider>
    );
}
