import MedeinaVariables from '@/util/variables';
import {RESOURCE_SCOPES} from '../api.constants';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {RbacErrorResponse, useUpdateWorkspacePolicyRequest} from './rbac.types';
import {customFetch} from '../api';

export default function useUpdateWorkspacePolicyById() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (request: useUpdateWorkspacePolicyRequest) => {
            const url = `${MedeinaVariables.FidelisSecCopUri}/policyStore/workspacePolicies/${request.policy.id}?api-version=2024-01-01-preview`;

            const response = await customFetch<Response>(
                url,
                {
                    method: 'PUT',
                    scopes: RESOURCE_SCOPES.FIDELIS,
                    body: request.policy,
                },
                true,
            );

            if (!response.ok) {
                const error = (await response.json()) as RbacErrorResponse;
                throw error;
            }

            return response;
        },

        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(['rbac', variables.workspaceName]);
            queryClient.invalidateQueries(['auth', 'getUserInfo']);
        },
        onError: (data, variables) => {
            queryClient.invalidateQueries(['rbac', variables.workspaceName]);
            queryClient.invalidateQueries(['auth', 'getUserInfo']);
        },
    });
}
