import React from 'react';
import {
    Dropdown,
    Label,
    Option,
    Radio,
    RadioGroup,
    Switch,
    mergeClasses,
} from '@fluentui/react-components';
import useClasses from '@/components/Navigation/Preferences.styles';
import useSwitcherClasses from './ThemeSwitcher.styles';
import {ColorScheme, DebugLevel, useAppState} from '@/api/app';
import {
    DarkThemeSVGIcon,
    HighContrastThemeSVGIcon,
    LightThemeSVGIcon,
} from '@/components/Navigation/ThemeIcons';
import {useTranslation} from 'react-i18next';

interface ThemeOptionProps {
    children: React.ReactNode;
    selected: boolean;
    label: string;
    value: ColorScheme;
    disabled?: boolean;
    onChange?: (data: any) => void;
}

// TODO - Implement real look and feel for switcher
export const ThemeOption = (props: ThemeOptionProps) => {
    const classes = useSwitcherClasses();

    return (
        <div className={props.selected ? classes.selected : classes.themeOption}>
            <div aria-hidden>{props.children}</div>
            <div className={classes.radioRow}>
                <Label className={classes.label} aria-hidden>
                    {props.label}
                </Label>
                <Radio value={props.value} onChange={props.onChange} aria-label={props.label} />
            </div>
        </div>
    );
};

export default function Preferences() {
    const classes = useClasses();
    const switcherClasses = useSwitcherClasses();
    const {t} = useTranslation();
    const {colorScheme, setColorScheme} = useAppState();

    return (
        <div className={classes.field}>
            <RadioGroup
                layout="horizontal"
                onChange={(_, data) => {
                    setColorScheme(data.value as ColorScheme);
                }}
                disabled={colorScheme === ColorScheme.OS}
                defaultValue={colorScheme}
                className={switcherClasses.radioGroup}
            >
                <ThemeOption
                    selected={colorScheme === ColorScheme.Light}
                    label={t('SettingsThemeLightLabel')}
                    value={ColorScheme.Light}
                    onChange={(data) => setColorScheme(data.value as ColorScheme)}
                >
                    <LightThemeSVGIcon aria-hidden />
                </ThemeOption>
                <ThemeOption
                    selected={colorScheme === ColorScheme.Dark}
                    label={t('SettingsThemeDarkLabel')}
                    value={ColorScheme.Dark}
                    onChange={(data) => setColorScheme(data.value as ColorScheme)}
                >
                    <DarkThemeSVGIcon aria-hidden />
                </ThemeOption>
                <ThemeOption
                    selected={colorScheme === ColorScheme.HighContrast}
                    label={t('SettingsThemeHighContrastLabel')}
                    value={ColorScheme.HighContrast}
                    onChange={(data) => setColorScheme(data.value as ColorScheme)}
                >
                    <HighContrastThemeSVGIcon aria-hidden />
                </ThemeOption>
            </RadioGroup>
        </div>
    );
}
