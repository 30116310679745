import { makeStyles } from '@griffel/react';
import { typographyStyles } from '@fluentui/react-theme';
export const body1ClassNames = {
    root: 'fui-Body1'
};
/**
 * Styles for the root slot
 */ export const useBody1Styles = makeStyles({
    root: typographyStyles.body1
});
