import {useMutation} from '@tanstack/react-query';
import MedeinaVariables from '@/util/variables';
import {customFetch} from '../api';
import {GetRoleByNameResponse} from './rbac.types';
import {useTranslation} from 'react-i18next';
import {EVERYONE_IDENTIFIER, GDAP_IDENTIFIER, GDAP} from './rbac.constants';

export default function useGetRoleMemberDetails() {
    const {t} = useTranslation('admin');

    return useMutation({
        mutationFn: async (body: any) => {
            const url = `${MedeinaVariables.ApiUri}/graphData/details`;

            // Only `principal.microsoft.id` can have `*` as a role ID. Circumvent object creation for this case.
            const doesEveryoneExist: boolean = body?.IamItems?.User?.includes(EVERYONE_IDENTIFIER);

            const doesGdapExist: boolean = body?.IamItems?.Role?.includes(GDAP_IDENTIFIER);

            // Remove * and / from the list of users
            if (doesEveryoneExist) {
                body.IamItems.User = body.IamItems.User.filter(
                    (id: string) => id !== EVERYONE_IDENTIFIER,
                );
            }

            if (doesGdapExist) {
                body.IamItems.Role = body.IamItems.Role.filter(
                    (id: string) => id !== GDAP_IDENTIFIER,
                );
            }

            const resp = await customFetch<GetRoleByNameResponse>(url, {
                method: 'POST',
                body: body,
            });

            // Add Everyone to the list of users
            if (doesEveryoneExist) {
                resp.details.User.push({
                    id: EVERYONE_IDENTIFIER,
                    displayName: t('RoleAssignment.Everyone'),
                    mail: t('RoleAssignment.EveryoneInfo'),
                });
            }

            if (doesGdapExist) {
                resp.details.Role.push({
                    id: GDAP_IDENTIFIER,
                    displayName: GDAP,
                });
            }

            return resp;
        },
        onSuccess: (_, variables) => {},
    });
}
