import { makeStyles } from '@griffel/react';
import { typographyStyles } from '@fluentui/react-theme';
export const caption1ClassNames = {
    root: 'fui-Caption1'
};
/**
 * Styles for the root slot
 */ export const useCaption1Styles = makeStyles({
    root: typographyStyles.caption1
});
