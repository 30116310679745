import {useMutation} from '@tanstack/react-query';
import {useAppInsights} from '@/util/telemetry';
import {IMedeinaExtendedTelemetryItem, MedeinaTelemetryEvent} from './telemetry.types';

export default function useTrackEvent() {
    const appInsights = useAppInsights();

    return useMutation({
        mutationFn: async (event: IMedeinaExtendedTelemetryItem, customProperties?: any) => {
            return appInsights?.trackEvent(
                {
                    ...event,
                    name: 'Microsoft.Medeina.ActionEvent',
                    data: {
                        ...(event.data ?? {}),
                        name: event.name ?? MedeinaTelemetryEvent.Unknown,
                    },
                },
                customProperties,
            );
        },
    });
}
