import React from 'react';
import {Stack} from '@fluentui/react';
import useClasses from './CheckboxGroup.styles';
import {CheckboxGroupProps} from '@/components/ui/Filter/Filter.types';
import {Checkbox} from '@fluentui/react-components';

export default function CheckboxGroup({
    options,
    selectedOptions,
    onCheckboxChange,
}: CheckboxGroupProps) {
    const classes = useClasses();
    const selectedOptionsSet = new Set(selectedOptions);
    return (
        <Stack className={classes.stackStyle} tokens={{childrenGap: 10}}>
            {options.map((option, index) => (
                <Checkbox
                    key={index}
                    label={option}
                    checked={selectedOptionsSet.has(option)}
                    onChange={() => onCheckboxChange(option)}
                />
            ))}
        </Stack>
    );
}
