import {Button, Field, Text} from '@fluentui/react-components';
import useClasses from '../../AdminConfigurationModalContent.styles';
import {MedeinaInfoLabel} from '../../../Buttons/MedeinaInfoLabel';
import {SettingsScope, SkillsetConfiguration} from '@/api/skills';
import {ResetIcon} from '@/components/ui/icons';
import {useUserState} from '@/api/user';
import {useTranslation} from 'react-i18next';

interface SelectedConfigsInfoResetHeaderProps {
    scope: SettingsScope;
    existingConfigHasValue: boolean;
    onResetClick: () => void;
}

export default function SelectedConfigsInfoResetHeader({
    scope,
    existingConfigHasValue,
    onResetClick,
}: SelectedConfigsInfoResetHeaderProps) {
    const classes = useClasses();
    const {tenant: userTenant} = useUserState();
    const {t} = useTranslation('plugins');

    const getPluginConfigurationHeaderText = () => {
        return scope == SettingsScope.User
            ? t('Admin.UserScopeSettingsTitle')
            : t('Admin.TenantScopeSettingsTitle', {
                  tenantName: userTenant.displayName,
              });
    };

    return (
        <div className={classes.sectionHeading}>
            <Field
                className={classes.headingTextCol}
                label={{
                    children: (
                        <MedeinaInfoLabel
                            labelContent={
                                <Text className={classes.headingText} as="h2">
                                    {getPluginConfigurationHeaderText()}
                                </Text>
                            }
                            infoContent={
                                scope == SettingsScope.User
                                    ? t('Admin.UserScopeSettingsDescription')
                                    : t('Admin.TenantScopeSettingsDescription')
                            }
                        />
                    ),
                }}
            />
            {existingConfigHasValue && (
                <Button
                    className={classes.triggerResetButton}
                    appearance="subtle"
                    size="medium"
                    icon={<ResetIcon />}
                    onClick={onResetClick}
                >
                    {t('common.Reset')}
                </Button>
            )}
        </div>
    );
}
