import {TipsMessage} from './DailyTips.types';
import useClasses from './DailyTips.styles';
import {useMemo, useState} from 'react';
import {DismissIcon} from '../icons';
import {Button, Text, Tooltip} from '@fluentui/react-components';
import getRandomElementFromArray from '@/util/getRandomElement';
import {dailyTipMessages} from './DailyTips.messages';
import {useTranslation} from 'react-i18next';

function DailyTips() {
    const classes = useClasses();
    const {t} = useTranslation('session');
    const [isVisible, setIsVisible] = useState(true);
    const tip = useMemo(() => {
        return getRandomElementFromArray<TipsMessage>(dailyTipMessages);
    }, [dailyTipMessages]);
    const Icon = tip?.image;
    const title = tip?.title || '';
    const information = tip?.information || '';

    return (
        <>
            {isVisible && (
                <div className={classes.tipContainer}>
                    <Tooltip content={t('CloseDailyTip')} relationship="label" positioning="below">
                        <Button
                            icon={<DismissIcon />}
                            appearance="subtle"
                            className={classes.closeButton}
                            onClick={() => {
                                setIsVisible(false);
                            }}
                        />
                    </Tooltip>
                    <h2 className={classes.tipType}>{t(tip?.type || 'DailyTip.DailyTip')}</h2>
                    <h3 className={classes.tipTitle}>{t(title)}</h3>
                    <Text className={classes.tipInformation}>{t(information)}</Text>
                    <Icon className={classes.tipIcon} />
                </div>
            )}
        </>
    );
}

export default DailyTips;
