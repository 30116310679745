import {useEffect, useState} from 'react';
import {
    Card,
    Caption1,
    Subtitle2,
    mergeClasses,
    Button,
    Text,
    Body1Strong,
    Link,
    Tooltip,
} from '@fluentui/react-components';
import {ExternalLinkIcon, SkillIcon} from '@/components/ui/icons';
import {
    useGetAllPromptbooks,
    PromptbookDescriptor,
    PromptInputs,
    PromptbookVisibility,
} from '@/api/promptbooks';
import AcrylicBase from '@/components/ui/AcrylicBase';
import useClasses from './ExploreCardList.styles';
import ExploreCardList from './ExploreCardList';
import {PromptbookListIcon, RunIcon} from '@/components/ui/icons';
import ExploreCardEmpty from './ExploreCardEmpty';
import {ExploreCardProps} from './ExploreCopilot.types';
import {PromptBar} from '@/components/ui';
import GridContainer from '@/components/ui/Grid/GridContainer';
import GridItem from '@/components/ui/Grid/GridItem';
import shuffleArray from '@/util/shuffleArray';
import {SubmitIcon} from '@/components/ui/icons';
import {useNavigate} from 'react-router-dom';
import PromptbookLibraryForm from '@/components/ui/PromptBar/PromptbookLibraryForm';
import {useTranslation} from 'react-i18next';
import useFeaturedClasses from './FeaturedContentTabs.styles';
import MedeinaVariables from '@/util/variables';

export default function ExplorePromptbooks({
    className,
    btnClicked,
    cardRange,
    disablePrev,
    disableNext,
    clickCounter,
    active,
    filter = "(cast(visibility, Edm.String) eq 'Global')",
}: ExploreCardProps & {filter?: string}) {
    const classes = useClasses();
    const featuredClasses = useFeaturedClasses();
    const {t} = useTranslation('common');
    const {data: promptbooks, isLoading} = useGetAllPromptbooks({
        filter,
    });
    const [defaultPromptbook, setDefaultPromptbook] = useState<PromptbookDescriptor | null>(null);
    const [startIndex, setStartIndex] = useState(-1);
    const [endIndex, setEndIndex] = useState(-1);
    const [promptbooksList, setPromptbooksList] = useState<Array<PromptbookDescriptor | null>>([]);
    const [shuffledPromptBooks, setShuffledPromptBooks] = useState<Array<PromptbookDescriptor>>([]);
    const [openPromptbookViewDialog, setOpenPromptbookViewDialog] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (promptbooks?.value?.length && promptbooks?.value?.length > 0) {
            setShuffledPromptBooks(shuffleArray(promptbooks.value));
        }
    }, [promptbooks]);

    // on update of start or end index, check if prev or next button should be disabled.
    // Also on changing of active tab, check if prev or next button should be disabled.
    useEffect(() => {
        if (shuffledPromptBooks.length > 0 && active) {
            endIndex >= shuffledPromptBooks.length - 1 ? disableNext(true) : disableNext(false);
            startIndex <= 0 ? disablePrev(true) : disablePrev(false);
        }
    }, [startIndex, endIndex, active, shuffledPromptBooks.length]);

    // on promptbook update or card range update, update the promptbook list.
    useEffect(() => {
        let list: Array<PromptbookDescriptor | null> = [];
        if (shuffledPromptBooks.length > 0) {
            list = shuffledPromptBooks.slice(0, cardRange);
        }
        setStartIndex(0);
        setEndIndex(cardRange - 1);
        setPromptbooksList(list.concat([null, null, null, null]).slice(0, cardRange));
    }, [shuffledPromptBooks, cardRange]);

    // on click of prev or next button, update the promptbook list.
    useEffect(() => {
        if (btnClicked === 'prev') {
            setStartIndex(startIndex - 1);
            setEndIndex(endIndex - 1);
            let list: Array<PromptbookDescriptor | null> = [];
            if (shuffledPromptBooks.length) {
                list = shuffledPromptBooks.slice(startIndex - 1, endIndex);
            }
            setPromptbooksList(list.concat([null, null, null, null]).slice(0, cardRange));
        } else if (btnClicked === 'next') {
            setStartIndex(startIndex + 1);
            setEndIndex(endIndex + 1);
            let list: Array<PromptbookDescriptor | null> = [];
            if (shuffledPromptBooks.length > 0) {
                list = shuffledPromptBooks.slice(startIndex + 1, endIndex + 2);
            }
            setPromptbooksList(list.concat([null, null, null, null]).slice(0, cardRange));
        }
    }, [clickCounter]);

    const startPromptbookLabel = t('tooltips.startPromptbook');

    return (
        <div className={className}>
            <GridContainer>
                <div className={featuredClasses.titleColumn} data-testid="explore-copilot-title">
                    <Body1Strong className={featuredClasses.subtitle}>
                        {t('home.featuredPromptbooksSubtitle')}
                        <Link href={MedeinaVariables.LearnMorePromptbooksUrl} target="_blank">
                            {t('LearnMore')}
                            <ExternalLinkIcon />
                        </Link>
                    </Body1Strong>
                </div>
                <ExploreCardList>
                    {promptbooksList?.map((promptbook, index) =>
                        promptbook ? (
                            <GridItem key={index} sm={12} md={12} lg={6} xlg={4} xxlg={4} xxxlg={3}>
                                <Card
                                    className={mergeClasses(classes.card, classes.promptbookCard)}
                                    focusMode="tab-only"
                                    aria-label={`View promptbook: ${promptbook.name}`}
                                    data-testid="promptbook-card"
                                    tabIndex={-1}
                                >
                                    <AcrylicBase
                                        className={classes.promptbookAcrylic}
                                        shadow="none"
                                        offset="none"
                                    >
                                        <div
                                            className={mergeClasses(
                                                classes.cardContent,
                                                classes.promptbookCardContent,
                                            )}
                                        >
                                            <Subtitle2
                                                block
                                                truncate
                                                wrap={false}
                                                title={promptbook.name}
                                                className={classes.promptbookTitle}
                                                onClick={() => {
                                                    setDefaultPromptbook(promptbook);
                                                    setOpenPromptbookViewDialog(true);
                                                }}
                                                onKeyDown={(
                                                    event: React.KeyboardEvent<HTMLDivElement>,
                                                ) => {
                                                    if (event.key === 'Enter') {
                                                        event.preventDefault();
                                                        setDefaultPromptbook(promptbook);
                                                        setOpenPromptbookViewDialog(true);
                                                    }
                                                }}
                                                tabIndex={0}
                                            >
                                                {promptbook.name}
                                            </Subtitle2>
                                            <Caption1 className={classes.promptbookDescription}>
                                                {promptbook.description}
                                            </Caption1>
                                        </div>
                                        <div
                                            className={mergeClasses(
                                                classes.cardFooter,
                                                classes.promptbookCardFooter,
                                            )}
                                        >
                                            <div className={classes.summary}>
                                                <span>
                                                    {promptbook.visibility ==
                                                    PromptbookVisibility.Global
                                                        ? t('MicrosoftSecurity')
                                                        : promptbook.ownerName}
                                                    &nbsp;&middot;&nbsp;
                                                    <PromptbookListIcon />
                                                    {' ' +
                                                        promptbook.prompts.length +
                                                        (promptbook.prompts.length === 1
                                                            ? ' prompt'
                                                            : ' prompts')}
                                                </span>
                                            </div>
                                            <div>
                                                <Tooltip
                                                    content={`${startPromptbookLabel}: ${promptbook.name}`}
                                                    relationship="label"
                                                >
                                                    <Button
                                                        aria-label={`${startPromptbookLabel}: ${promptbook.name}`}
                                                        appearance="subtle"
                                                        size="small"
                                                        icon={<RunIcon />}
                                                        onClick={() =>
                                                            navigate(
                                                                `/sessions/new/${promptbook?.promptbookId}`,
                                                            )
                                                        }
                                                    />
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </AcrylicBase>
                                </Card>
                            </GridItem>
                        ) : (
                            <GridItem key={index} sm={12} md={12} lg={6} xlg={4} xxlg={4} xxxlg={3}>
                                <ExploreCardEmpty key={index} />
                            </GridItem>
                        ),
                    )}
                    {/*
                     * TODO:
                     * This needs to be cleaned up to just call the promptbook
                     * modal vs loading a hidden promptbar.
                     */}
                    {defaultPromptbook && openPromptbookViewDialog && (
                        <PromptbookLibraryForm
                            promptbook={defaultPromptbook!}
                            promptbookInputs={defaultPromptbook?.promptbookinputs.reduce(
                                (acc, input) => {
                                    const {name, description} = input;
                                    acc[name] = description;
                                    return acc;
                                },
                                {} as PromptInputs,
                            )}
                            onCancel={() => setOpenPromptbookViewDialog(false)}
                            onSubmit={() => setOpenPromptbookViewDialog(false)}
                            open={openPromptbookViewDialog}
                        />
                    )}
                </ExploreCardList>
            </GridContainer>
            <Caption1 block className={classes.viewPromptbooks}>
                <Link href="/promptbooks" data-testid="promptbook-library-button-home-page">
                    {t('home.viewPromptbooks')} <SubmitIcon />
                </Link>
            </Caption1>
        </div>
    );
}
