import * as React from 'react';
import {Field, Input, Text} from '@fluentui/react-components';
import useClasses from '../../AdminConfigurationModalContent.styles';
import {MedeinaInfoLabel} from '../../../Buttons/MedeinaInfoLabel';
import {SkillsetConfiguration, SkillsetSetting, SkillsetSettingType} from '@/api/skills';
import {getSettingInputType} from '../Utils/AdminConfigModal.utils';
import {useTranslation} from 'react-i18next';

interface SelectedSkillsetSettingConfigurationProps {
    config: SkillsetSetting;
    defaultInputValue: string;
    skillsetConfigurations: SkillsetConfiguration[];
    failedValidationSettings: string[];
    onInputChange: (ev: any) => void;
    autoFocus: boolean;
}

export default function SelectedSkillsetSettingConfiguration({
    config,
    defaultInputValue,
    skillsetConfigurations,
    failedValidationSettings,
    onInputChange,
    autoFocus,
}: SelectedSkillsetSettingConfigurationProps) {
    const classes = useClasses();
    const [inputText, setInputText] = React.useState<string>(defaultInputValue);
    const {t} = useTranslation('plugins');
    React.useEffect(() => {
        setInputText(defaultInputValue);
    }, [defaultInputValue]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputText(e.currentTarget.value);
        onInputChange(e);
    };

    return (
        <div key={config.name} className={classes.input}>
            <Field
                label={{
                    children: (
                        <MedeinaInfoLabel
                            labelContent={
                                <Text className={classes.configText}>
                                    {config.label ?? config.name}
                                </Text>
                            }
                            infoContent={config.description ?? ''}
                        />
                    ),
                }}
                required={config.required ?? false}
                validationState={failedValidationSettings.includes(config.name) ? 'error' : 'none'}
                validationMessage={
                    failedValidationSettings.includes(config.name)
                        ? t('Plugin.ConfigurePluginInputRequired', {
                              parameterName: config.name,
                          })
                        : ''
                }
            >
                {skillsetConfigurations && (
                    <Input
                        aria-label={config.label ?? config.name}
                        type={getSettingInputType(config.settingType ?? SkillsetSettingType.String)}
                        value={inputText}
                        key={config.name}
                        autoFocus={autoFocus}
                        size="large"
                        appearance="outline"
                        className={
                            config.settingType == SkillsetSettingType.String
                                ? classes.textareaWrapper
                                : classes.longTextareaWraper
                        }
                        placeholder={
                            config.hintText ??
                            t('Plugin.ConfigurePluginInputPlaceholder', {
                                parameterName: config.name,
                            })
                        }
                        onChange={handleInputChange}
                    />
                )}
            </Field>
            <br />
        </div>
    );
}
