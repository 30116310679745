import {
    Button,
    Caption1,
    Label,
    Link,
    MessageBar,
    MessageBarActions,
    MessageBarBody,
    Title3,
    Tooltip,
    mergeClasses,
} from '@fluentui/react-components';
import {ChangeEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {DismissIcon, PromptbookIcon, RunIcon} from '@/components/ui/icons';
import Textarea from '@/components/ui/Textarea';
import useClasses from './PromptbookFormNewSession.styles';
import {PromptbookInputDescriptor, PromptbookPrompts} from '@/api/promptbooks';
import {PromptInputs} from '@/api/prompts';
import {PromptBarPromptbookFormProps} from './PromptBarPromptbookForm.types';
//import PromptbookPrompt from '@/components/sections/promptbook/PromptbookPrompt';
import useScrollClasses from '@/components/ui/util/MedeinaScrollbar.styles';
import {useErrorMessages} from '@/api/errors';
import {ErrorMessage} from '@/api/errors.types';
import {ApiError} from '@/api/api';
import {useTranslation} from 'react-i18next';
import PromptbookPromptList from '@/components/sections/promptbooks/PromptbookPromptList';

export * from './PromptBarSkillForm.types';

export default function PromptbookFormNewSession({
    promptbook,
    promptbookInputs,
    promptbookPrompts,
    onChange,
    onSubmit,
    open,
    onPromptsChange,
    errorCreateSessionWithPromptbook,
}: PromptBarPromptbookFormProps) {
    const classes = useClasses();
    const inputRefs = useRef<{[key: string]: HTMLTextAreaElement | null}>({});
    const scrollClasses = useScrollClasses();
    const {t: tCommmon} = useTranslation('common');
    const {t: tSession} = useTranslation('session');
    const {t: tPromptbooks} = useTranslation('promptbooks');
    const {getErrorMessage} = useErrorMessages();

    // Generate input defaults.
    const inputs = promptbook.promptbookinputs;
    const prompts = promptbook.prompts;

    const [inputValues, setInputValues] = useState<PromptInputs>(
        promptbookInputs ?? (inputs && inputs.length > 0)
            ? inputs.reduce((values, input) => {
                  return values;
              }, {} as PromptInputs)
            : ({} as PromptInputs),
    );

    const [promptValues, setPromptValues] = useState<PromptbookPrompts[]>(prompts);
    const [runState, setRunState] = useState<boolean>(false);
    const [closeDisabled, setCloseDisabled] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<ErrorMessage | string | undefined>(undefined);

    // Reset parent state initially, otherwise it could include stale or empty data.
    useEffect(() => {
        onChange?.(inputValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Focus the first input on mount.
    useEffect(() => {
        if (inputs && inputs.length > 0 && inputs[0].name) {
            inputRefs.current[inputs[0].name]?.focus();
        }
    }, []);

    useEffect(() => {
        if (
            errorCreateSessionWithPromptbook !== undefined &&
            errorCreateSessionWithPromptbook !== null
        ) {
            setRunState(false);

            if (
                errorCreateSessionWithPromptbook instanceof ApiError &&
                errorCreateSessionWithPromptbook.response
            ) {
                if (errorCreateSessionWithPromptbook.response?.status === 403) {
                    const errorMessage = tCommmon('Errors.NoPermissionForFeature');
                    setErrorMessage(errorMessage);
                } else if (errorCreateSessionWithPromptbook.response?.status === 429) {
                    // For capacity middleware errors, we are using the status code to get the error message.
                    const errorMessage = getErrorMessage(429);
                    setErrorMessage(errorMessage);
                    setRunState(false);
                } else {
                    errorCreateSessionWithPromptbook.response.text().then((e) => {
                        const parsedError = JSON.parse(e);
                        let errorMessage =
                            parsedError.detail ??
                            parsedError.message ??
                            parsedError.errorMessage ??
                            parsedError.value ??
                            e.toString();

                        setErrorMessage(errorMessage);
                        setCloseDisabled(false);
                    });
                }
            } else {
                setErrorMessage(errorCreateSessionWithPromptbook.toString());
            }
        } else {
            setErrorMessage(undefined);
        }
    }, [errorCreateSessionWithPromptbook]);

    // Update state based on form changes.
    const handleChange = useCallback(
        (ev: ChangeEvent<HTMLTextAreaElement>, input: PromptbookInputDescriptor) => {
            const nextValues = {...inputValues, [input.name]: ev.target.value};
            setInputValues(nextValues);
            onChange?.(nextValues);
            //isValidated();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onChange],
    );

    const handleRunClick = () => {
        setRunState(true); // Disable the button when it is clicked
        setCloseDisabled(true);
        onSubmit?.();
    };

    const handlePromptListDataChange = (promptDefn: PromptbookPrompts[]) => {
        setPromptValues(promptDefn);
        onPromptsChange?.(promptDefn);
    };

    // Check if all input values are provided
    const isValidated = useMemo(() => {
        return inputs && inputs.every((input) => !!inputValues[input.name]);
    }, [inputs, inputValues]);

    return (
        <div>
            <div className={mergeClasses(classes.root, scrollClasses.colorNeutralBackground2)}>
                <div className={classes.flexcontainer}>
                    <div>
                        <PromptbookIcon className={classes.promptbookIcon} />
                    </div>
                    <div className={classes.promptbookBody} tabIndex={0}>
                        <div className={classes.container}>
                            <div className={classes.promptbook}>
                                <Tooltip relationship="label" content={promptbook.name}>
                                    <Title3 tabIndex={0} className={classes.promptbookName}>
                                        {promptbook.name}
                                    </Title3>
                                </Tooltip>
                                <Tooltip relationship="label" content={promptbook.description}>
                                    <Caption1
                                        tabIndex={0}
                                        className={classes.promptbookDescription}
                                    >
                                        {promptbook.description}
                                    </Caption1>
                                </Tooltip>
                            </div>
                            <div className={classes.runButtonContainer}>
                                <Button
                                    icon={<RunIcon />}
                                    onClick={handleRunClick}
                                    className={mergeClasses(
                                        (!isValidated || runState) && classes.runDisabled,
                                    )}
                                    disabled={!isValidated || runState}
                                    appearance="primary"
                                >
                                    {tPromptbooks('SubmitButton')}
                                </Button>
                            </div>
                        </div>
                        <div className={classes.errorMessage}>
                            {errorMessage && (
                                <MessageBar intent="error" layout="multiline">
                                    <MessageBarBody>
                                        {typeof errorMessage === 'string'
                                            ? errorMessage
                                            : errorMessage?.message}
                                        {errorMessage &&
                                            typeof errorMessage !== 'string' &&
                                            errorMessage?.learnMoreText &&
                                            errorMessage?.learnMoreUri && (
                                                <>
                                                    {' '}
                                                    <Link href={errorMessage.learnMoreUri}>
                                                        {errorMessage.learnMoreText}
                                                    </Link>
                                                </>
                                            )}
                                    </MessageBarBody>
                                    <MessageBarActions
                                        containerAction={
                                            <Button
                                                onClick={() => setErrorMessage(undefined)}
                                                aria-label={tSession('DismissButton')}
                                                appearance="transparent"
                                                icon={<DismissIcon />}
                                            />
                                        }
                                    />
                                </MessageBar>
                            )}
                        </div>
                        <div className={classes.inputs}>
                            {inputs &&
                                inputs.map((input) => (
                                    <>
                                        <Label
                                            id={`${input.name}-label`}
                                            className={classes.labelStyle}
                                        >
                                            {input.description}
                                        </Label>
                                        <Textarea
                                            key={input.name}
                                            ref={(el) => (inputRefs.current[input.name] = el)}
                                            value={inputValues[input.name] ?? ''}
                                            size="large"
                                            appearance="outline"
                                            className={classes.textareaWrapper}
                                            autosize
                                            onChange={(ev) => handleChange(ev, input)}
                                            onSubmit={handleRunClick}
                                            aria-labelledby={`${input.name}-label`}
                                        />
                                    </>
                                ))}
                        </div>
                        <div className={classes.prompts}>
                            {prompts && (
                                <PromptbookPromptList
                                    promptDefns={prompts}
                                    onPromptDataChange={handlePromptListDataChange}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
