import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import MedeinaVariables from '@/util/variables';
import {
    GetAggregatedCapacityUsageRequest,
    GetAggregatedCapacityUsageResponse,
} from './capacities.types';
import {WORKSPACE_PROVIDER_DEFAULT_STALE_TIME} from '../api.constants';
import {customFetch} from '../api';

export default function useGetAggregatedCapacityUsage(
    {capacityResourceName, startTime, endTime}: GetAggregatedCapacityUsageRequest,
    useQueryOptions?: Pick<UseQueryOptions<GetAggregatedCapacityUsageResponse>, 'enabled'>,
) {
    const url = `${MedeinaVariables.ApiUri}/usage/${capacityResourceName}?startTime=${startTime}&endTime=${endTime}`;

    return useQuery<GetAggregatedCapacityUsageResponse>({
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['capacities', capacityResourceName, startTime, endTime],
        staleTime: WORKSPACE_PROVIDER_DEFAULT_STALE_TIME,
        queryFn: () =>
            customFetch<GetAggregatedCapacityUsageResponse>(url, {
                method: 'GET',
                // Disable below to directly invoke Medeina API instead of Fidelis.
                // Issue in PROD where Fidelis call fails, whereas Mediena succeeds.
                //scopes: RESOURCE_SCOPES.FIDELIS,
            }),
        ...useQueryOptions,
    });
}
