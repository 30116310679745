import {AppState, ColorScheme, DebugLevel} from './app.types';

// These variables exist here because they're used with `jest.setup.ts`.
// Anything pulled in via `jest.setup.ts` cannot be mocked with `jest.mock()`,
// so we are reducing the amount of files pulled in as much as possible.

export const localStorageNamespace = 'AppState';

export const defaults: AppState = {
    colorScheme: ColorScheme.Default,
    debugLevel: DebugLevel.Summary,
    userTours: {
        admin: null,
        analyst: null,
        defenderIntro: null,
    },
    layout: {
        sidePanel: {
            open: false,
            width: 25,
        },
        contentArea: {
            fullWidth: false,
        },
    },
};

export function readFromLocalStorage(): AppState {
    const appState =
        typeof window !== 'undefined' && window.localStorage.getItem(localStorageNamespace);
    return appState
        ? Object.assign({}, defaults, JSON.parse(appState))
        : Object.assign({}, defaults);
}

export function writeToLocalStorage(appState: AppState): void {
    window.localStorage.setItem(localStorageNamespace, JSON.stringify(appState));
}
