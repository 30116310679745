import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        visibility: 'hidden',
    },
    visible: {
        visibility: 'visible',
    },
    dropdownTriggerBtn: {
        backgroundColor: tokens.colorNeutralBackground1,
        fontWeight: tokens.fontWeightRegular,
    },
});
