import {useState, useEffect} from 'react';
import {AuthorizationManagementClient} from '@azure/arm-authorization';
import {TokenCredential} from '@azure/core-auth';
import useArmToken from '@/util/msal/useArmToken';

type AzureRole =
    | 'Owner'
    | 'Contributor'
    | 'Reader'
    | 'User Access Administrator'
    | 'Network Contributor'
    | 'Storage Account Contributor';

interface UseCheckPermissionProps {
    /** Subscription ID that owns the resource */
    subscriptionId: string;
    /** Scope of the role assignment check. Could be:
     * Subscription Level: subscriptions/{subscriptionId}
     * Resource Group Level: subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}
     * Resource Level: subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/{resourceProviderNamespace}/{resourceType}/{resourceName}
     * */
    scope: string;
    /** The principal ID of the user or service principal to check */
    principalId: string;
    /** The roles required to perform the action */
    requiredRoles?: AzureRole[]; // e.g., ['Owner', 'Contributor', 'Reader']
    /** The action string to check for in role assignments, e.g., Microsoft.securitycopilot/capacities/write */
    action?: string;
}

function matchAction(pattern: string, action: string): boolean {
    // Convert wildcard patterns to regular expressions
    const regex = new RegExp(`^${pattern.replace(/\*/g, '.*')}$`);
    return regex.test(action);
}

function useCheckPermission({
    subscriptionId,
    scope,
    principalId,
    requiredRoles,
    action,
}: UseCheckPermissionProps) {
    const {token: accessToken} = useArmToken();
    const [hasPermission, setHasPermission] = useState<boolean | null>(null);
    const [error, setError] = useState<Error | null>(null);
    const [loading, setLoading] = useState<boolean | null>(null);

    useEffect(() => {
        const checkPermission = async () => {
            if (
                !accessToken ||
                !subscriptionId ||
                !scope ||
                (!principalId && (!requiredRoles || !action))
            ) {
                return;
            }

            const credential: TokenCredential = {
                getToken: async () => ({token: accessToken, expiresOnTimestamp: 0}),
            };

            const client = new AuthorizationManagementClient(credential, subscriptionId);

            try {
                const roleAssignments = client.roleAssignments.listForScope(scope);

                let roleAssignmentCount = 0;
                for await (const roleAssignment of roleAssignments) {
                    roleAssignmentCount++;
                    if (
                        roleAssignment.principalId === principalId &&
                        roleAssignment.scope &&
                        roleAssignment.roleDefinitionId
                    ) {
                        try {
                            // Extract the roleDefinitionId from the full URL
                            const roleDefinitionIdParts =
                                roleAssignment.roleDefinitionId.split('/');
                            const roleDefinitionId =
                                roleDefinitionIdParts[roleDefinitionIdParts.length - 1];
                            const roleDefinition = await client.roleDefinitions.get(
                                roleAssignment.scope,
                                roleDefinitionId,
                            );

                            if (
                                requiredRoles &&
                                requiredRoles.includes(roleDefinition.roleName as AzureRole)
                            ) {
                                setHasPermission(true);
                                return;
                            }

                            if (
                                action &&
                                roleDefinition?.permissions?.some((permission) =>
                                    permission?.actions?.some((a) => matchAction(a, action)),
                                )
                            ) {
                                setHasPermission(true);
                                return;
                            }
                        } catch (err) {
                            setError(err as Error);
                        }
                    }
                }
                setHasPermission(false);
            } catch (err) {
                setError(err as Error);
            } finally {
                setLoading(false);
            }
        };

        checkPermission();
    }, [accessToken, subscriptionId, scope, principalId, requiredRoles, action]);

    return {hasPermission, error, loading};
}

export default useCheckPermission;
