import {
    Button,
    Caption1,
    Dialog,
    DialogActions,
    DialogBody,
    DialogSurface,
    MenuItem,
    Subtitle1,
    Tooltip,
    mergeClasses,
    useRestoreFocusTarget,
    Body1,
    Body1Strong,
} from '@fluentui/react-components';
import {useEffect, useMemo, useRef, useState} from 'react';
import {
    DismissIcon,
    RunIcon,
    DuplicateIcon,
    EditIcon,
    DeleteIcon,
    CopyIcon,
    PromptbookListIcon,
    SaveIcon,
    ShareIcon,
} from '@/components/ui/icons';
import useClasses from './PromptbookLibraryForm.styles';
import {PromptInputs} from '@/api/prompts';
import {PromptBarPromptbookFormProps} from './PromptBarPromptbookForm.types';
import PromptbookPrompt from '@/components/sections/promptbook/PromptbookPrompt';
import MedeinaFeatures from '@/util/features';
import {useNavigate} from 'react-router-dom';
import {CircleSmallFilled} from '@fluentui/react-icons';
import DeletePromptbookDialog from '@/components/sections/promptbooks/DeletePromptbookDialog';
import {useMsalUserId} from '@/util/msal';
import useScrollClasses from '@/components/ui/util/MedeinaScrollbar.styles';
import {PromptbookCreateForm, PromptbookOperationMode} from '@/components/sections/promptbooks';
import {PromptbookVisibility, useGetPromptbookLink} from '@/api/promptbooks';
import PromptbookInputsForm from '@/components/sections/promptbooks/PromptbookInputsForm';
import PromptbookPlugins from '@/components/sections/promptbooks/PromptbookPlugins';
import {useGetSkillsets} from '@/api/skills';
import LinkCopiedDialog from '@/components/sections/promptbooks/LinkCopiedDialog';
import {useFeatureFlag} from '@/api/user';
import {useTranslation} from 'react-i18next';

export * from './PromptBarSkillForm.types';

//This function opens up a view model for promptbook which will have option to delete, duplicate, edit and dismiss.
export default function PromptbookLibraryForm({
    promptbook,
    promptbookInputs,
    promptbookPrompts,
    onCancel,
    onChange,
    onSubmit,
    open,
}: PromptBarPromptbookFormProps) {
    const classes = useClasses();
    const inputRefs = useRef<{[key: string]: HTMLTextAreaElement | null}>({});
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [openDuplicateDialog, setOpenDuplicateDialog] = useState<boolean>(false);
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
    const [showLinkToast, setShowLinkToast] = useState<boolean>(false);
    const restoreFocusTargetAttribute = useRestoreFocusTarget();
    // Generate input defaults.
    const inputs = promptbook.promptbookinputs;
    const prompts = promptbook.prompts;
    const navigate = useNavigate();
    const scrollClasses = useScrollClasses();
    const {t} = useTranslation('promptbooks');

    const {
        mutate: getPromptbookLink,
        isError: getPromptbookLinkError,
        reset,
        error,
        isLoading: getPromptbookLinkLoading,
    } = useGetPromptbookLink();

    const [inputValues, setInputValues] = useState<PromptInputs>(
        promptbookInputs ??
            inputs.reduce((values, input) => {
                return values;
            }, {} as PromptInputs),
    );
    const [closeDisabled, setCloseDisabled] = useState<boolean>(false);
    const userObjectId = useMsalUserId();

    const isSelfAuthored = useMemo<boolean>(
        () =>
            userObjectId === promptbook?.userId &&
            promptbook.visibility !== PromptbookVisibility.Global,
        [userObjectId, promptbook],
    );

    const {data: skillsets} = useGetSkillsets();

    const isShareable = useMemo<boolean>(
        () =>
            userObjectId === promptbook?.userId ||
            promptbook.visibility === PromptbookVisibility.Global ||
            promptbook.visibility === PromptbookVisibility.Tenant,
        [userObjectId, promptbook],
    );

    const promptbookPlugins = useMemo(() => {
        return promptbook.prompts.reduce((acc, prompt) => {
            prompt.plugins?.forEach((plugin) => {
                const matchedSkillset = skillsets?.value.find(
                    (skillset) => skillset.name === plugin,
                );
                if (matchedSkillset) {
                    acc.push({
                        skillset: matchedSkillset.name,
                        icon: matchedSkillset.icon || '',
                        displayName: matchedSkillset.displayName || '',
                    });
                }
            });
            return acc;
        }, [] as {skillset: string; icon: string; displayName: string}[]);
    }, [promptbook.prompts, skillsets?.value]);

    // Reset parent state initially, otherwise it could include stale or empty data.
    useEffect(() => {
        onChange?.(inputValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Focus the first input on mount.
    useEffect(() => {
        if (inputs && inputs.length > 0 && inputs[0].name) {
            inputRefs.current[inputs[0].name]?.focus();
        }
    }, []);

    const handleShareClicked = () => {
        getPromptbookLink(promptbook, {
            onSuccess: () => {
                setShowLinkToast(true);
            },
        });
    };

    return (
        <>
            <DeletePromptbookDialog
                {...{
                    promptbook: promptbook,
                    promptbookId: promptbook.promptbookId,
                    promptbookIds: [],
                }}
                open={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                    onCancel?.();
                }}
                onSuccess={() => {
                    setOpenDeleteDialog(false);
                    onCancel?.();
                }}
            />
            {promptbook && openDuplicateDialog && (
                <div>
                    <PromptbookCreateForm
                        promptbook={promptbook}
                        mode={PromptbookOperationMode.Duplicate}
                        open={openDuplicateDialog}
                        onClose={() => {
                            setOpenDuplicateDialog(false);
                        }}
                        onSuccess={() => {
                            setOpenDuplicateDialog(false);
                            onCancel?.();
                        }}
                        sessionId={''}
                        promptIds={[]}
                        promptbookId={''}
                    />
                </div>
            )}
            {promptbook && openEditDialog && (
                <div>
                    <PromptbookCreateForm
                        promptbook={promptbook}
                        mode={PromptbookOperationMode.Edit}
                        open={openEditDialog}
                        onClose={() => {
                            setOpenEditDialog(false);
                        }}
                        onSuccess={() => {
                            setOpenEditDialog(false);
                            onCancel?.();
                        }}
                        sessionId={''}
                        promptIds={[]}
                        promptbookId={''}
                    />
                </div>
            )}
            {showLinkToast && (
                <LinkCopiedDialog
                    open={showLinkToast}
                    onClose={() => setShowLinkToast(false)}
                ></LinkCopiedDialog>
            )}
            <Dialog
                modalType="modal"
                open={open}
                onOpenChange={(event, data) => {
                    if (data.type === 'escapeKeyDown' || data.type === 'backdropClick') {
                        onCancel?.();
                    }
                }}
            >
                <DialogSurface
                    className={mergeClasses(classes.root, scrollClasses.colorNeutralBackground1)}
                >
                    <DialogActions className={classes.buttonContainer}>
                        <div>
                            <Button
                                icon={<RunIcon />}
                                onClick={() =>
                                    MedeinaFeatures.NewSessionPage
                                        ? navigate(`/sessions/new/${promptbook?.promptbookId}`)
                                        : navigate('/')
                                }
                                appearance="primary"
                            >
                                {'Start new session'}
                            </Button>
                        </div>
                        <div className={classes.rightIcons} role="menu">
                            <Tooltip content={t('DuplicatePromptbookButton')} relationship="label">
                                <MenuItem
                                    data-testid="duplicate-menu"
                                    role="menuitem"
                                    icon={<DuplicateIcon />}
                                    {...restoreFocusTargetAttribute}
                                    onClick={() => {
                                        setOpenDuplicateDialog(true);
                                    }}
                                ></MenuItem>
                            </Tooltip>
                            {isShareable && (
                                <>
                                    <Tooltip
                                        content={t('SharePromptbookButton')}
                                        relationship="label"
                                    >
                                        <MenuItem
                                            data-testid="share-menu"
                                            role="menuitem"
                                            icon={<ShareIcon />}
                                            onClick={handleShareClicked}
                                        ></MenuItem>
                                    </Tooltip>
                                </>
                            )}
                            {isSelfAuthored && (
                                <>
                                    <Tooltip
                                        content={t('EditPromptbookButton')}
                                        relationship="label"
                                    >
                                        <MenuItem
                                            data-testid="edit-menu"
                                            role="menuitem"
                                            icon={<EditIcon />}
                                            onClick={() => {
                                                setOpenEditDialog(true);
                                            }}
                                        ></MenuItem>
                                    </Tooltip>
                                    <Tooltip
                                        content={t('DeletePromptbookButton')}
                                        relationship="label"
                                    >
                                        <MenuItem
                                            data-testid="delete-menu"
                                            role="menuitem"
                                            icon={<DeleteIcon />}
                                            onClick={() => setOpenDeleteDialog(true)}
                                        ></MenuItem>
                                    </Tooltip>
                                </>
                            )}
                            <MenuItem
                                icon={<DismissIcon />}
                                onClick={onCancel}
                                aria-label="close"
                                disabled={closeDisabled}
                                className={classes.dismissIcon}
                            ></MenuItem>
                        </div>
                    </DialogActions>
                    <DialogBody className={classes.flexcontainer}>
                        <div className={classes.promptbookBody}>
                            <div className={classes.promptbookNameAndDescription}>
                                <div className={classes.promptbookName}>
                                    <Subtitle1
                                        data-testid="promptbook-title"
                                        className={classes.titleContainer}
                                        tabIndex={0}
                                    >
                                        {promptbook.name}
                                    </Subtitle1>
                                    <div className={classes.createdByContainer}>
                                        <Caption1 tabIndex={0}>
                                            Created by {promptbook.ownerName}
                                        </Caption1>
                                        <CircleSmallFilled />
                                        <Caption1>
                                            {promptbook.prompts.length ?? '0'} prompts&nbsp;
                                        </Caption1>
                                        <PromptbookListIcon />
                                    </div>
                                    <div className={classes.createdByContainer} tabIndex={0}>
                                        <Caption1>Tags:&nbsp;</Caption1>
                                        {promptbook.tags?.map((currentTag, index) => (
                                            <Caption1 key={index + '-' + currentTag.tag}>
                                                {currentTag.tag}
                                                {index < promptbook.tags!.length - 1 ? ',' : ''}
                                                &nbsp;
                                            </Caption1>
                                        ))}
                                    </div>
                                </div>
                                <div className={classes.promptbookDescription}>
                                    <Body1 tabIndex={0}>{promptbook.description}</Body1>
                                </div>
                            </div>

                            {inputs && inputs.length > 0 && (
                                <div className={classes.inputs}>
                                    <Body1Strong tabIndex={0}>Inputs you&apos;ll need </Body1Strong>
                                    <PromptbookInputsForm
                                        edit={false}
                                        formData={[...inputs]}
                                        onFormDataChange={() => {}}
                                    />
                                </div>
                            )}

                            {promptbookPlugins && promptbookPlugins.length > 0 && (
                                <div className={classes.inputs}>
                                    <Body1Strong tabIndex={0}>Plugins required</Body1Strong>
                                    <PromptbookPlugins
                                        shouldOverflow={false}
                                        plugins={promptbookPlugins}
                                    />
                                </div>
                            )}

                            <div className={classes.prompts}>
                                <Body1Strong tabIndex={0}>Prompts</Body1Strong>
                                <div className={classes.promptsContainer}>
                                    {prompts &&
                                        prompts.map((prompt, index) => (
                                            <PromptbookPrompt
                                                key={index}
                                                index={index}
                                                prompt={prompt}
                                            />
                                        ))}
                                </div>
                            </div>
                        </div>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </>
    );
}
