import React, {useEffect, useState} from 'react';
import {useTourContext} from '@/components/ui/Tour';
import AdminTour from './AdminTour';
import {useLayout} from '@/components/ui/Layout';
import {BackgroundAnimation, BackgroundImage} from '@/components/ui/Background';
import useTourHistoryTelemetry from '@/components/ui/Tour/useTourHistoryTelemetry';
import useDetermineInitialFreSetup from './stops/useDetermineInitialFreSetup';
import {ProgressBar} from '@fluentui/react-components';
import useClasses from './AdminTour.styles';
import {TOUR_ERRORS} from './stops/AdminTour.types';
import PoweringUp from './PoweringUp';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import {GridContainer} from '@/components/ui/Grid';
import {useTranslation} from 'react-i18next';

function AdminTourContainer() {
    const [dataLoaded, setDataloaded] = useState<boolean>(false);

    const {onTourStart, onTourComplete, onTourExitEarly} = useTourHistoryTelemetry({
        tourName: 'admin',
    });
    const {background} = useLayout();
    const classes = useClasses();
    const tourClasses = useTourClasses();

    const {t} = useTranslation('tours');

    const {
        dataLoadedSuccessfully,
        freStage,
        dataLoading,
        workspacesIsError,
        dataShareIsError,
        isFreCompleted,
        workspaceName,
        overrideStepRedirect,
        capacitiesIsDataError,
    } = useDetermineInitialFreSetup(!dataLoaded);

    useEffect(() => {
        if (dataLoadedSuccessfully) {
            setDataloaded(true);
        }
    }, [dataLoadedSuccessfully]);

    const {
        setTour,
        currentStop,
        activeTour,
        setDefaultV2TourError,
        v2TourError,
        setTourErrorV2,
        errorPage,
        showOverlay,
        loadingState,
        visibleTourStopCount,
    } = useTourContext();

    const {content} = currentStop || {};

    useEffect(() => {
        if (!!workspacesIsError || !!dataShareIsError || !!capacitiesIsDataError) {
            setTourErrorV2(TOUR_ERRORS.Generic);
        }

        if (
            !v2TourError &&
            (!!workspacesIsError ||
                !!dataShareIsError ||
                !!capacitiesIsDataError ||
                (dataLoadedSuccessfully && !activeTour))
        ) {
            setTour(
                AdminTour({
                    telemetryOptions: {onTourComplete, onTourExitEarly, onTourStart},
                    freStage: freStage,
                    overrideStep: overrideStepRedirect,
                    isFreComplete: isFreCompleted,
                    workspaceName: workspaceName || '',
                }),
            );
        } else if (!v2TourError) {
        } else {
            setDefaultV2TourError();
        }
    }, [
        dataLoadedSuccessfully,
        dataLoading,
        workspacesIsError,
        dataShareIsError,
        capacitiesIsDataError,
    ]);

    useEffect(() => {
        background?.setImage?.(BackgroundImage.triangles);
        background?.setAnimation?.(BackgroundAnimation.bottomGradient);
        //setTourErrorV2(null);
        // run only when component loads
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [canShowContent, setCanShowContent] = useState<boolean>(false);

    const onPoweringUpComplete = () => {
        setCanShowContent(true);
    };
    return (
        <div className={tourClasses.tourCenterWrapper} data-testid="tour-center-wrapper">
            {canShowContent && !v2TourError && (
                <ProgressBar
                    className={classes.progressBar}
                    aria-label={t('adminV2Fre.container.ariaLabel.progressBar')}
                    value={currentStop?.stepValue ?? 1 / visibleTourStopCount()}
                    max={visibleTourStopCount()}
                />
            )}
            <GridContainer
                data-testid="admin-tour-container-root"
                className={tourClasses.tourContainerRoot}
            >
                {!loadingState &&
                !v2TourError &&
                ((dataLoading && !dataLoadedSuccessfully) || !canShowContent) ? (
                    <PoweringUp onDisplayComplete={onPoweringUpComplete} />
                ) : (
                    !v2TourError && activeTour && !showOverlay && <>{canShowContent && content}</>
                )}
            </GridContainer>
            {!!v2TourError && activeTour && !showOverlay && errorPage}
        </div>
    );
}

export default AdminTourContainer;
