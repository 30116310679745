import {PromptBarSubmitOptions} from '@/components/ui';
import {useNavigate} from 'react-router-dom';

import {useUserState} from '@/api/user/user';
import useGetUserDetails from '@/api/user/useGetUserDetails';
import {subtractHoursFromDate} from '@/util/subtractHoursFromDate';
import {CreateSessionWithPromptbookRequest, useCreateSessionWithPrompt} from '@/api/sessions';
import useCreateSessionWithPromptbook from '@/api/sessions/useCreateSessionWithPromptbook';
import {useCallback} from 'react';
import {featureFlags} from '@/util/hasStaticFeatureFlags';
import {useErrorMessages} from '@/api/errors';

export interface PromptbarSubmitType {
    onSubmit?: (param: PromptBarSubmitOptions) => void;
    isErrorCreateSessionWithPrompt: boolean;
    resetCreateSessionWithPrompt: () => void;
    errorCreateSessionWithPrompt: Promise<unknown> | unknown;
    promptbookLoading: boolean;
    promptbookApplySuccess: boolean;
    errorCreateSessionWithPromptbook: Promise<unknown> | unknown;
}

const usePromptbarSubmit = (): PromptbarSubmitType => {
    const {toggleDailyTip} = useUserState();
    const {data: userDetails} = useGetUserDetails();
    const {
        mutate: createSessionWithPrompt,
        isError: isErrorCreateSessionWithPrompt,
        reset: resetCreateSessionWithPrompt,
        error: errorCreateSessionWithPrompt,
    } = useCreateSessionWithPrompt();
    const {
        mutate: createSessionWithPromptbook,
        isLoading: promptbookLoading,
        isSuccess: promptbookApplySuccess,
        error: errorCreateSessionWithPromptbook,
        failureReason,
    } = useCreateSessionWithPromptbook();
    const navigate = useNavigate();

    const params = new URLSearchParams(window.location.search);
    const featureFlagsParams = featureFlags();
    const skillsetsParams = params.get('skillsets');

    // Create a new session with its first interaction on submit.
    const onSubmit = useCallback(
        ({skillsets, featureFlags, ...prompt}: PromptBarSubmitOptions) => {
            // Before prompt creation, check if user is new/inactive and set showDailyTip accordingly.
            toggleDailyTip(
                !!userDetails?.lastPromptExecutedAt
                    ? new Date(userDetails?.lastPromptExecutedAt) <
                          subtractHoursFromDate(new Date(), 12)
                    : true,
            );
            // creating session with prompt only for cases when it is not promptbook
            if (!prompt.promptbookId) {
                createSessionWithPrompt(
                    {skillsets, featureFlags, prompt},
                    {
                        onSuccess: (session) => {
                            // Only include params if they're defined.
                            const params = [
                                skillsetsParams && `skillsets=${skillsetsParams}`,
                                featureFlagsParams && `featureFlags=${featureFlagsParams}`,
                            ].filter((param) => !!param);

                            // Push a URL change to go to the session.
                            navigate(
                                `/sessions/${session.sessionId}${
                                    params && params.length > 0 ? `?${params.join('&')}` : ''
                                }`,
                            );
                        },
                    },
                );
            } else {
                // creating the body for the promptbook apply.
                const promptbook: CreateSessionWithPromptbookRequest = {
                    content: prompt.content,
                    promptbook: {
                        promptbookId: prompt.promptbookId,
                        overriddenPrompts: prompt.promptbookPrompts,
                        inputs: prompt.inputs,
                    },
                    skillsets: skillsets,
                    featureFlags: featureFlags,
                };

                createSessionWithPromptbook(promptbook, {
                    onSuccess: (session) => {
                        // Only include params if they're defined.
                        const params = [
                            skillsetsParams && `skillsets=${skillsetsParams}`,
                            featureFlagsParams && `featureFlags=${featureFlagsParams}`,
                        ].filter((param) => !!param);

                        // Push a URL change to go to the session.
                        navigate(
                            `/sessions/${session.sessionId}${
                                params && params.length > 0 ? `?${params.join('&')}` : ''
                            }`,
                        );
                    },
                });
            }
        },
        [
            createSessionWithPrompt,
            navigate,
            skillsetsParams,
            featureFlagsParams,
            toggleDailyTip,
            userDetails,
            createSessionWithPromptbook,
        ],
    );

    return {
        onSubmit,
        isErrorCreateSessionWithPrompt,
        resetCreateSessionWithPrompt,
        errorCreateSessionWithPrompt,
        promptbookLoading,
        promptbookApplySuccess,
        errorCreateSessionWithPromptbook,
    };
};

export default usePromptbarSubmit;
