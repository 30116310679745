import {UserProfile} from '@/api/user/user.types';
import {CheckboxProps} from '@fluentui/react-components';

export interface FilterConfiguration {
    /**
     * The React node that represents the filter component.
     */
    node: React.ReactNode;
}

export type FilteringConfiguration = Array<FilterConfiguration>;

export interface FilterDrawerProps {
    /**
     * Array of filter configurations to be displayed in the filter drawer.
     */
    filterConfiguration: FilteringConfiguration;

    /**
     * Function to be called when the drawer is closed.
     */
    onClose: () => void;

    /**
     * Title to be displayed at the top of the filter drawer.
     */
    filterTitle: string;

    /**
     * Optional function to be called when the "Apply All" button is clicked.
     * Receives an object with selected filter options.
     */
    onApplyAll?: (selectedFilters: {[key: string]: string[]}) => void;

    /**
     * Optional function to be called when the "Reset All" button is clicked.
     */
    onResetAll?: () => void;

    /**
     * Optional function to be called when a specific panel is reset.
     * Receives the updated selected filters.
     */
    onResetPanel?: (updatedFilters: {[key: string]: string[]}) => void;

    /**
     * The currently selected filters.
     */
    selectedFilters: {[key: string]: string[]};
}

export interface FilterContainerProps {
    /**
     * Array of filter configurations to be displayed in the filter container.
     */
    FilterConfiguration: FilteringConfiguration;
}

export type UserDisplayData = Partial<UserProfile> & {
    id: Required<UserProfile['id']>;
    displayName: Required<UserProfile['displayName']>;
};

export type PanelData = string[] | UserDisplayData[];

export interface UsageMonitoringFilterPanelProps {
    /**
     * Order in which the panel should be displayed.
     */
    order: number;

    /**
     * Title of the filter panel.
     */
    title: string;

    /**
     * Identifier category of the panel
     */
    category: string;

    /**
     * Data to be displayed in the filter panel.
     * Can be an array of strings or partial user profiles.
     */
    data: PanelData;

    /**
     * Flag indicating if multiselect functionality should be shown.
     */
    showMultiselect: boolean;

    /**
     * Flag indicating if filter search functionality should be shown in multiselect.
     */
    showMultiSelectFilterSearch: boolean;

    /**
     * Flag indicating if user filter search functionality should be shown.
     */
    showUserFilterSearch: boolean;

    /**
     * Optional array of currently selected options.
     */
    selectedOptions?: string[];

    /**
     * Optional function to be called when selected options change.
     * Receives an array of selected options.
     */
    onSelectionChange?: (selectedOptions: string[]) => void;

    /**
     * Optional function to be called to reset the panel.
     */
    onReset?: () => void;
}

export interface MultiSelectProps {
    /**
     * Array of options to be displayed in the multiselect component.
     */
    options: string[];

    /**
     * Flag indicating if filter search functionality should be shown.
     */
    showFilterSearch: boolean;

    /**
     * Array of currently selected options.
     */
    selectedOptions: string[];

    /**
     * Function to be called when selected options change.
     * Receives an array of selected options.
     */
    onSelectionChange: (selectedOptions: string[]) => void;
}

export interface CheckboxGroupProps {
    /**
     * Array of options to be displayed in the checkbox group.
     */
    options: string[];

    /**
     * Array of currently selected options.
     */
    selectedOptions: string[];

    /**
     * Function to be called when a checkbox option is changed.
     * Receives the option that was changed.
     */
    onCheckboxChange: (option: string) => void;
}

export interface CustomCheckboxProps extends CheckboxProps {
    /**
     * Optional source URL of the image to be displayed with the checkbox.
     */
    imageSrc?: string;

    /**
     * Optional alt text for the image to be displayed with the checkbox.
     */
    imageAlt?: string;

    /**
     * Text to be displayed with the checkbox.
     */
    text: string;
}

export enum UsageMonitoringFilterPanelType {
    CopilotExperience = 'Copilot Experience',
    Status = 'Status',
    Users = 'Users',
    InvocationCategory = 'Invocation Category',
    Plugins = 'Plugins',
}

export const PIPE_DELIMITER = '|';
