import { iconFilledClassName, iconRegularClassName } from '@fluentui/react-icons';
import { createCustomFocusIndicatorStyle } from '@fluentui/react-tabster';
import { tokens } from '@fluentui/react-theme';
import { shorthands, mergeClasses, makeStyles } from '@griffel/react';
import { useButtonStyles_unstable } from '../Button/useButtonStyles.styles';
export const toggleButtonClassNames = {
    root: 'fui-ToggleButton',
    icon: 'fui-ToggleButton__icon'
};
const useRootCheckedStyles = makeStyles({
    // Base styles
    base: {
        backgroundColor: tokens.colorNeutralBackground1Selected,
        ...shorthands.borderColor(tokens.colorNeutralStroke1),
        color: tokens.colorNeutralForeground1Selected,
        ...shorthands.borderWidth(tokens.strokeWidthThin),
        [`& .${iconFilledClassName}`]: {
            display: 'inline'
        },
        [`& .${iconRegularClassName}`]: {
            display: 'none'
        },
        ':hover': {
            backgroundColor: tokens.colorNeutralBackground1Hover,
            ...shorthands.borderColor(tokens.colorNeutralStroke1Hover),
            color: tokens.colorNeutralForeground1Hover
        },
        ':hover:active': {
            backgroundColor: tokens.colorNeutralBackground1Pressed,
            ...shorthands.borderColor(tokens.colorNeutralStroke1Pressed),
            color: tokens.colorNeutralForeground1Pressed
        }
    },
    // High contrast styles
    highContrast: {
        '@media (forced-colors: active)': {
            backgroundColor: 'Highlight',
            ...shorthands.borderColor('Highlight'),
            color: 'HighlightText',
            forcedColorAdjust: 'none',
            ':hover': {
                backgroundColor: 'HighlightText',
                ...shorthands.borderColor('Highlight'),
                color: 'Highlight'
            },
            ':hover:active': {
                backgroundColor: 'HighlightText',
                ...shorthands.borderColor('Highlight'),
                color: 'Highlight'
            },
            ':focus': {
                ...shorthands.border('1px', 'solid', 'HighlightText'),
                outlineColor: 'Highlight'
            }
        }
    },
    // Appearance variations
    outline: {
        backgroundColor: tokens.colorTransparentBackgroundSelected,
        ...shorthands.borderColor(tokens.colorNeutralStroke1),
        ...shorthands.borderWidth(tokens.strokeWidthThicker),
        ':hover': {
            backgroundColor: tokens.colorTransparentBackgroundHover
        },
        ':hover:active': {
            backgroundColor: tokens.colorTransparentBackgroundPressed
        },
        ...createCustomFocusIndicatorStyle({
            ...shorthands.borderColor(tokens.colorNeutralStroke1)
        })
    },
    primary: {
        backgroundColor: tokens.colorBrandBackgroundSelected,
        ...shorthands.borderColor('transparent'),
        color: tokens.colorNeutralForegroundOnBrand,
        ':hover': {
            backgroundColor: tokens.colorBrandBackgroundHover,
            ...shorthands.borderColor('transparent'),
            color: tokens.colorNeutralForegroundOnBrand
        },
        ':hover:active': {
            backgroundColor: tokens.colorBrandBackgroundPressed,
            ...shorthands.borderColor('transparent'),
            color: tokens.colorNeutralForegroundOnBrand
        }
    },
    secondary: {
    },
    subtle: {
        backgroundColor: tokens.colorSubtleBackgroundSelected,
        ...shorthands.borderColor('transparent'),
        color: tokens.colorNeutralForeground2Selected,
        ':hover': {
            backgroundColor: tokens.colorSubtleBackgroundHover,
            ...shorthands.borderColor('transparent'),
            color: tokens.colorNeutralForeground2Hover
        },
        ':hover:active': {
            backgroundColor: tokens.colorSubtleBackgroundPressed,
            ...shorthands.borderColor('transparent'),
            color: tokens.colorNeutralForeground2Pressed
        }
    },
    transparent: {
        backgroundColor: tokens.colorTransparentBackgroundSelected,
        ...shorthands.borderColor('transparent'),
        color: tokens.colorNeutralForeground2BrandSelected,
        ':hover': {
            backgroundColor: tokens.colorTransparentBackgroundHover,
            ...shorthands.borderColor('transparent'),
            color: tokens.colorNeutralForeground2BrandHover
        },
        ':hover:active': {
            backgroundColor: tokens.colorTransparentBackgroundPressed,
            ...shorthands.borderColor('transparent'),
            color: tokens.colorNeutralForeground2BrandPressed
        }
    }
});
const useRootDisabledStyles = makeStyles({
    // Base styles
    base: {
        backgroundColor: tokens.colorNeutralBackgroundDisabled,
        ...shorthands.borderColor(tokens.colorNeutralStrokeDisabled),
        color: tokens.colorNeutralForegroundDisabled,
        ':hover': {
            backgroundColor: tokens.colorNeutralBackgroundDisabled,
            ...shorthands.borderColor(tokens.colorNeutralStrokeDisabled),
            color: tokens.colorNeutralForegroundDisabled
        },
        ':hover:active': {
            backgroundColor: tokens.colorNeutralBackgroundDisabled,
            ...shorthands.borderColor(tokens.colorNeutralStrokeDisabled),
            color: tokens.colorNeutralForegroundDisabled
        }
    },
    // Appearance variations
    outline: {
    },
    primary: {
        ...shorthands.borderColor('transparent'),
        ':hover': {
            ...shorthands.borderColor('transparent')
        },
        ':hover:active': {
            ...shorthands.borderColor('transparent')
        }
    },
    secondary: {
    },
    subtle: {
        backgroundColor: tokens.colorTransparentBackground,
        ...shorthands.borderColor('transparent'),
        ':hover': {
            backgroundColor: tokens.colorTransparentBackgroundHover,
            ...shorthands.borderColor('transparent')
        },
        ':hover:active': {
            backgroundColor: tokens.colorTransparentBackgroundPressed,
            ...shorthands.borderColor('transparent')
        }
    },
    transparent: {
        backgroundColor: tokens.colorTransparentBackground,
        ...shorthands.borderColor('transparent'),
        ':hover': {
            backgroundColor: tokens.colorTransparentBackgroundHover,
            ...shorthands.borderColor('transparent')
        },
        ':hover:active': {
            backgroundColor: tokens.colorTransparentBackgroundPressed,
            ...shorthands.borderColor('transparent')
        }
    }
});
const useIconCheckedStyles = makeStyles({
    // Appearance variations
    subtleOrTransparent: {
        color: tokens.colorNeutralForeground2BrandSelected
    },
    // High contrast styles
    highContrast: {
        '@media (forced-colors: active)': {
            forcedColorAdjust: 'auto'
        }
    }
});
const usePrimaryHighContrastStyles = makeStyles({
    // Do not use primary variant high contrast styles for toggle buttons
    // otherwise there isn't enough difference between on/off states
    base: {
        '@media (forced-colors: active)': {
            backgroundColor: 'ButtonFace',
            ...shorthands.borderColor('ButtonBorder'),
            color: 'ButtonText',
            forcedColorAdjust: 'auto'
        }
    },
    disabled: {
        '@media (forced-colors: active)': {
            ...shorthands.borderColor('GrayText'),
            color: 'GrayText',
            ':focus': {
                ...shorthands.borderColor('GrayText')
            }
        }
    }
});
export const useToggleButtonStyles_unstable = (state)=>{
    const rootCheckedStyles = useRootCheckedStyles();
    const rootDisabledStyles = useRootDisabledStyles();
    const iconCheckedStyles = useIconCheckedStyles();
    const primaryHighContrastStyles = usePrimaryHighContrastStyles();
    const { appearance, checked, disabled, disabledFocusable } = state;
    state.root.className = mergeClasses(toggleButtonClassNames.root, // Primary high contrast styles
    appearance === 'primary' && primaryHighContrastStyles.base, appearance === 'primary' && (disabled || disabledFocusable) && primaryHighContrastStyles.disabled, // Checked styles
    checked && rootCheckedStyles.base, checked && rootCheckedStyles.highContrast, appearance && checked && rootCheckedStyles[appearance], // Disabled styles
    (disabled || disabledFocusable) && rootDisabledStyles.base, appearance && (disabled || disabledFocusable) && rootDisabledStyles[appearance], // User provided class name
    state.root.className);
    if (state.icon) {
        state.icon.className = mergeClasses(toggleButtonClassNames.icon, (appearance === 'subtle' || appearance === 'transparent') && iconCheckedStyles.subtleOrTransparent && iconCheckedStyles.highContrast, state.icon.className);
    }
    useButtonStyles_unstable(state);
    return state;
};
