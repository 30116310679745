import {useEffect} from 'react';
import useManageUsageDashboardState from './useManageUsageDashboardState';
import useManageUsageDashboardDataSources from './useManageUsageDashboardDataSources';
import {SortingDirection} from '@/api/api';

interface ManageUsageDashboardProps {
    capacityName: string;
    startDate: Date;
    endDate: Date;
    pageNumber: number;
    sortDirection: SortingDirection;
}

export default function useManageUsageDashboard(props: ManageUsageDashboardProps) {
    const {
        getState,
        updateTimePeriod,
        updatePageNumber,
        updateFilters,
        needsFacetRefresh,
        needsHourlyDataRefresh,
        needsDimensionalDataRefresh,
        updateSortDirection,
    } = useManageUsageDashboardState({
        startDate: props.startDate,
        endDate: props.endDate,
    });

    const {
        hourlyDataModel,
        dimensionalDataModel,
        facetDataModel,
        isDataFacetsError,
        isHourlyCapacityUsageError,
        isDimensionalDataError,
        hourlyCapacityUsageDataUpdatedAt,
        isDimensionalDataLoading,
        isDimensionalDataRefetching,
        dataFacetsLoading,
        hourlyCapacityUsageLoading,
        isAggregateDataFetched,
    } = useManageUsageDashboardDataSources({
        capacityName: props.capacityName,
        state: getState(),
        needsFacetRefresh,
        needsHourlyDataRefresh,
        needsDimensionalDataRefresh,
    });

    const isUsageDataLoading =
        dataFacetsLoading || hourlyCapacityUsageLoading || isDimensionalDataLoading;

    const pageLoading =
        needsDimensionalDataRefresh &&
        needsFacetRefresh &&
        needsHourlyDataRefresh &&
        isUsageDataLoading;

    const isEvaluationAggregateDataLoading =
        needsDimensionalDataRefresh && (isDimensionalDataLoading || isDimensionalDataRefetching);

    const pageError = isDataFacetsError || isHourlyCapacityUsageError || isDimensionalDataError;

    const lastUpdatedAt = hourlyCapacityUsageDataUpdatedAt || new Date();

    // Update the Usage Dashboard when the page number changes inside an useEffect
    useEffect(() => {
        updatePageNumber(props.pageNumber);
    }, [props.pageNumber]);

    return {
        getState,
        hourlyDataModel,
        dimensionalDataModel,
        facetDataModel,
        updateTimePeriod,
        updatePageNumber,
        updateFilters,
        updateSortDirection,
        pageLoading,
        isEvaluationAggregateDataLoading: !isAggregateDataFetched,
        pageError,
        lastUpdatedAt,
        dateRangeOption: getState()?.dateText ?? '',
    };
}
