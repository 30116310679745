import { makeResetStyles, mergeClasses, shorthands } from '@griffel/react';
export const toastFooterClassNames = {
    root: 'fui-ToastFooter'
};
/**
 * Styles for the root slot
 */ const useRootBaseClassName = makeResetStyles({
    paddingTop: '16px',
    gridColumnStart: 2,
    gridColumnEnd: 3,
    display: 'flex',
    alignItems: 'center',
    ...shorthands.gap('14px')
});
/**
 * Apply styling to the ToastFooter slots based on the state
 */ export const useToastFooterStyles_unstable = (state)=>{
    const rootBaseClassName = useRootBaseClassName();
    state.root.className = mergeClasses(toastFooterClassNames.root, rootBaseClassName, state.root.className);
    return state;
};
