import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        ...shorthands.gap(tokens.spacingHorizontalL),
        width: '100%',
        height: '100%',
    },
    card: {
        boxSizing: 'border-box',
        height: '128px',
        // min width is required to prevent flex-box
        // from sizing based off long content
        minWidth: '0px',
        '@media (min-width: 1024px) and (max-width: 1365px)': {
            height: '128px',
        },
        '@media (min-width: 640px) and (max-width: 1023px)': {
            height: '128px',
        },
        '@media (min-width: 480px) and (max-width: 639px)': {
            height: '124px',
        },
        '@media (max-width: 479px)': {
            height: '124px',
        },
    },
    cardEmpty: {
        backgroundColor: tokens.colorNeutralStrokeAlpha,
        ...shorthands.borderRadius('10px'),
    },
    cardAcrylic: {
        height: '100%',
    },
    cardContent: {
        backgroundColor: tokens.colorNeutralBackground3,
        color: tokens.colorNeutralForeground3,
        ...shorthands.borderRadius(tokens.borderRadiusXLarge),
        boxSizing: 'border-box',
        height: '100%',
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalXXL),
        width: '100%',
    },
    cardFooter: {
        display: 'flex',
        boxSizing: 'border-box',
        ...shorthands.flex(0, 0, 'auto'),
        flexDirection: 'row',
        ...shorthands.gap(tokens.spacingHorizontalXXL),
        fontSize: tokens.fontSizeBase400,
        justifyContent: 'space-between',
        lineHeight: tokens.lineHeightBase400,
        ...shorthands.padding(tokens.spacingVerticalXS, tokens.spacingHorizontalXXL, 0), //
        width: '100%',
        '> *': {
            ...shorthands.flex(0, 0, 'auto'),
        },
    },
    badge: {
        backgroundColor: tokens.colorBrandBackgroundInvertedSelected,
        color: tokens.colorNeutralForegroundInverted,
        marginRight: tokens.spacingHorizontalS,
    },
    hiddenPromptbar: {
        display: 'none',
    },
    summary: {
        fontSize: tokens.fontSizeBase200,
        lineHeight: tokens.lineHeightBase400,

        '& svg': {
            fontSize: tokens.fontSizeBase300,
            marginBottom: '-2px',
        },

        '@media (min-width: 1920px)': {
            'max-width': '280px',
        },
        '@media (min-width: 1366px) and (max-width: 1919px)': {
            'max-width': '280px',
        },
        '@media (min-width: 1024px) and (max-width: 1365px)': {
            'max-width': '204.67px',
        },
        '@media (min-width: 640px) and (max-width: 1023px)': {
            'max-width': '178px',
        },
        '@media (min-width: 480px) and (max-width: 639px)': {
            'max-width': '345px',
        },
        '@media (max-width: 480px)': {
            'max-width': '170px',
        },

        'white-space': 'nowrap',
        'text-overflow': 'ellipsis',
        ...shorthands.overflow('hidden'),
    },
    summaryContent: {
        lineHeight: tokens.lineHeightBase400,
    },

    // Promptbook additional stuff
    promptbookCard: {
        backgroundColor: 'none',
        ...shorthands.padding('0px'),
        ...shorthands.borderRadius('10px'),
        ':hover': {
            backgroundColor: 'none',
        },
    },
    promptbookAcrylic: {
        height: '-webkit-fill-available',
        // This is super brittle, but only way I could get Griffel to style
        // sub elements differently on hover
        '&:hover': {
            // Leaving this here as design is working on the correct color selection
            // for hover state (light and dark) and how it impacts the child elements.
            // This selector was a pain to get right, so leaving it.  It highlights
            // the top of the promptbook card.
            // '& > :first-child' : {
            //     backgroundColor: tokens.colorNeutralBackground3Pressed,
            // },
            '& > :nth-child(4)': {
                backgroundColor: tokens.colorNeutralBackgroundInvertedDisabled,
            },
        },
    },
    promptbookCardContent: {
        ...shorthands.borderRadius(tokens.borderRadiusXLarge, tokens.borderRadiusXLarge, 0, 0),
        ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalL),
        display: 'flex',
        rowGap: tokens.spacingHorizontalXS,
        flexDirection: 'column',
        boxShadow: tokens.shadow8,
    },
    promptbookCardFooter: {
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalL),
    },
    promptbookTitle: {
        fontSize: '16px',
        '@media (max-width: 479px)': {
            'white-space': 'nowrap',
            'text-overflow': 'ellipsis',
            maxWidth: '240px',
        },
        cursor: 'pointer',
    },
    promptbookDescription: {
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
        'text-overflow': 'ellipsis',
        ...shorthands.overflow('hidden'),
    },
    viewPromptbooks: {
        ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalSNudge),
        textAlign: 'right',

        '> a': {
            color: tokens.colorNeutralForeground2,
        },

        '& svg': {
            fontSize: tokens.fontSizeBase300,
            marginBottom: '-2px',
        },
    },
});
