import MedeinaVariables from '@/util/variables';
import useFetch from '../useFetch';
import {useQuery} from '@tanstack/react-query';
import {GetSupportedGeosResponse} from '.';
import {RESOURCE_SCOPES} from '../api.constants';

export default function useGetSupportedGeos() {
    const url = `${MedeinaVariables.FidelisSecCopUri}/provisioning/geos?api-version=2024-03-01-preview`;
    const {customFetch} = useFetch();

    return useQuery<GetSupportedGeosResponse>({
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['supported-geos'],
        staleTime: Infinity,
        retry: false,
        queryFn: async () => {
            var response = await customFetch<Response>(
                url,
                {
                    method: 'GET',
                    scopes: RESOURCE_SCOPES.FIDELIS,
                },
                true,
            );

            if (!response.ok) {
                throw new Error(`Failed to get supported geos: ${response.statusText}`);
            }

            var data = await response.json();
            return data as GetSupportedGeosResponse;
        },
    });
}
