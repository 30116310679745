import { shorthands, mergeClasses, makeStyles } from '@griffel/react';
import { tokens } from '@fluentui/react-theme';
export const menuDividerClassNames = {
    root: 'fui-MenuDivider'
};
const useStyles = makeStyles({
    root: {
        ...shorthands.margin('4px', '-5px', '4px', '-5px'),
        width: 'auto',
        ...shorthands.borderBottom(tokens.strokeWidthThin, 'solid', tokens.colorNeutralStroke2)
    }
});
export const useMenuDividerStyles_unstable = (state)=>{
    const styles = useStyles();
    state.root.className = mergeClasses(menuDividerClassNames.root, styles.root, state.root.className);
    return state;
};
