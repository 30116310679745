import { makeStyles } from '@griffel/react';
import { typographyStyles } from '@fluentui/react-theme';
export const caption2ClassNames = {
    root: 'fui-Caption2'
};
/**
 * Styles for the root slot
 */ export const useCaption2Styles = makeStyles({
    root: typographyStyles.caption2
});
