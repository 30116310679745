import { makeStyles } from '@griffel/react';
import { typographyStyles } from '@fluentui/react-theme';
export const title3ClassNames = {
    root: 'fui-Title3'
};
/**
 * Styles for the root slot
 */ export const useTitle3Styles = makeStyles({
    root: typographyStyles.title3
});
