import Menu from './Menu';
import useClasses from './Navigation.styles';
import {Breadcrumbs} from './Breadcrumbs';
import useNavActions from './useNavActions';
import HeaderMenu from '../HeaderMenu';
import useViewportSize from '../ui/Grid/useViewportSize';
import NavigationBanner from './NavigationBanner';

export default function Navigation() {
    const classes = useClasses();
    const {navActions, toggableActions} = useNavActions();

    const screenSize = useViewportSize();

    const renderHeaderMenu = screenSize.md || screenSize.sm;

    return (
        <>
            <div className={classes.root}>
                <span className={classes.leftNav}>
                    <Menu />
                </span>
                <span className={classes.breadcrumbs}>
                    <Breadcrumbs />
                </span>
                <span className={classes.rightNav}>
                    {renderHeaderMenu && <HeaderMenu />}
                    {!renderHeaderMenu && navActions ? navActions : null}
                </span>
            </div>
            <NavigationBanner />
        </>
    );
}
