import React, {useEffect, useState} from 'react';
import {Dropdown, Label, Option, mergeClasses, useId} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import useClasses from '../Preferences.styles';
import {PluginRbacSettingOptions, PluginRbacSettingScopeLabel} from './Preferences.constants';
import {PluginRbacSetting, PluginRbacSettingScope} from './Preferences.types';
import {TenantInfo, usePatchTenantInfo} from '@/api/tenant';
import {MedeinaInfoLabel} from '@/components/ui/Buttons/MedeinaInfoLabel';
import {getDefaultPluginRbacSetting, PluginRbacDropdownOptions} from './Preferences.utils';

interface PluginRbacManagementSettingProps {
    tenantInfo: TenantInfo | undefined;
    pluginRbacScope: PluginRbacSettingScope;
    isAdmin: boolean;
    overriddenLabelText?: string;
}

export default function PluginRbacManagementSetting({
    tenantInfo,
    pluginRbacScope,
    isAdmin,
    overriddenLabelText,
}: PluginRbacManagementSettingProps) {
    const pluginRbacDropdownId = useId(`pluginRbacDropdown${pluginRbacScope}`);
    const classes = useClasses();
    const {t} = useTranslation();
    const {mutate: patchTenantInfo, isLoading: patchTenantInfoLoading} = usePatchTenantInfo();
    const [pluginMgmtRbac, setPluginMgmtRbac] = useState(PluginRbacSetting.ANY);

    const isUserRbacScope = pluginRbacScope === PluginRbacSettingScope.USER;

    useEffect(() => {
        // Set the plugin management RBAC text based on the tenantInfo response
        const rbacSetting: PluginRbacSetting = getDefaultPluginRbacSetting(
            pluginRbacScope,
            tenantInfo,
        );
        setPluginMgmtRbac(rbacSetting);
    }, [tenantInfo, setPluginMgmtRbac, pluginRbacScope]);

    const handlePluginRbacChange = (rbacSetting: PluginRbacSetting) => {
        // Update the tenantInfo with the new plugin RBAC setting.
        // Include the existing tenantInfo values to populate the other fields
        const allowPluginUpload = rbacSetting === PluginRbacSetting.ANY;
        if (isUserRbacScope) {
            if (allowPluginUpload) {
                patchTenantInfo({
                    ...tenantInfo,
                    allowUserPluginUpload: allowPluginUpload,
                });
            } else {
                // If selecting to disallow user plugin upload, also disallow tenant plugin upload.
                patchTenantInfo({
                    ...tenantInfo,
                    allowUserPluginUpload: false,
                    allowTenantPluginUpload: false,
                });
            }
        } else {
            patchTenantInfo({
                ...tenantInfo,
                allowTenantPluginUpload: allowPluginUpload,
            });
        }
    };

    const rbacDropdownEnabled = () => {
        // Disabled for non-admins and while the patchTenantInfo response is loading
        if (overriddenLabelText || !isAdmin || patchTenantInfoLoading) {
            return false;
        }

        // Enabled when the user is an admin and the tenantInfo is defined.
        if (isUserRbacScope) {
            return tenantInfo?.allowUserPluginUpload !== undefined;
        }
        return tenantInfo?.allowTenantPluginUpload !== undefined;
    };

    const renderDropdownLabel = () => {
        return (
            <Label
                id={pluginRbacDropdownId}
                className={
                    !rbacDropdownEnabled()
                        ? mergeClasses(classes.label, classes.disabledLabel)
                        : classes.label
                }
            >
                {t(PluginRbacSettingScopeLabel[pluginRbacScope])}
            </Label>
        );
    };

    const dropdownTextValue = t(PluginRbacSettingOptions[pluginMgmtRbac]);

    return (
        <div className={classes.rbacDropdown}>
            {overriddenLabelText ? (
                <MedeinaInfoLabel
                    labelContent={renderDropdownLabel()}
                    infoContent={overriddenLabelText}
                />
            ) : (
                renderDropdownLabel()
            )}
            <div>
                <Dropdown
                    className={classes.dropdownSelector}
                    value={dropdownTextValue}
                    selectedOptions={pluginMgmtRbac ? [pluginMgmtRbac] : []}
                    onOptionSelect={(_, data) => {
                        handlePluginRbacChange(data.optionValue as PluginRbacSetting);
                    }}
                    aria-labelledby={pluginRbacDropdownId}
                    disabled={!rbacDropdownEnabled()}
                >
                    {PluginRbacDropdownOptions.map((option) => (
                        <Option key={option.value} value={option.value} text={t(option.text)}>
                            {t(option.text)}
                        </Option>
                    ))}
                </Dropdown>
            </div>
        </div>
    );
}
