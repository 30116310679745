import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    tipContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
        height: '233px',
        ...shorthands.borderRadius(tokens.borderRadiusXLarge),
        boxSizing: 'border-box',
        ...shorthands.border('1px', 'solid', 'transparent'),
        paddingLeft: '100px',
        '&:hover ': {
            ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
            '& .fui-Button': {
                display: 'flex',
            },
        },
    },
    tipType: {
        marginTop: tokens.spacingVerticalXXXL,
        fontFamily: tokens.fontFamilyBase,
        fontWeight: tokens.fontWeightBold,
        fontSize: '10px',
        lineHeight: '13px',
        textAlign: 'center',
        color: tokens.colorPaletteAnchorBorderActive,
    },
    tipTitle: {
        marginTop: tokens.spacingVerticalL,
        left: '19%',
        right: '19%',
        fontFamily: tokens.fontFamilyBase,
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase300,
        lineHeight: '19px',
        textAlign: 'center',
        color: tokens.colorNeutralForeground1,
    },
    tipInformation: {
        marginTop: tokens.spacingVerticalM,
        left: '21%',
        right: '21%',
        width: '420px',
        height: '37px',
        fontFamily: tokens.fontFamilyBase,
        fontHeight: '400',
        fontSize: tokens.fontSizeBase300,
        lineWeight: '19px',
        display: 'flex',
        textAlign: 'center',
        color: tokens.colorNeutralForeground3,
    },
    tipIcon: {
        marginTop: tokens.spacingVerticalXL,
        width: '64px',
        height: '64px',
        textAlign: 'center',
    },
    closeButton: {
        display: 'none',
        alignSelf: 'end',
        position: 'absolute',
        marginRight: tokens.spacingHorizontalM,
        marginTop: tokens.spacingHorizontalM,
    },
});
