import { makeStyles } from '@griffel/react';
import { typographyStyles } from '@fluentui/react-theme';
export const body2ClassNames = {
    root: 'fui-Body2'
};
/**
 * Styles for the root slot
 */ export const useBody2Styles = makeStyles({
    root: typographyStyles.body2
});
