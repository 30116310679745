import {PromptSuggestion} from '@/components/ui/PromptSuggestions/PromptSuggestions.types';
import {ServerApiError} from '../api';
import {JSONValue, PreviewState} from '../api.types';
import {PromptType} from '../prompts';
import {SessionSharingMailRequest} from '../sessions/sessions.types';

export type Evaluation = {
    sessionId: string;
    promptId: string;
    evaluationId: string;
    createdAt: string;
    completedAt: string;
    updatedAt: string;
    state: EvaluationState;
    result: EvaluationResult;
    promptSnapshot: EvaluationPromptSnapshot;
    logFormat: EvaluationLogFormat;
};

export enum EvaluationState {
    Unknown = 'Unknown',
    Created = 'Created',
    Running = 'Running',
    Completed = 'Completed',
    Cancelled = 'Cancelled',
}

export type EvaluationResult = {
    resultType: EvaluationResultType;
    content?: string;
    sources?: EvaluationSource[];
    suggestedPrompts?: PromptSuggestion[];
    outputComponents?: EvaluationOutputComponent[];
    previewState?: PreviewState;
};

export type EvaluationSource = {
    displayName: string;
    sourceContent: string;
    sourceType: EvaluationSourceType;
};

export enum EvaluationOutputComponentType {
    GptOutput = 'GptOutput',
    Text = 'Text',
    Image = 'Image',
}

export type EvaluationOutputComponent = {
    name: string;
    content: string;
    description: string;
    type: EvaluationOutputComponentType;
};

export type EvaluationImageSource = {
    displayName: string;
    imageFileType: string;
    description: string;
    imageBase64: string;
    imageThumbnailBase64: string;
};

export enum EvaluationSourceType {
    Unknown = 'Unknown',
    Uri = 'Uri',
    Text = 'StringType',
}

export type EvaluationPromptSnapshot = {
    preceedingEvaluationIds: string[];
    promptType: PromptType;
    skillName?: string;
    content: string;
    inputs?: JSONValue;
};

export enum EvaluationResultType {
    Unknown = 'Unknown',
    Success = 'Success',
    Error = 'Error',
}

export type EvaluationCreationResponse = {
    evaluation: Evaluation;
    nextEvaluationInsertedOn?: string;
    queueLength: number;
};

export type EvaluationLog = {
    sessionId: string;
    promptId: string;
    evaluationId: string;
    logId: string;
    logLevel: EvaluationLogLevel;
    skillInvocationId?: string;
    skillsetName?: string;
    skillName?: string;
    content?: string;
    stepLogs: EvaluationStepLog[];
    updatedAt: string;
    imageCode: string;
    title: string;
    stepStatus: EvaluationStepStatus;
    createdAt: string;
    previewState?: PreviewState;
};

export type EvaluationStepLog = {
    logId: string;
    contentType: string;
    markdownContent: string;
    structuredContent: EvaluationStepStructuredContent;
    logLevel: EvaluationLogLevel;
    createdAt: string;
};

export enum EvaluationLogFormat {
    StepLogs = 'StepLogs',
    Ungrouped = 'Ungrouped',
}

export enum EvaluationLogLevel {
    Error = 'Error',
    Warning = 'Warning',
    Information = 'Information',
    Verbose = 'Verbose',
}

export enum EvaluationStepStatus {
    Running = 'Running',
    Completed = 'Completed',
    Errored = 'Errored',
    Cancelled = 'Cancelled',
    CompletedWithWarning = 'CompletedWithWarning',
}

export enum EvaluationStepLogContentType {
    Markdown = 'Markdown',
    StructuredContent = 'StructuredContent',
}

export type EvaluationStepStructuredContent = {
    title: string;
    content: string;
};

export type PinnedEvaluation = {
    sessionId: string;
    promptId: string;
    evaluationId: string;
};

export type CreateEvaluationRequest =
    | {
          sessionId: string;
          promptId: string;
      }
    | void
    | undefined;

export type GetEvaluationRequest = {
    sessionId: string;
    promptId: string;
    evaluationId?: string;
};

export type GetEvaluationLogsRequest = GetEvaluationRequest & {
    lastLogId?: string;
    enabled?: boolean;
};

export type GetEvaluationImagesRequest = {
    sessionId: string;
    promptId: string;
    evaluationId?: string;
};

export type GetPinnedEvaluationsRequest = {
    sessionId: string;
};

export type CreatePinnedEvaluationRequest = {
    sessionId: string;
    promptId: string;
    evaluationId: string;
};

export type CreatePinnedEvaluationsRequest = {
    sessionId: string;
    promptIds: string[];
};

export type DeletePinnedEvaluationRequest = {
    sessionId: string;
    evaluationId: string;
};

export class CreateEvaluationRunningTimeoutError extends ServerApiError {
    data?: Evaluation | undefined;

    constructor(evaluation?: Evaluation) {
        super('CreateEvaluationRunningTimeout', null);
        this.data = evaluation;
    }
}
export type CancelEvaluationRequest = {
    sessionId: string;
    promptId: string;
    evaluationId: string;
};

export interface DocumentDownloadResponse {
    fileBlob?: Blob;
    fileName?: string;
    isError: boolean;
}

export interface DocumentDownloadRequest {
    sessionId: string;
    promptId: string;
    evaluationId: string;
    linePositions: number[];
}

export type GetInvestigationExportMailRequest = {
    sessionId: string;
};

export type InvestigationExportMailRequest = SessionSharingMailRequest & {
    evaluationIds: string[];
    promptText: string;
    summaryLink: string;
    evaluationLink: string;
    invitationType: string;
};

export type GetEvaluationsRequest = {
    sessionId: string;
    promptEvaluationIds: [string, string][];
};

export type GetEvaluationsFromIdRequest = GetEvaluationsRequest;

export type EvaluationSkillInvocation = {
    sessionId: string;
    promptId: string;
    evaluationId: string;
    skillsetName: string;
};

export type GetEvaluationSkillInvocationsRequest = {
    sessionId: string;
    promptIds: string[];
    evaluationIds: string[];
    enabled?: boolean;
};

export type GetEvaluationSkillInvocationsProps = {
    sessionId: string;
    promptId: string;
    evaluationId: string;
};
