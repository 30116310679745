import {HourlyCapacityUsage} from '@/api/capacities/capacities.types';
import {transformDataForDataTable} from '../UsageDashboard.utils';
import DataTable from './DataTable';
import {HourlyCapacityUsageStatus} from '../UsageDashboard.types';
import {columns} from '../UsageDashboard.config';

interface DataTableV1Props {
    data: HourlyCapacityUsage[];
}

export default function DataTableV1(props: DataTableV1Props) {
    const tableData = transformDataForDataTable(props.data);

    return <DataTable<HourlyCapacityUsageStatus> data={tableData} columns={columns} />;
}
