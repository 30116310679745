import { makeResetStyles, makeStyles, mergeClasses } from '@griffel/react';
import { TOAST_POSITIONS, getPositionStyles } from '../../state/index';
export const toasterClassNames = {
    root: 'fui-Toaster'
};
/**
 * Styles for the root slot
 */ const useRootBaseClassName = makeResetStyles({
    position: 'fixed',
    width: '292px',
    pointerEvents: 'none'
});
const useToasterStyles = makeStyles({
    inline: {
        position: 'absolute'
    }
});
/**
 * Apply styling to the Toaster slots based on the state
 */ export const useToasterStyles_unstable = (state)=>{
    const rootBaseClassName = useRootBaseClassName();
    const styles = useToasterStyles();
    const className = mergeClasses(toasterClassNames.root, rootBaseClassName, state.inline && styles.inline, state.root.className);
    if (state.bottomStart) {
        var _state_bottomStart;
        state.bottomStart.className = className;
        var _style;
        (_style = (_state_bottomStart = state.bottomStart).style) !== null && _style !== void 0 ? _style : _state_bottomStart.style = {};
        Object.assign(state.bottomStart.style, getPositionStyles(TOAST_POSITIONS.bottomStart, state.dir, state.offset));
    }
    if (state.bottomEnd) {
        var _state_bottomEnd;
        state.bottomEnd.className = className;
        var _style1;
        (_style1 = (_state_bottomEnd = state.bottomEnd).style) !== null && _style1 !== void 0 ? _style1 : _state_bottomEnd.style = {};
        Object.assign(state.bottomEnd.style, getPositionStyles(TOAST_POSITIONS.bottomEnd, state.dir, state.offset));
    }
    if (state.topStart) {
        var _state_topStart;
        state.topStart.className = className;
        var _style2;
        (_style2 = (_state_topStart = state.topStart).style) !== null && _style2 !== void 0 ? _style2 : _state_topStart.style = {};
        Object.assign(state.topStart.style, getPositionStyles(TOAST_POSITIONS.topStart, state.dir, state.offset));
    }
    if (state.topEnd) {
        var _state_topEnd;
        state.topEnd.className = className;
        var _style3;
        (_style3 = (_state_topEnd = state.topEnd).style) !== null && _style3 !== void 0 ? _style3 : _state_topEnd.style = {};
        Object.assign(state.topEnd.style, getPositionStyles(TOAST_POSITIONS.topEnd, state.dir, state.offset));
    }
    if (state.top) {
        var _state_top;
        state.top.className = className;
        var _style4;
        (_style4 = (_state_top = state.top).style) !== null && _style4 !== void 0 ? _style4 : _state_top.style = {};
        Object.assign(state.top.style, getPositionStyles(TOAST_POSITIONS.top, state.dir, state.offset));
    }
    if (state.bottom) {
        var _state_bottom;
        state.bottom.className = className;
        var _style5;
        (_style5 = (_state_bottom = state.bottom).style) !== null && _style5 !== void 0 ? _style5 : _state_bottom.style = {};
        Object.assign(state.bottom.style, getPositionStyles(TOAST_POSITIONS.bottom, state.dir, state.offset));
    }
    return state;
};
