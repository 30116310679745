import React, {useEffect, useState} from 'react';
import {Label, Link, Title1, mergeClasses} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';

import {usePatchTenantInfo} from '@/api/tenant';
import MedeinaFeatures from '@/util/features';
import {PluginRbacSettingScope} from '@/components/Navigation/Preferences/Preferences.types';
import {CustomSwitchTransition} from '@/components/ui/Switch/switch.types';
import PluginRbacManagementSetting from '@/components/Navigation/Preferences/PluginRbacManagement';
import ProgressiveSwitch from '@/components/ui/Switch';
import {MedeinaInfoLabel} from '@/components/ui/Buttons/MedeinaInfoLabel';
import {ErrorIcon} from '@/components/ui/icons';

import useClasses from '@/components/Navigation/Preferences.styles';
import useOrgClasses from './OrganizationData.styles';
import {SettingsProps} from './rbac/OwnerSettings.types';
import MedeinaVariables from '@/util/variables';

// Individual component that handles setting the plugin settings and rendering the error message if the update fails.
function PluginSettings(props: SettingsProps) {
    const {isAdmin, tenantInfo, userInfo} = props;
    const classes = useClasses();
    const orgClasses = useOrgClasses();
    const {t} = useTranslation('common');
    const {t: tAdmin} = useTranslation('admin');
    const isTenantInfoSuccess =
        tenantInfo !== undefined && tenantInfo !== null && tenantInfo.tenantId;
    const [errorTagForO365Consent, setErrorTagForO365Consent] = useState<boolean>(false);
    const {mutateAsync: patchTenantInfo} = usePatchTenantInfo();
    const [updateO365Consent, setUpdateO365Consent] = useState<CustomSwitchTransition>({
        isChanging: false,
        isChangeSuccessful: false,
        hasChangeFailed: false,
    });

    useEffect(() => {
        if (isTenantInfoSuccess) {
            setErrorTagForO365Consent(false);
        }
    }, [tenantInfo]);

    // Update O365 Compliance Flag and only Global Admin is Authorized to view and update.
    const updateCompliancePreferences = async (allowO365DataCollection: boolean) => {
        setUpdateO365Consent({
            isChanging: true,
            isChangeSuccessful: false,
            hasChangeFailed: false,
        });
        patchTenantInfo(
            {
                name: tenantInfo?.name,
                tenantId: tenantInfo?.tenantId,
                complianceRequirements: {
                    allowO365DataCollection: {
                        enabled: allowO365DataCollection,
                    },
                },
            },
            {
                onSuccess: () => {
                    setUpdateO365Consent({
                        isChanging: false,
                        isChangeSuccessful: true,
                        hasChangeFailed: false,
                    });
                    setErrorTagForO365Consent(false);
                },
                onError: () => {
                    setUpdateO365Consent({
                        isChanging: false,
                        isChangeSuccessful: false,
                        hasChangeFailed: true,
                    });
                    setErrorTagForO365Consent(true);
                },
            },
        );
    };

    // The setting for 365 can only be set by a global admin
    const isGlobalAdmin = userInfo?.isGlobalAdmin ?? false;
    const needToDisableO365ConsentToggle = MedeinaFeatures.EnableO365ConsentStepInFRE
        ? !isAdmin
        : !isGlobalAdmin;

    // Display info label explaining why the tenant setting is disabled and overridden by user setting.
    const showUserSettingOverrideInfoLabel = isAdmin && tenantInfo?.allowUserPluginUpload === false;
    return (
        <div>
            <Title1 role="heading" className={classes.subtitle}>
                {t('SettingsPluginsHeading')}
            </Title1>
            <PluginRbacManagementSetting
                tenantInfo={tenantInfo}
                pluginRbacScope={PluginRbacSettingScope.USER}
                isAdmin={isAdmin}
            />
            <PluginRbacManagementSetting
                tenantInfo={tenantInfo}
                pluginRbacScope={PluginRbacSettingScope.TENANT}
                isAdmin={isAdmin}
                overriddenLabelText={
                    showUserSettingOverrideInfoLabel
                        ? t('SettingsPluginsTenantUploadDescription')
                        : undefined
                }
            />
            {!isAdmin && (
                <Label className={classes.warningLabel}>
                    *{t('SettingsPluginsAdminManagedLabel')}
                </Label>
            )}
            {MedeinaFeatures.EnableComplianceBasedPluginManagement && (
                <div>
                    <Title1 role="heading" className={classes.subtitle}>
                        {t('O365ConsentSettingsPluginsHeading')}
                    </Title1>
                    <div className={classes.paragraphContent}>
                        {t('O365ConsentSettingsPluginsInfoLabel')}{' '}
                        <Link
                            data-test-id="data-access-article-link"
                            href={MedeinaVariables.PrivacyAndDataSecurity}
                            target="_blank"
                        >
                            <div className={classes.linkContent}>
                                {t('O365ConsentSettingsPluginsMoreDetails')}{' '}
                            </div>
                        </Link>
                    </div>
                    <ProgressiveSwitch
                        data-testid="O365-compliance-toggle-switch"
                        label={
                            <div>
                                <MedeinaInfoLabel
                                    labelContent={t('O365ComplianceSwitchLabel')}
                                    infoContent={t('O365ComplianceSwitchInfoLabel')}
                                    defaultButtonWrap={true}
                                ></MedeinaInfoLabel>
                            </div>
                        }
                        labelPosition="before"
                        aria-label={t('O365ComplianceAriaLabel')}
                        disabled={needToDisableO365ConsentToggle}
                        checked={
                            tenantInfo?.complianceRequirements?.allowO365DataCollection?.enabled ??
                            false
                        }
                        onClick={(ev) => updateCompliancePreferences(ev)}
                        state={updateO365Consent}
                        className={mergeClasses(
                            classes.switch,
                            needToDisableO365ConsentToggle && classes.disabledLabel,
                        )}
                    ></ProgressiveSwitch>
                    {errorTagForO365Consent && (
                        <div>
                            <div className={orgClasses.errorTag}>
                                <span>
                                    <ErrorIcon filled />
                                </span>
                                <span>{tAdmin('ownerSettings.organizationData.ErrorMessage')}</span>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default PluginSettings;
