import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import MedeinaVariables from '@/util/variables';
import {GetCapacitiesResponse} from '.';
import {
    DEFAULT_API_RETRY_COUNT,
    RESOURCE_SCOPES,
    WORKSPACE_PROVIDER_DEFAULT_STALE_TIME,
} from '../api.constants';
import useFetch from '../useFetch';

export default function useGetCapacities(
    useQueryOptions?: Pick<UseQueryOptions<GetCapacitiesResponse>, 'enabled'>,
) {
    const url = `${MedeinaVariables.FidelisSecCopUri}/account/capacities?api-version=2023-12-01-preview`;
    const {customFetch} = useFetch();

    return useQuery({
        ...useQueryOptions,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: WORKSPACE_PROVIDER_DEFAULT_STALE_TIME,
        queryKey: ['capacities'],
        queryFn: async () => {
            const response = await customFetch<Response>(
                url,
                {
                    method: 'GET',
                    scopes: RESOURCE_SCOPES.FIDELIS,
                },
                true,
            );

            if (!response.ok) {
                const error = {
                    code: response.status,
                    message: response.statusText,
                };

                throw error;
            }

            const data = await response.json();
            return data as GetCapacitiesResponse;
        },
        retry: DEFAULT_API_RETRY_COUNT,
    });
}
