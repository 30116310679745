import { makeStyles } from '@griffel/react';
import { typographyStyles } from '@fluentui/react-theme';
export const subtitle2ClassNames = {
    root: 'fui-Subtitle2'
};
/**
 * Styles for the root slot
 */ export const useSubtitle2Styles = makeStyles({
    root: typographyStyles.subtitle2
});
