import { makeStyles } from '@griffel/react';
import { typographyStyles } from '@fluentui/react-theme';
export const body1StrongClassNames = {
    root: 'fui-Body1Strong'
};
/**
 * Styles for the root slot
 */ export const useBody1StrongStyles = makeStyles({
    root: typographyStyles.body1Strong
});
