import {useMutation} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {OAuthAuthorizationCodeRequest, SettingsScope} from './skills.types';

export default function useGetSkillsetAuthToken() {
    const {customFetch} = useFetch();
    return useMutation({
        mutationFn: async (body: OAuthAuthorizationCodeRequest) => {
            return await customFetch<Response>(
                `/skillsets/${body?.skillsetName}/oauth2/token?scope=${
                    body?.scope ?? SettingsScope.User
                }`,
                {
                    method: 'POST',
                    body,
                },
            );
        },
    });
}
