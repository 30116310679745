import {ExportIcon, CopyIcon, MailIcon} from '@/components/ui/icons';
import {
    Menu,
    MenuTrigger,
    MenuButton,
    MenuPopover,
    MenuList,
    MenuItem,
    Tooltip,
    PopoverProps,
    Popover,
    PopoverTrigger,
    Button,
    PopoverSurface,
} from '@fluentui/react-components';
import downloadWordDocument from '../downloadWordDocument';
import {ExportProps} from './ExportDocument.types';
import {MedeinaTelemetryEvent, useTrackEvent} from '@/api/telemetry';
import {useCallback, useEffect, useState} from 'react';
import ExportInvestigationMailDialog from '@/components/sections/evaluations/ExportInvestigationMailDialog';
import {useSessionCopyLink, useShareSession} from '@/api/sessions';
import LinkCopiedDialog from '@/components/sections/promptbooks/LinkCopiedDialog';

const imagePath = '/images/docx.svg';

export default function ExportDocument({
    evaluation,
    sessionId,
    evaluationIds,
    investigationTitle,
    promptTitle = '',
    isDisabled,
    isSummaryExport,
    children,
    enableCopy = true,
    isUserSessionOwner,
    label,
    className,
}: ExportProps) {
    const {mutate: trackEvent} = useTrackEvent();
    const [shareUrl, setShareUrl] = useState<string>('');
    const {mutate: shareSession} = useShareSession({sessionId});
    const {showLinkCopiedModel} = useSessionCopyLink();

    const handleCopy = () => {
        trackEvent({
            name: isSummaryExport
                ? MedeinaTelemetryEvent.Sessions.ExportSummaryAsCopy
                : MedeinaTelemetryEvent.Evaluations.ExportAsCopy,
        });

        navigator.clipboard
            .writeText(evaluation)
            .then(() => {})
            .catch((error) => {
                console.error('Error copying text:', error);
            });
    };

    const handleWordDownloadClick = () => {
        trackEvent({
            name: isSummaryExport
                ? MedeinaTelemetryEvent.Sessions.ExportSummaryAsWord
                : MedeinaTelemetryEvent.Evaluations.ExportAsWord,
        });

        downloadWordDocument(evaluation, investigationTitle);
    };

    const [dialogOpen, setDialogOpen] = useState(false);
    const handleDialogChange = (isOpen: boolean) => {
        setDialogOpen(isOpen);
    };
    const handleOpenChange: PopoverProps['onOpenChange'] = useCallback(
        (e: any, data: {open: any}) => {
            shareSession(
                {sessionId},
                {
                    onSuccess: ({token}) => {
                        if (token && typeof window !== 'undefined') {
                            setShareUrl(
                                `${window.location.origin}/sessions/${sessionId}?st=${token}`,
                            );
                        }
                    },
                },
            );
        },
        [setShareUrl, shareSession, sessionId],
    );

    const handleCopyToClipboard = useCallback(() => {
        if (shareUrl && typeof window !== 'undefined') {
            window.navigator.clipboard.writeText(shareUrl);
            trackEvent({
                name: MedeinaTelemetryEvent.Sessions.ShareSession,
            });
        }
    }, [shareUrl]);

    const handleCopyLink = () => {
        handleCopyToClipboard();
        setDialogOpen(true);
    };

    return (
        <>
            <Menu>
                <MenuTrigger disableButtonEnhancement>
                    {children ? (
                        (children as React.ReactElement)
                    ) : (
                        <Tooltip content="Export Response" relationship="label">
                            <MenuButton
                                appearance="subtle"
                                shape="circular"
                                icon={<ExportIcon />}
                                menuIcon={null}
                                disabled={isDisabled}
                                className={className}
                            >
                                {label}
                            </MenuButton>
                        </Tooltip>
                    )}
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        <MenuItem
                            icon={<img src={imagePath} alt={'docx'} />}
                            onClick={handleWordDownloadClick}
                        >
                            Word
                        </MenuItem>
                        {!showLinkCopiedModel && (
                            <MenuItem
                                icon={<MailIcon />}
                                onClick={() => setDialogOpen(true)}
                                disabled={!isUserSessionOwner}
                            >
                                Mail
                            </MenuItem>
                        )}
                        {showLinkCopiedModel && (
                            <Popover onOpenChange={handleOpenChange}>
                                <PopoverTrigger disableButtonEnhancement>
                                    <MenuItem
                                        icon={<MailIcon />}
                                        onClick={handleCopyLink}
                                        disabled={!isUserSessionOwner}
                                    >
                                        Mail
                                    </MenuItem>
                                </PopoverTrigger>
                                <PopoverSurface></PopoverSurface>
                            </Popover>
                        )}
                        {enableCopy && (
                            <MenuItem icon={<CopyIcon />} onClick={handleCopy}>
                                Copy
                            </MenuItem>
                        )}
                    </MenuList>
                </MenuPopover>
            </Menu>
            {dialogOpen && !showLinkCopiedModel && (
                <ExportInvestigationMailDialog
                    sessionId={sessionId}
                    evaluationIds={evaluationIds}
                    sessionName={investigationTitle}
                    exportType={isSummaryExport ? 'Investigate' : 'Prompt '}
                    isOpen={dialogOpen}
                    onOpenChange={handleDialogChange}
                />
            )}
            {dialogOpen && showLinkCopiedModel && (
                <LinkCopiedDialog
                    open={dialogOpen && showLinkCopiedModel}
                    onClose={() => setDialogOpen(false)}
                />
            )}
        </>
    );
}
