import { makeStyles, mergeClasses, shorthands } from '@griffel/react';
import { tokens } from '@fluentui/react-theme';
export const accordionPanelClassNames = {
    root: 'fui-AccordionPanel'
};
/**
 * Styles for the root slot
 */ const useStyles = makeStyles({
    root: {
        ...shorthands.margin(0, tokens.spacingHorizontalM)
    }
});
/** Applies style classnames to slots */ export const useAccordionPanelStyles_unstable = (state)=>{
    const styles = useStyles();
    state.root.className = mergeClasses(accordionPanelClassNames.root, styles.root, state.root.className);
    return state;
};
