import {useAppState} from '@/api/app';
import {
    CAPACITY_BUCKETS,
    DATETIME_FORMAT,
    UNIT_CAPACITY_STATUS,
} from '../../UsageDashboard.constants';
import {BarChartHoverCardProps} from './BarChartHoverCard.types';
import {useMemo} from 'react';
import {themes} from '@/components/theme/CustomFluentProvider';
import {FluentProvider} from '@fluentui/react-components';
import useClasses from './BarChartHoverCard.styles';
import {useTranslation} from 'react-i18next';
import {getDayName, getHourlyTimeframe} from '../../UsageDashboard.utils';
import MedeinaFeatures from '@/util/features';
import HoverCardUsage from './HoverCardUsage';
import {format} from 'date-fns';

export default function BarChartHoverCard(props: BarChartHoverCardProps) {
    const classes = useClasses();
    const {t} = useTranslation('admin');

    const {calloutData} = props;

    // calloutData.xAxisPoint is in user timezone
    const usageDate = new Date(calloutData.xAxisPoint);

    // TODO: This needs to do away after Usage V2 is fully implemented
    const usageValue = useMemo(() => {
        return calloutData.chartData.reduce((sum, item) => sum + item.data, 0);
    }, [calloutData.chartData]);

    const matchingStatus = useMemo(() => {
        return Object.entries(UNIT_CAPACITY_STATUS).find(
            ([key]) => key === calloutData.xAxisCalloutData,
        );
    }, [calloutData.xAxisCalloutData]);

    const usageStatus = calloutData.xAxisCalloutData && matchingStatus;

    const usageDayName = getDayName(usageDate);
    const displayDate = format(usageDate, DATETIME_FORMAT.TABLE_COLUMN_DATE);
    const hourlyTimeframe = getHourlyTimeframe(usageDate);

    const getChartDataItem = (key: string) =>
        calloutData.chartData.find((item) => item.legend === key);

    const belowThresholdAmount = getChartDataItem(CAPACITY_BUCKETS.BELOW_THRESHOLD)?.data;
    const aboveThresholdAmount = getChartDataItem(CAPACITY_BUCKETS.ABOVE_THRESHOLD)?.data;

    // FluentV9 tokens aren't compatible with react-charting (V8).
    // This is a workaround to use v9 tokens by injecting FluentProvider within the CustomComponent.
    const {colorScheme} = useAppState();
    const theme = useMemo(() => themes[colorScheme] ?? themes.Default, [colorScheme]);

    return (
        <FluentProvider theme={theme}>
            <div className={classes.root}>
                <div>
                    <div>{displayDate}</div>
                    <div>{usageDayName}</div>
                    <div>{hourlyTimeframe}</div>
                </div>
                {usageStatus && (
                    <div className={classes.usageStatus}>
                        <div className={classes.usageStatusIcon}>{matchingStatus[1].icon}</div>
                        <div className={classes.usageStatusText}>{t(matchingStatus[1].status)}</div>
                    </div>
                )}
                {!MedeinaFeatures.UsageMonitoringV2 && (
                    <HoverCardUsage
                        usageValue={String(usageValue)}
                        usageType={'base'}
                    ></HoverCardUsage>
                )}
                {MedeinaFeatures.UsageMonitoringV2 && (
                    <>
                        {parseInt(String(aboveThresholdAmount ?? 0)) > 0 && (
                            <HoverCardUsage
                                usageValue={String(aboveThresholdAmount)}
                                usageType={'highDemand'}
                            ></HoverCardUsage>
                        )}
                        <HoverCardUsage
                            usageValue={String(belowThresholdAmount)}
                            usageType={'base'}
                        ></HoverCardUsage>
                    </>
                )}
            </div>
        </FluentProvider>
    );
}
