import {Button} from '@fluentui/react-components';
import {ButtonLabels} from '../UsageDashboard.constants';
import {ApiResponseDisplayProps} from './ApiResponseDisplay.types';
import useClasses from './ApiResponseDisplay.styles';
import {useQueryClient} from '@tanstack/react-query';
import {useTranslation} from 'react-i18next';
import {ParagraphContent} from '@/components/ui/Text';

export function ApiResponseDisplay(props: ApiResponseDisplayProps) {
    const queryClient = useQueryClient();
    const classes = useClasses();
    const {title, subtitle, isError, queryKeyForRefresh} = props;
    const {t} = useTranslation('common');

    const refreshPage = () => {
        queryClient.refetchQueries({queryKey: queryKeyForRefresh});
        props.onRefreshButtonClick?.();
    };

    return (
        <div className={classes.root}>
            <span className={classes.headerText}>{title}</span>
            <ParagraphContent>{subtitle}</ParagraphContent>
            {isError && (
                <Button onClick={refreshPage} appearance="primary">
                    {t(ButtonLabels.REFRESH)}
                </Button>
            )}
        </div>
    );
}
