import React, {useEffect, useState} from 'react';
import {Dropdown, Label, Option, useId} from '@fluentui/react-components';
import useClasses from './Preferences.styles';
import {ColorScheme, DebugLevel, useAppState, useGetUserInfo} from '@/api/app';
import MedeinaFeatures from '@/util/features';
import ThemeSwitcher from '../ui/ThemeSwitcher/ThemeSwitcher';
import {useTranslation} from 'react-i18next';
import {responseDebuggerOptions} from './Preferences/Preferences.constants';

import {useFeatureFlags} from '@/api/user';
import {useGetTenantInfo} from '@/api/tenant';
import {AppLanguageField, TimeZoneField} from './Localization';
import {useFeatureFlag} from '@/api/user';
import PluginSettings from '@/components/admin/PluginSettings';
import FileSettings from '@/components/admin/FileSettings';

export default function Preferences() {
    const [
        _,
        hasShowAppLanguage,
        hasShowTimeZone,
        enableLocalization,
        enableTimezone,
        disableLocalization,
        disableTimezone,
        debug,
    ] = useFeatureFlags([
        MedeinaFeatures.ShowAppLanguage,
        MedeinaFeatures.ShowTimeZone,
        MedeinaFeatures.EnableLocalization,
        MedeinaFeatures.EnableTimezone,
        MedeinaFeatures.DisableLocalization,
        MedeinaFeatures.DisableTimezone,
        MedeinaFeatures.Debug,
    ]);
    const debugLevelDropdownId = useId('debugLevelDropdown');
    const classes = useClasses();
    const {t} = useTranslation();
    const {colorScheme, setColorScheme, debugLevel, setDebugLevel} = useAppState();
    const [isAdmin, setIsAdmin] = useState(false);
    const {data: tenantInfo} = useGetTenantInfo();
    const {data: userInfo, isSuccess: adminSuccess} = useGetUserInfo();
    const isVerboseLoggingEnabled = useFeatureFlag(MedeinaFeatures.EnableVerboseLogging);
    const isFidelisGAEnabled = useFeatureFlag(MedeinaFeatures.LaunchExperienceFidelisGA);

    // check if the there is a feature flag override OR the environment's flag is enabled AND the user feature flag has not explicitly disabled it
    const showLanguageField = (hasShowAppLanguage || enableLocalization) && !disableLocalization;
    const showTimeZoneField = (hasShowTimeZone || enableTimezone) && !disableTimezone;

    if (debug) {
        console.log({
            showLanguageField,
            showTimeZoneField,
            subFlags: {
                languageUrlFlag: hasShowAppLanguage,
                timezoneUrlFlag: hasShowTimeZone,
                envLocalizationFlag: enableLocalization,
                envTmezoneFlag: enableTimezone,
                localizationDisableUserFlag: disableLocalization,
                timezoneDisableUserFlag: disableTimezone,
            },
        });
    }

    // for users who had the ColorScheme.OS option enabled, we need to set the color scheme to dark
    useEffect(() => {
        if (colorScheme === ColorScheme.OS) {
            setColorScheme(ColorScheme.Dark);
        }
        // only run this when the component mounts
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // end TODO

    useEffect(() => {
        // Set the admin state if the admin response is successful, otherwise set it to false
        if (adminSuccess && userInfo) {
            setIsAdmin(userInfo.isAdmin);
        } else {
            setIsAdmin(false);
        }
    }, [adminSuccess, userInfo]);

    const debuggerDisplayText = t(
        responseDebuggerOptions.find((option) => option.value === debugLevel)?.label ||
            'SettingsPluginsDebuggingPlaceholder',
    );

    return (
        <div className={classes.root}>
            <div className={classes.field}>
                <h3 className={classes.firstHeader}>{t('SettingsThemeHeading')}</h3>
                <ThemeSwitcher />
            </div>
            {showLanguageField && <AppLanguageField />}

            {showTimeZoneField && <TimeZoneField />}

            {!isFidelisGAEnabled && (
                <>
                    <PluginSettings isAdmin={isAdmin} userInfo={userInfo} tenantInfo={tenantInfo} />
                    <FileSettings tenantInfo={tenantInfo} isAdmin={isAdmin} userInfo={userInfo} />
                </>
            )}

            {isVerboseLoggingEnabled && (
                <div className={classes.field}>
                    <h3>{t('SettingsResponseDebuggingHeading')}</h3>
                    <Label id={debugLevelDropdownId} className={classes.label}>
                        {t('SettingsDebugLevelHeading')}
                    </Label>
                    <div>
                        <Dropdown
                            className={classes.dropdownSelector}
                            value={debuggerDisplayText}
                            selectedOptions={debuggerDisplayText ? [debuggerDisplayText] : []}
                            onOptionSelect={(_, data) => {
                                setDebugLevel(data.optionValue as DebugLevel);
                            }}
                            aria-labelledby={debugLevelDropdownId}
                        >
                            {responseDebuggerOptions.map((option) => (
                                <Option key={option.value} value={option.value}>
                                    {t(option.label)}
                                </Option>
                            ))}
                        </Dropdown>
                    </div>
                </div>
            )}
        </div>
    );
}
