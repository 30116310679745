import React from 'react';
import {Field, Text, Switch, Button, Tooltip} from '@fluentui/react-components';
import useClasses from './ConnectorCategoryHeader.styles';
import {SkillsetsIcon} from '../../../icons';
import {MedeinaInfoLabel} from '../../../Buttons/MedeinaInfoLabel';
import {useTranslation} from 'react-i18next';
import useIsUserPluginManagementAllowed from '@/api/tenant/useIsUserPluginManagementAllowed';

interface ConnectorCategoryHeaderProps {
    category: string;
    categoryDisplayName: string;
    categoryDescription: string;
    categoryChecked: boolean;
    enableSwitch: boolean;
    categoryFilteredSkillsetsCount: number;
    onCategorySelected?: (checked: boolean) => void;
    onOpenExternalView?: () => void;
}

export default function ConnectorCategoryHeader({
    category,
    categoryDisplayName,
    categoryDescription,
    categoryChecked,
    enableSwitch,
    categoryFilteredSkillsetsCount,
    onCategorySelected,
    onOpenExternalView,
}: ConnectorCategoryHeaderProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');
    const {isUserPluginManagementAllowed} = useIsUserPluginManagementAllowed();

    const onCategoryChangeHandler = (ev: any) => {
        onCategorySelected?.(ev.target.checked);
    };

    const renderAddPluginButton = () => {
        return (
            <Button
                icon={<SkillsetsIcon />}
                onClick={onOpenExternalView}
                appearance="subtle"
                size="small"
                aria-label={t('UploadPluginText')}
                disabled={!isUserPluginManagementAllowed()}
                data-testid="category-header-add-plugin-button"
            />
        );
    };

    return (
        <div className={classes.categoryRow}>
            <Field
                className={classes.categoryTextCol}
                label={{
                    children: (
                        <MedeinaInfoLabel
                            labelContent={
                                <Text as="h2" className={classes.categoryNameText}>
                                    {categoryDisplayName}
                                </Text>
                            }
                            infoContent={categoryDescription}
                        />
                    ),
                }}
            />
            {category == 'Plugin' && categoryFilteredSkillsetsCount > 0 && (
                <div className={classes.skillsetAddCol}>
                    <div>
                        {isUserPluginManagementAllowed() ? (
                            <Tooltip
                                withArrow
                                content={t('UploadPluginText')}
                                positioning={'before'}
                                relationship="label"
                            >
                                {renderAddPluginButton()}
                            </Tooltip>
                        ) : (
                            <MedeinaInfoLabel
                                labelContent={renderAddPluginButton()}
                                infoContent={t('UploadPluginDisabledLabel')}
                            />
                        )}
                    </div>
                </div>
            )}
            <div className={classes.switchColumn}>
                <Switch
                    checked={categoryChecked}
                    onChange={onCategoryChangeHandler}
                    name={category}
                    labelPosition="before"
                    className={classes.switch}
                    aria-label={categoryDisplayName}
                    disabled={!enableSwitch}
                />
            </div>
        </div>
    );
}
