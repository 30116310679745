import {useCallback, useEffect, useState} from 'react';
import {MemberListItemProps, MemberType, RoleObjectIdData, RoleType} from '../rbac.types';
import {AddRoleMembersMessages} from './useAddRoleMemberErrorHanding';
import {EVERYONE_IDENTIFIER} from '@/api/rbac/rbac.constants';

interface MemberManagementProps {
    existingMembers: RoleObjectIdData;
}

// Handles different add member scenarios.
export default function useMemberManagement(props: MemberManagementProps) {
    const [members, setMembers] = useState<MemberListItemProps[]>([]);
    const [error, setError] = useState<AddRoleMembersMessages>();
    const roles = Object.values(RoleType);

    useEffect(() => {
        setError(AddRoleMembersMessages.NO_MESSAGE);
    }, []);

    const getMemberListKey = (memberId: string, role: string) => `${memberId}-${role}`;

    // To generate a memberMap which contains existing members if available or create an empty map.
    const generateMemberMap = useCallback(() => {
        const memberMap: Record<string, Set<RoleType>> = {};

        for (const role in props.existingMembers) {
            const existingMemberRoleObject = props.existingMembers[role as RoleType] || {
                [MemberType.User]: [],
                [MemberType.Group]: [],
                [MemberType.Role]: [],
            };

            for (const memberType in existingMemberRoleObject) {
                const objectIds = existingMemberRoleObject?.[memberType as MemberType] || [];

                objectIds.forEach((objectId) => {
                    if (!memberMap[objectId]) {
                        memberMap[objectId] = new Set();
                    }

                    memberMap[objectId].add(role as RoleType);
                });
            }
        }

        return memberMap;
    }, [props.existingMembers]);

    // Handle the event when a new member get added in the list.
    // Will generate a warning message if memeber already have all the roles.
    // It also update the remaining roles list for a member.
    const onMemberAdded = (memberData: MemberListItemProps) => {
        setError(AddRoleMembersMessages.NO_MESSAGE);
        if (!!memberData) {
            const newlyAddedMemberItems = members.filter((m) => m.memberId === memberData.memberId);

            const memberMap = generateMemberMap();
            const existingRoles = memberMap[memberData.memberId] ?? new Set();
            const distinctRoles = [
                ...new Set(newlyAddedMemberItems.map((m) => m.role as RoleType)),
                ...existingRoles,
            ];

            if (!!newlyAddedMemberItems && distinctRoles.length >= roles.length) {
                setError(AddRoleMembersMessages.MEMBER_CANNOT_HAVE_MORE_ROLES);
                return;
            }

            const rolesNotInDistinctRoles = roles.filter(
                (role) => !distinctRoles.includes(role as RoleType),
            );

            // If the member is `Everyone`, they cannot be an owner
            if (memberData.memberId === EVERYONE_IDENTIFIER) {
                const ownerIndex = rolesNotInDistinctRoles.indexOf(RoleType.Owner);
                if (ownerIndex > -1) {
                    rolesNotInDistinctRoles.splice(ownerIndex, 1);
                }
            }

            if (rolesNotInDistinctRoles.length > 0) {
                const updatedData: MemberListItemProps[] = [
                    ...members,
                    {
                        ...memberData,
                        key: getMemberListKey(memberData.memberId, rolesNotInDistinctRoles[0]),
                        role: rolesNotInDistinctRoles[0] as RoleType,
                    },
                ];
                setMembers(updatedData);
            } else {
                setError(AddRoleMembersMessages.MEMBER_CANNOT_HAVE_MORE_ROLES);
                return;
            }
        }
    };

    // It reset the error to none and member list to empty.
    const resetMemberCollection = () => {
        setError(AddRoleMembersMessages.NO_MESSAGE);
        setMembers([]);
    };

    // Handles the event of member getting removed from the list.
    const removeMemberItem = (key: string) => {
        setError(AddRoleMembersMessages.NO_MESSAGE);
        setMembers(members.filter((m) => m.key !== key));
    };

    const updateMemberItem = (key: string, role: RoleType) => {
        setError(AddRoleMembersMessages.NO_MESSAGE);
        const matchingMember = members.find((m) => m.key === key);
        if (!!matchingMember) {
            matchingMember.role = role;
            matchingMember.key = getMemberListKey(matchingMember.memberId, role);
            setMembers([...members]);
        }
    };

    return {
        members,
        onMemberAdded,
        error,
        resetMemberCollection,
        removeMemberItem,
        updateMemberItem,
        generateMemberMap,
    };
}
