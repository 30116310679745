import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        ...shorthands.gap(tokens.spacingVerticalXXXL),
        display: 'flex',
        flexDirection: 'column',
        marginBottom: tokens.spacingVerticalXXXL,
    },
    subtitle: {
        fontWeight: tokens.fontWeightSemibold,
        fontSize: tokens.fontSizeBase500,
    },
    paragraphContent: {
        fontSize: tokens.fontSizeBase200,
        lineHeight: tokens.spacingVerticalL,
        fontWeight: tokens.fontWeightRegular,
        textAlign: 'justify',
        color: tokens.colorNeutralForeground2,
        paddingBottom: tokens.spacingVerticalXXL,
    },
    dataSwitcher: {
        '& .fui-Switch > label:first-of-type': {
            ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalNone),
        },
    },
    errorTag: {
        color: tokens.colorPaletteCranberryBorderActive,
        '& > span:first-child': {
            position: 'relative',
            top: '3px',
            paddingRight: '3px',
        },
    },
});
