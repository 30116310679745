import React, {useEffect, useMemo, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {Button, Tooltip} from '@fluentui/react-components';
import useClasses from '../Breadcrumbs.styles';
import {useOverflowTooltip} from '@/components/ui/Reflow';
import {useTranslation} from 'react-i18next';

function Home() {
    const classes = useClasses();
    const {t} = useTranslation();
    const applicationLabel = t('ApplicationTitle');
    const {ref, isOverflowing, showTooltip} = useOverflowTooltip<HTMLButtonElement>();

    return (
        <Tooltip
            content={applicationLabel}
            relationship="label"
            visible={isOverflowing && showTooltip}
        >
            <Button
                className={classes.link}
                href="/"
                as="a"
                appearance="subtle"
                size="medium"
                ref={ref}
            >
                {applicationLabel}
            </Button>
        </Tooltip>
    );
}

export default Home;
