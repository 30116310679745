import {TourControls, TourStageContainer, useTourContext} from '@/components/ui/Tour';
import {Link, mergeClasses, useFocusFinders} from '@fluentui/react-components';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';
import usePageClasses from './LocationDisplay.styles';
import {EarthIcon} from '@/components/ui/icons';
import {useRef, useEffect, useState} from 'react';
import {overlayAnimation} from '@/components/ui/Tour/Overlay';
import {motion} from 'framer-motion';
import useManageLocationDisplayDetails from './useManageLocationDisplayDetails';
import {useTranslation} from 'react-i18next';
import {TOUR_ERRORS, TOUR_STEPS, TourErrorTypes} from './AdminTour.types';
import useManageTransitionDetails from './useManageTourTransition';
import MedeinaVariables from '@/util/variables';
import {useNavigate} from 'react-router-dom';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {useQueryClient} from '@tanstack/react-query';
import {Body, FooterContent, TextH1} from '@/components/ui/Text';

export default function LocationDisplay() {
    const tour = useTourContext();
    const tourClasses = useTourClasses();
    const pageClasses = usePageClasses();
    const queryClient = useQueryClient();
    const {t} = useTranslation('tours');
    const {t: commonLabel} = useTranslation('common');
    const {validatePostWorkspaceSetup} = useManageTransitionDetails();
    const navigate = useNavigate();
    const {update: providerUpdate} = useWorkspaceState();

    const locationSelectionRef = useRef<HTMLDivElement>(null);
    // New loading state for the continue button
    const [isContinueLoading, setIsContinueLoading] = useState(false);
    const {setTourErrorV2} = useTourContext();

    const {geoValue, isAccountLoading} = useManageLocationDisplayDetails({
        onTenantFetchError: () => {
            setTourErrorV2({
                ...TOUR_ERRORS[TourErrorTypes.DataLoadError],
                actionButtonStep: TOUR_STEPS.Locations,
            });
        },
    });

    const {findFirstFocusable} = useFocusFinders();
    useEffect(() => {
        if (locationSelectionRef?.current) {
            const firstFocusable = findFirstFocusable(locationSelectionRef.current);
            firstFocusable?.focus();
        }
    }, [locationSelectionRef]);

    const handleContinueClick = async (): Promise<void> => {
        try {
            setIsContinueLoading(true);
            const step: TOUR_STEPS = await validatePostWorkspaceSetup();
            if (step === TOUR_STEPS.None) {
                tour.nextStop();
            } else {
                if (step === TOUR_STEPS.HOME) {
                    await providerUpdate();
                    await queryClient.invalidateQueries(['auth', 'getUserInfo']);
                    navigate('/');
                    tour.quitTour();
                } else {
                    tour.setTourStep(step);
                }
            }
        } catch (error) {
            setTourErrorV2({
                ...TOUR_ERRORS[TourErrorTypes.SelectionError],
                actionButtonStep: TOUR_STEPS.Locations,
            });
        } finally {
            setIsContinueLoading(false); // Stop loading regardless of outcome
        }
    };

    return (
        <>
            {!isAccountLoading && (
                <motion.div
                    key="terms"
                    variants={overlayAnimation}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                >
                    <TourStageContainer ref={locationSelectionRef}>
                        <TourStageContainer.StageContent>
                            <>
                                <div className={mergeClasses(pageClasses.icon)}>
                                    <EarthIcon></EarthIcon>
                                </div>
                                <div className={mergeClasses(tourClasses.tourSectionSpacing)}>
                                    <TextH1>
                                        {!!geoValue &&
                                            `${t('adminV2Fre.locationDisplay.Title')} ${geoValue}`}
                                    </TextH1>
                                </div>
                                <div className={tourClasses.tourXLargeSectionSpacing}>
                                    <Body>
                                        {t('adminV2Fre.locationDisplay.SupplementaryContent')}
                                    </Body>
                                </div>
                                <div className={tourClasses.tourSectionSpacing}>
                                    <FooterContent>
                                        <>
                                            {t('adminV2Fre.locationDisplay.DataProtection')}
                                            {` `}
                                            <Link
                                                href={MedeinaVariables.TrustCenterUrl}
                                                target="_blank"
                                                aria-label={t(
                                                    'adminV2Fre.locationDisplay.ariaLabel.DataProtection',
                                                )}
                                            >
                                                {t('adminV2Fre.locationDisplay.ProtectsData')}
                                            </Link>
                                        </>
                                    </FooterContent>
                                </div>
                            </>
                        </TourStageContainer.StageContent>
                        <TourStageContainer.FooterContent>
                            <TourControls
                                spanFullScreen={true}
                                nextButton={commonLabel('Continue')}
                                handleNext={handleContinueClick}
                                isNextButtonDisabled={isContinueLoading}
                                showPrevious={false}
                                showProgressSpinner={isContinueLoading}
                            ></TourControls>
                        </TourStageContainer.FooterContent>
                    </TourStageContainer>
                </motion.div>
            )}
        </>
    );
}
