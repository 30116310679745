import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import MedeinaVariables from '@/util/variables';
import {GetWorkspacesResponse} from '.';
import {
    DEFAULT_API_RETRY_COUNT,
    RESOURCE_SCOPES,
    WORKSPACE_PROVIDER_DEFAULT_STALE_TIME,
} from '../api.constants';
import useFetch from '../useFetch';

export default function useGetWorkspaces(
    useQueryOptions?: Pick<UseQueryOptions<GetWorkspacesResponse>, 'enabled' | 'refetchOnMount'>,
) {
    const url = `${MedeinaVariables.FidelisSecCopUri}/account/workspaces?api-version=2023-12-01-preview`;
    const {customFetch} = useFetch();

    return useQuery<GetWorkspacesResponse>({
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: WORKSPACE_PROVIDER_DEFAULT_STALE_TIME,
        queryKey: ['workspaces'],
        retry: DEFAULT_API_RETRY_COUNT,
        queryFn: async () => {
            const response = await customFetch<Response>(
                url,
                {
                    method: 'GET',
                    scopes: RESOURCE_SCOPES.FIDELIS,
                },
                true,
            );

            if (!response.ok) {
                const error = {
                    code: response.status,
                    message: response.statusText,
                };

                throw error;
            }

            const data = await response.json();
            return data as GetWorkspacesResponse;
        },
        ...useQueryOptions,
    });
}
