import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        width: '100%',
    },
    tab: {
        marginRight: tokens.spacingHorizontalSNudge,
    },
    title: {
        marginBottom: tokens.spacingVerticalM,
    },
    subtitle: {
        paddingTop: '24px',
    },
    hidden: {
        display: 'none',
    },
    skillIcon: {
        marginRight: tokens.spacingHorizontalXS,
    },
    titleColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    checked: {
        backgroundColor: tokens.colorNeutralBackgroundInverted,
        color: tokens.colorNeutralForegroundInverted,
        ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke2),
        minWidth: '0px',
        whiteSpace: 'nowrap',

        ':hover': {
            ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke2),
            backgroundColor: tokens.colorNeutralBackgroundInverted,
            color: tokens.colorNeutralForegroundInverted,
        },
    },
    container: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },

    carouselButton: {
        backgroundColor: tokens.colorTransparentBackground,
        ...shorthands.border('none'),
        ':hover': {
            backgroundColor: tokens.colorTransparentBackground,
        },
    },

    carouselButtonDisabled: {
        ':hover': {
            cursor: 'pointer !important', // This will change the cursor to an arrow.
        },
    },
});
