import {Variants} from 'framer-motion';

export default function useDataTableV2Transitions() {
    const dataGridVariant: Variants = {
        initial: {
            opacity: 0,
        },
        animate: {
            opacity: 1,
            transition: {delay: 1, duration: 0.3},
        },
        exit: {
            opacity: 0,
            transition: {duration: 0.3},
        },
    };

    return {dataGridVariant};
}
