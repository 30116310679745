import {Link, mergeClasses} from '@fluentui/react-components';
import useClasses from './OrganizationData.styles';
import {useGetWorkspaceByName} from '@/api/workspaces';
import {useUpdateWorkspace} from '@/api/workspaces';
import {useEffect, useState} from 'react';
import {ErrorIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import MedeinaVariables from '@/util/variables';
import {useGetUserInfo} from '@/api/app';
import {useGetTenantInfo} from '@/api/tenant';
import {CustomSwitchTransition} from '../ui/Switch/switch.types';
import ProgressiveSwitch from '../ui/Switch';
import PluginSettings from '@/components/admin/PluginSettings';
import FileSettings from '@/components/admin/FileSettings';
import MSManagedBanner from './MSManagedBanner';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

interface OrganizationDataProps {
    workspaceName: string;
}

interface DataAndPrivacyDetails {
    isAllowProductImprovement: boolean;
    isAllowModelImprovement: boolean;
}
// These are MSFT managed tenants that we don't want settings changed in
const managedTenants = MedeinaVariables.MSManagedTenants;

export default function OrganizationData(props: OrganizationDataProps) {
    const classes = useClasses();
    const {
        data: dataOptStatus,
        isFetched: isDataFetched,
        isSuccess: isDataSuccess,
        isError: isDataError,
    } = useGetWorkspaceByName(props?.workspaceName ?? '', {
        enabled: !!props.workspaceName,
    });

    const {data: tenantInfo, isSuccess: isTenantInfoSuccess} = useGetTenantInfo();
    const overrideSettings = useFeatureFlag(MedeinaFeatures.OverrideSettings);
    const disableSettingsUpdate = tenantInfo && managedTenants.includes(tenantInfo?.tenantId ?? '');

    const [isAllowProductImprovement, setIsAllowProductImprovement] = useState<boolean>(false);
    const [isAllowModelImprovement, setIsAllowModelImprovement] = useState<boolean>(false);
    const [errorTagForProductImprovementConsent, setErrorTagForProductImprovementConsent] =
        useState<boolean>(false);
    const [errorTagForModelImprovementConsent, setErrorTagForModelImprovementConsent] =
        useState<boolean>(false);

    const [updateProductImprovementConsent, setProductImprovementConsent] =
        useState<CustomSwitchTransition>({
            isChanging: false,
            isChangeSuccessful: false,
            hasChangeFailed: false,
        });
    const [updateModelImprovementConsent, setModelImprovementConsent] =
        useState<CustomSwitchTransition>({
            isChanging: false,
            isChangeSuccessful: false,
            hasChangeFailed: false,
        });

    const {mutate: updateWorkspace} = useUpdateWorkspace();

    const {t} = useTranslation('admin');

    useEffect(() => {
        if (isDataSuccess) {
            setIsAllowProductImprovement(
                (dataOptStatus?.workspaceOptInConfig?.isAllowProductImprovement ?? '') === 'true',
            );
            setIsAllowModelImprovement(
                (dataOptStatus?.workspaceOptInConfig?.isAllowModelImprovement ?? '') === 'true',
            );

            setErrorTagForModelImprovementConsent(false);
            setErrorTagForProductImprovementConsent(false);
        }
    }, [isDataFetched]);

    useEffect(() => {
        if (isDataError) {
            setErrorTagForProductImprovementConsent(true);
            setErrorTagForModelImprovementConsent(true);
        }
    }, [isDataError]);

    const updatePreferences = (
        preferences: DataAndPrivacyDetails,
        setUpdateConset: React.Dispatch<React.SetStateAction<CustomSwitchTransition>>,
        setErrorTag: React.Dispatch<React.SetStateAction<boolean>>,
    ) => {
        setUpdateConset({
            isChanging: true,
            isChangeSuccessful: false,
            hasChangeFailed: false,
        });
        updateWorkspace(
            {
                name: props.workspaceName ?? '',
                workspaceOptInConfig: {
                    isAllowProductImprovement: preferences.isAllowProductImprovement
                        ? 'true'
                        : 'false',
                    isAllowModelImprovement: preferences.isAllowModelImprovement ? 'true' : 'false',
                },
            },
            {
                onSuccess: (data) => {
                    setUpdateConset({
                        isChanging: false,
                        isChangeSuccessful: true,
                        hasChangeFailed: false,
                    });
                    setIsAllowProductImprovement(
                        (data?.workspaceOptInConfig?.isAllowProductImprovement ?? '') === 'true',
                    );
                    setIsAllowModelImprovement(
                        (data?.workspaceOptInConfig?.isAllowModelImprovement ?? '') === 'true',
                    );
                    setErrorTag(false);
                },
                onError: () => {
                    setUpdateConset({
                        isChanging: false,
                        isChangeSuccessful: false,
                        hasChangeFailed: true,
                    });
                    setErrorTag(true);
                },
            },
        );
    };

    const updateProductImprovement = (isAllowProductImprovement: boolean) => {
        updatePreferences(
            {
                isAllowModelImprovement,
                isAllowProductImprovement: isAllowProductImprovement || false,
            },
            setProductImprovementConsent,
            setErrorTagForModelImprovementConsent,
        );
    };

    const updateModelImprovement = (isAllowModelImprovement: boolean) => {
        updatePreferences(
            {
                isAllowModelImprovement: isAllowModelImprovement || false,
                isAllowProductImprovement,
            },
            setModelImprovementConsent,
            setErrorTagForProductImprovementConsent,
        );
    };
    const {data: userInfo} = useGetUserInfo();
    const isAdmin = userInfo?.isAdmin ?? false;

    return (
        <div className={classes.root}>
            <div>
                <h2 className={classes.subtitle}>
                    {t('ownerSettings.organizationData.SectionHeading')}
                </h2>
                <div className={mergeClasses(classes.paragraphContent)}>
                    {t('ownerSettings.organizationData.InfoContent')}{' '}
                    <Link
                        data-test-id="privacy-and-data-security-link"
                        href={MedeinaVariables.PrivacyAndDataSecurity}
                        target="_blank"
                    >
                        {t('ownerSettings.organizationData.PrivacyAndSecurity')}
                    </Link>
                </div>
                {disableSettingsUpdate && <MSManagedBanner />}

                <div className={classes.dataSwitcher}>
                    <div>
                        <ProgressiveSwitch
                            data-test-id="data-sharing-switch"
                            label={t('ownerSettings.organizationData.ImproveSecuritySwitchLabel')}
                            labelPosition="before"
                            aria-label={t(
                                'ownerSettings.organizationData.ariaLabel.ProductImprovement',
                            )}
                            checked={isAllowProductImprovement}
                            onClick={(ev) => updateProductImprovement(ev)}
                            state={updateProductImprovementConsent}
                            disabled={disableSettingsUpdate && !overrideSettings}
                        ></ProgressiveSwitch>
                        {errorTagForModelImprovementConsent && (
                            <div>
                                <div className={classes.errorTag}>
                                    <span>
                                        <ErrorIcon filled />
                                    </span>
                                    <span>{t('ownerSettings.organizationData.ErrorMessage')}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div>
                        <ProgressiveSwitch
                            data-test-id="ai-model-training-switch"
                            label={t(
                                'ownerSettings.organizationData.TrainSecurityCopilotSwitchLabel',
                            )}
                            labelPosition="before"
                            aria-label={t('ownerSettings.organizationData.ariaLabel.ModelTraining')}
                            checked={isAllowModelImprovement}
                            onClick={(ev) => updateModelImprovement(ev)}
                            state={updateModelImprovementConsent}
                            disabled={disableSettingsUpdate && !overrideSettings}
                        ></ProgressiveSwitch>
                        {errorTagForProductImprovementConsent && (
                            <div>
                                <div className={classes.errorTag}>
                                    <span>
                                        <ErrorIcon filled />
                                    </span>
                                    <span>{t('ownerSettings.organizationData.ErrorMessage')}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <PluginSettings isAdmin={isAdmin} userInfo={userInfo} tenantInfo={tenantInfo} />
            <FileSettings tenantInfo={tenantInfo} isAdmin={isAdmin} userInfo={userInfo} />
        </div>
    );
}
