import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap(tokens.spacingVerticalM),
        height: '100%',
        width: '100%',
    },
    heroCard: {
        '@media (min-width: 1920px)': {
            height: '448px',
        },
        '@media (min-width: 1366px) and (max-width: 1919px)': {
            height: '448px',
        },
        '@media (min-width: 1024px) and (max-width: 1365px)': {
            height: '440px',
        },
        '@media (min-width: 640px) and (max-width: 1023px)': {
            height: '416px',
        },
        '@media (min-width: 480px) and (max-width: 639px)': {
            height: '412px',
        },
        '@media (max-width: 479px)': {
            height: '114px',
        },
    },
    nonHeroCard: {
        '@media (min-width: 1920px)': {
            height: '448px',
        },
        '@media (min-width: 1366px) and (max-width: 1919px)': {
            height: '448px',
        },
        '@media (min-width: 1024px) and (max-width: 1365px)': {
            height: '440px',
        },
        '@media (min-width: 640px) and (max-width: 1023px)': {
            height: '212px',
        },
    },
    recentSessionsCard: {
        display: 'flex',
        ...shorthands.gap(tokens.spacingHorizontalXXL),
        flexDirection: 'column',

        '@media (min-width: 640px) and (max-width: 1023px)': {
            flexDirection: 'row',
        },
        '@media (max-width: 479px)': {
            height: 'auto',
        },
    },
    secondColumn: {
        display: 'flex',
        ...shorthands.flex(1, 1, 'auto'),
        '@media (max-width: 1919px)': {
            ...shorthands.gap(tokens.spacingVerticalL),
        },
    },
    secondColumnCard: {
        '@media (min-width: 640px) and (max-width: 1023px)': {
            width: '50%',
        },
    },
    alignCards: {
        '@media (min-width: 640px) and (max-width: 1023px)': {
            display: 'flex',
            flexDirection: 'row',
        },
    },
    continueLastSession: {
        ...shorthands.flex(0, 0, 'auto'),
        color: tokens.colorNeutralForeground2,
        ...shorthands.padding(0, tokens.spacingHorizontalSNudge),
    },
    viewSessions: {
        ...shorthands.flex(0, 0, 'auto'),
        color: tokens.colorNeutralForeground2,
        ...shorthands.padding(0, tokens.spacingHorizontalSNudge),
        textAlign: 'right',

        '> a': {
            color: tokens.colorNeutralForeground2,
        },

        '& svg': {
            fontSize: tokens.fontSizeBase300,
            marginBottom: '-2px',
        },
    },
    sessionsLink: {
        color: 'inherit',
        height: '100%',
        ':hover': {
            textdecoration: 'none',
            color: 'transparent',
        },
        ':active': {
            textdecoration: 'none',
            color: 'transparent',
        },
    },
});
