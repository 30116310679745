import useClasses from '../../AdminConfigurationModalContent.styles';
import {SettingsScope, SkillsetConfiguration, SkillsetError, SkillsetSetting} from '@/api/skills';
import SelectedConfigsInfoResetHeader from '../Components/SelectedConfigsInfoResetHeader';
import SelectedSkillsetSettingConfiguration from './SelectedSkillsetSettingConfiguration';
import {getConfigDefaultValue} from '../Utils/AdminConfigModal.utils';

interface SelectedSkillsetSettingsFormProps {
    selectedConfigs: Set<SkillsetSetting>;
    isSetUp: boolean;
    scope: SettingsScope;
    existingConfigs: SkillsetConfiguration | null;
    authType: string;
    skillsetConfigurations: SkillsetConfiguration[];
    failedValidationSettings: string[];
    requirementsCheckErrors: SkillsetError[];
    onReset: () => void;
    onInputChange: (ev: any, config: string) => void;
}

export default function SelectedSkillsetSettingsForm({
    selectedConfigs,
    isSetUp,
    scope,
    existingConfigs,
    authType,
    skillsetConfigurations,
    failedValidationSettings,
    requirementsCheckErrors,
    onReset,
    onInputChange,
}: SelectedSkillsetSettingsFormProps) {
    const classes = useClasses();

    // As config can exist without value, we need to check if there is any config with value to mark as existing config
    const hasRequirementsCheckValueError = requirementsCheckErrors.some(
        (error) => error.name === 'Value',
    );
    const configExists = existingConfigs != null;

    const getDefaultInputValue = (config: SkillsetSetting): string => {
        const defaultValue = getConfigDefaultValue(config.name, existingConfigs, authType);

        if (defaultValue) {
            return defaultValue;
        } else if (config.defaultValue) {
            return config.defaultValue;
        }

        return '';
    };

    return (
        <form className={classes.form}>
            <div className={classes.grid}>
                {!isSetUp && (
                    <SelectedConfigsInfoResetHeader
                        scope={scope}
                        existingConfigHasValue={configExists && !hasRequirementsCheckValueError}
                        onResetClick={onReset}
                    />
                )}
                <div className={isSetUp ? '' : classes.sectionBody}>
                    {Array.from(selectedConfigs.values()).map((config, index) => (
                        <SelectedSkillsetSettingConfiguration
                            key={index}
                            autoFocus={index === 0}
                            config={config}
                            defaultInputValue={getDefaultInputValue(config)}
                            skillsetConfigurations={skillsetConfigurations}
                            failedValidationSettings={failedValidationSettings}
                            onInputChange={(ev: any) => onInputChange(ev, config.name)}
                        />
                    ))}
                </div>
            </div>
        </form>
    );
}
