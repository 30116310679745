import {Button, Spinner, mergeClasses} from '@fluentui/react-components';
import useClasses from './RoleAssignment.styles';
import {Add16Filled} from '@fluentui/react-icons';
import RoleGroup from './rbac/RoleGroup';
import {useRef, useState, useEffect} from 'react';
import AddRoleMembers from './rbac/AddRoleMembers/AddRoleMembers';
import {RoleObjectIdData} from './rbac/rbac.types';
import {useWorkspaceState} from '../workspaces/workspaceStateProvider';
import {useGetRoleMembers} from '@/api/rbac';
import {PolicyWorkspace} from '@/api/rbac/rbac.types';
import {useTranslation} from 'react-i18next';
import {ErrorDisplay} from './ErrorDisplay';

export default function RoleAssignment() {
    const [isAddRoleMembersOpen, setIsAddRoleMembersOpen] = useState<boolean>(false);
    const [existingMembers, setExistingMembers] = useState<RoleObjectIdData>();
    const addButtonRef = useRef<HTMLButtonElement>(null);
    const [policyProcessed, setPolicyProcessed] = useState<boolean>(false);
    const [isGraphDataError, setGraphDataError] = useState<boolean>(false);
    const [isGraphDataLoading, setIsGraphDataLoading] = useState<boolean>(false);
    const [isAddRoleMemberError, setAddRoleMemberError] = useState<boolean>(false);
    const pageClasses = useClasses();

    const {workspaceName} = useWorkspaceState();

    const {
        data: dataShareResponse,
        isLoading: dataShareLoading,
        isFetched: dataShareFetched,
        isSuccess: dataShareIsSuccessful,
        isError: dataShareError,
        isError: dataShareIsError,
        isRefetching: dataShareRefetching,
    } = useGetRoleMembers(workspaceName || '', {enabled: !!workspaceName});

    const addRoleMemberClick = () => {
        setIsAddRoleMembersOpen(true);
    };

    const performActionsOnAddRoleMembersClose = () => {
        setIsAddRoleMembersOpen(false);
        addButtonRef.current?.focus();
    };

    const performAddRoleMembersActions = (roleObjectIdData: RoleObjectIdData) => {
        setExistingMembers(roleObjectIdData);
        setPolicyProcessed(true);
    };

    const onGraphDataError = () => {
        setGraphDataError(true);
    };

    const [referenceNameError, setReferenceNameError] = useState<boolean>(false);
    const [policyReferenceName, setPolicyReferenceName] = useState<string>('');

    useEffect(() => {
        if (dataShareIsSuccessful) {
            if (dataShareResponse.properties.entity.referenceName!!) {
                setPolicyReferenceName(dataShareResponse.properties.entity.referenceName);
            } else {
                setReferenceNameError(true);
            }
        }
    }, [dataShareFetched, dataShareIsSuccessful]);

    const {t} = useTranslation('admin');

    const isDataLoading =
        dataShareLoading || dataShareRefetching || isGraphDataLoading || !policyProcessed;
    const isDataError = isGraphDataError || dataShareError || referenceNameError;

    return (
        <div className={pageClasses.root}>
            <div className={pageClasses.titleSection}>
                <h1 className={pageClasses.title}>{t('RoleAssignment.PageHeading')}</h1>
                <div className={mergeClasses(pageClasses.paragraphContent)}>
                    {t('RoleAssignment.InfoContent')}
                </div>
                <div className={mergeClasses(pageClasses.addMemberButton)}>
                    <Button
                        appearance="primary"
                        onClick={addRoleMemberClick}
                        ref={addButtonRef}
                        aria-label={t('RoleAssignment.AriaLabels.AddMember')}
                        disabled={isDataLoading && !isAddRoleMemberError}
                    >
                        <Add16Filled />
                        {t('RoleAssignment.AddMember')}
                    </Button>
                </div>
            </div>
            {isDataLoading && !isDataError && !isAddRoleMemberError && (
                <Spinner
                    size="extra-large"
                    aria-live="polite"
                    label={t('RoleAssignment.LoadingMembersLabel')}
                    labelPosition="below"
                    className={pageClasses.spinner}
                ></Spinner>
            )}

            {isDataError && <ErrorDisplay />}

            <div className={pageClasses.componentContainer}>
                <RoleGroup
                    werePoliciesSuccessfullyFetched={dataShareIsSuccessful}
                    workspacePolicy={dataShareResponse as PolicyWorkspace}
                    onPolicySuccessfullyProcessed={performAddRoleMembersActions}
                    dataRefetching={dataShareRefetching}
                    onGraphDataError={onGraphDataError}
                    onGraphDataLoaded={() => setIsGraphDataLoading(false)}
                    onGraphDataLoading={() => setIsGraphDataLoading(true)}
                    isAddRoleMemberError={isAddRoleMemberError}
                    isDataLoading={isDataLoading}
                    policyReferenceName={policyReferenceName}
                />
            </div>
            <AddRoleMembers
                isAddRoleMembersOpen={isAddRoleMembersOpen}
                onAddRoleMembersClose={performActionsOnAddRoleMembersClose}
                existingMembers={existingMembers as RoleObjectIdData}
                workspacePolicy={dataShareResponse as PolicyWorkspace}
                onAddRoleMembersError={() => setAddRoleMemberError(true)}
                onAddRoleMembersReset={() => setAddRoleMemberError(false)}
            />
        </div>
    );
}
