import {makeStyles, tokens} from '@fluentui/react-components';
export const buttonHover = 'buttonHover';
export const buttonFocus = 'buttonFocus';

export default makeStyles({
    removeButton: {
        '& > span:first-child': {
            position: 'relative',
            top: '3px',
            marginRight: '3px',
            scale: '1.3',
        },
        [`&:hover .${buttonHover}`]: {
            color: tokens.colorStatusDangerForeground3,
        },
        [`&:focus-within .${buttonFocus}`]: {
            color: tokens.colorStatusDangerForeground3,
        },
    },
    remove: {
        display: 'flex',
        alignSelf: 'center',
        marginLeft: 'auto',
        marginRight: '8px',
        opacity: 0.001,
    },
});
