export const responseDebuggerOptions = [
    {value: 'Summary', label: 'SettingsDebuggerSummaryLabel'},
    {value: 'Verbose', label: 'SettingsDebuggerVerboseLabel'},
];

export const PluginRbacSettingOptions = {
    ANY: 'PluginRbacSettingAny',
    ADMIN: 'PluginRbacSettingAdmin',
};

export const PluginRbacSettingScopeLabel = {
    USER: 'SettingsPluginsUserUploadLabel',
    TENANT: 'SettingsPluginsTenantUploadLabel',
};

export const FileRbacSettingOptions = {
    OFF: 'SettingsFileUploadToggleDropdownOff',
    ON: 'SettingsFileUploadToggleDropdownOn',
};
