import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import MedeinaVariables from '@/util/variables';
import {GetRoleMembersResponse} from '.';
import {customFetch} from '../api';
import {RESOURCE_SCOPES} from '../api.constants';

export default function useGetRoleMembers(
    workspaceName: string,
    useQueryOptions?: Pick<UseQueryOptions<GetRoleMembersResponse>, 'enabled'>,
) {
    const url = `${MedeinaVariables.FidelisSecCopUri}/policyStore/workspacePolicies?workspaceName=${workspaceName}&api-version=2024-01-01-preview`;

    return useQuery<GetRoleMembersResponse>({
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['rbac', workspaceName],
        queryFn: async () =>
            await customFetch<GetRoleMembersResponse>(url, {
                method: 'GET',
                scopes: RESOURCE_SCOPES.FIDELIS,
            }),
        ...useQueryOptions,
    });
}
