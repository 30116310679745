import {Button, mergeClasses} from '@fluentui/react-components';
import useClasses from './ExpiredCapacity.styles';
import {ExpiringIcon} from '@/components/ui/icons';
import {useGetUserInfo} from '@/api/app';
import {useTranslation} from 'react-i18next';
import Forbidden from './msal/Forbidden';

interface ExpiredCapacityProps {
    onSwitchOrCreateCapacityDialog?: () => void;
}

export default function ExpiredCapacity(props: ExpiredCapacityProps) {
    const classes = useClasses();
    const {t: expiredCapacityLabel} = useTranslation('admin');
    const {data: authData} = useGetUserInfo();

    const openSwitchOrCreateCapacityDialog = () => {
        props.onSwitchOrCreateCapacityDialog?.();
    };
    return (
        <>
            {authData?.isAdmin && (
                <div className={classes.root}>
                    <div className={mergeClasses(classes.centeredContent, classes.centeredBlock)}>
                        <ExpiringIcon className={classes.warningLogo} />
                        <h1
                            aria-live="assertive"
                            className={classes.field}
                            data-test-id="expired capacity title"
                        >
                            {expiredCapacityLabel('expiredCapacity.Title')}
                        </h1>
                        <h3
                            className={mergeClasses(classes.field, classes.titleContent)}
                            data-test-id="expiry subHeading"
                        >
                            {expiredCapacityLabel('expiredCapacity.Subheading')}
                        </h3>
                        <div>
                            <Button
                                appearance="primary"
                                onClick={() => openSwitchOrCreateCapacityDialog()}
                            >
                                {expiredCapacityLabel('expiredCapacity.GetStarted')}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            {Boolean(authData) && !authData?.isAdmin && (
                <Forbidden message="CapacitiesRemovedNonAdmin"></Forbidden>
            )}
        </>
    );
}
