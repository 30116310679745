import { makeResetStyles, mergeClasses, shorthands } from '@griffel/react';
import { tokens } from '@fluentui/react-theme';
export const drawerFooterClassNames = {
    root: 'fui-DrawerFooter'
};
/**
 * Styles for the root slot
 */ const useStyles = makeResetStyles({
    ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalXXL, tokens.spacingVerticalXXL),
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: tokens.spacingHorizontalS,
    boxSizing: 'border-box'
});
/**
 * Apply styling to the DrawerFooter slots based on the state
 */ export const useDrawerFooterStyles_unstable = (state)=>{
    const styles = useStyles();
    state.root.className = mergeClasses(drawerFooterClassNames.root, styles, state.root.className);
    return state;
};
