import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    dialogSurface: {
        ...shorthands.padding(tokens.spacingHorizontalXXL),

        '@media (max-width: 479px)': {
            ...shorthands.padding(tokens.spacingHorizontalL),
        },
    },
    tabbedDialogBody: {
        display: 'grid',
        gridTemplateColumns: 'auto 3fr',
        maxHeight: `calc(100% - ${tokens.spacingHorizontalXL})`,
        // ...shorthands.margin(tokens.spacingVerticalSNudge),

        '@media (max-width: 479px)': {
            display: 'grid',
            gridTemplateColumns: '1fr',
        },
    },
    dialogBody: {
        display: 'grid',
        gridTemplateColumns: 'auto',
        maxHeight: `calc(100% - ${tokens.spacingHorizontalXL})`,
        // ...shorthands.margin(tokens.spacingVerticalSNudge),

        '@media (max-width: 479px)': {
            display: 'grid',
            gridTemplateColumns: '1fr',
        },
    },
    tabList: {
        overflowY: 'auto', // Enables vertical scrolling
        maxHeight: 'fit-content', // Sets the max height to 100% of its parent
        boxSizing: 'border-box',
        ...shorthands.padding(tokens.spacingHorizontalXXS),
    },
    dialogContent: {
        overflowY: 'auto', // Enables vertical scrolling
        maxHeight: 'fit-content', // Sets the max height to 100% of its parent
        boxSizing: 'border-box',
        // Include small padding to prevent the outline of input elements from being cut off
        ...shorthands.padding(tokens.spacingHorizontalXXS),
    },
});
