import {IExtendedConfiguration} from '@microsoft/1ds-analytics-web-js/dist-esm/src/DataModels';
import MedeinaVariables from '@/util/variables';
import {IExtendedTelemetryItem} from '@microsoft/1ds-core-js';
import redactShareToken from './redactShareToken';

// TODO: Integrate region-specific telemetry endpoints.
// https://eng.ms/docs/products/geneva/collect/clientingestion/onboardingsteps/endpoints/configureuploaddestination
const ENDPOINT_URLS = {
    // Public Cloud: US
    US: 'https://us-mobile.events.data.microsoft.com/OneCollector/1.0/',
    // Public Cloud: European Union
    EU: 'https://eu-mobile.events.data.microsoft.com/OneCollector/1.0/',
    // Global
    Global: undefined,
};

export function initializeTelemetry(item: IExtendedTelemetryItem) {
    if (item.baseType === 'PageActionData' || item.name === 'Ms.Web.PageAction') {
        if (item.data && item.data?.refUri && typeof item.data.refUri === 'string') {
            if (item.data.refUri.includes('?st=')) {
                item.data.refUri = redactShareToken(item.data.refUri);
            }
        }
    }

    if (item.baseType === 'PageviewData' || item.name === 'Ms.Web.PageView') {
        // PageView contains the refUri under data.baseData.refUri
        if (item.baseData) {
            const baseData = item.baseData;
            let refUriKey = 'refUri' as string;
            if (baseData && baseData[refUriKey]) {
                const refUri = baseData[refUriKey];
                if (typeof refUri === 'string') {
                    if (refUri.includes('?st=')) {
                        baseData[refUriKey] = redactShareToken(refUri);
                    }
                }
            }
        }
    }
}

export default {
    instrumentationKey: MedeinaVariables.TelemetryInstrumentationKey,
    extensions: [],
    endpointUrl: ENDPOINT_URLS.Global,
    webAnalyticsConfiguration: {
        autoCapture: {
            pageView: true,
            onLoad: true,
            onUnload: true,
            click: true,
            scroll: false,
            resize: false,
            lineage: false,
            jsErrors: true,
            msTags: true,
        },
    },
} as IExtendedConfiguration;
