import {RoleType, MemberType} from '@/components/admin/rbac/rbac.types';

export const CONDITION_ATTRIBUTES = {
    User: 'principal.microsoft.id',
    Group: 'principal.microsoft.groups',
    Role: 'principal.microsoft.aad.approles',
};

export const ROLE_POLICY_NAME_PREFIX_MAP: Record<RoleType, string> = {
    [RoleType.Contributor]: 'purviewworkspacerole_builtin_workspace-contributor',
    [RoleType.Owner]: 'purviewworkspacerole_builtin_workspace-owner',
};

export const GROUP_POLICY_KEYS: Record<MemberType, string> = {
    [MemberType.User]: 'principal.microsoft.id',
    [MemberType.Group]: 'principal.microsoft.groups',
    [MemberType.Role]: 'principal.microsoft.aad.approles',
};
