import {useMutation} from '@tanstack/react-query';
import useFetch from '@/api/useFetch';
import {CapacityUsageExportResponse} from './capacities.types';
import {UsageDashboardDataExportProps} from '@/components/sections/admin/UsageDashboard/UsageDashboard.types';

const getFileNameFromResponseHeaders = (response: Response): string => {
    return (
        response.headers
            .get('content-disposition')
            ?.split(';')
            ?.filter((x: String) => x.indexOf('filename') > -1)[0]
            ?.split('=')[1]
            ?.replace(/"/g, '') || ''
    );
};

export default function useExportAggregateCapacityUsage() {
    const {customFetch} = useFetch();

    const errorResponse = (): CapacityUsageExportResponse => ({isError: true});

    return useMutation({
        mutationFn: async (
            request: UsageDashboardDataExportProps,
        ): Promise<CapacityUsageExportResponse> => {
            try {
                var response = await customFetch<Response>(
                    `/usage/${request.capacityName}/aggregates/dimensional/export`,
                    {
                        method: 'POST',
                        body: JSON.stringify(request.searchFilters),
                    },
                    true,
                );

                if (!response) {
                    return errorResponse();
                }
                const fileBlob = await response.blob();

                if (!fileBlob || !response.ok) {
                    return errorResponse();
                }

                return {
                    fileName: getFileNameFromResponseHeaders(response),
                    fileBlob: fileBlob,
                    isError: false,
                };
            } catch (error) {
                return errorResponse();
            }
        },
    });
}
