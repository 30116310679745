import {useEffect, useMemo, useState, useRef} from 'react';
import {EvaluationState, useGetEvaluation} from '@/api/evaluations';
import {Markdown} from '@/components/ui';
import useClasses from './Evaluation.styles';
import {EvaluationProps} from './Evaluation.types';

export default function UnknownEvaluationResult({
    sessionId,
    promptId,
    evaluationId,
}: EvaluationProps) {
    const classes = useClasses();
    const {data: evaluation} = useGetEvaluation({sessionId, promptId, evaluationId});

    const isEvaluationContentStreaming =
        !!evaluation?.result?.content && evaluation?.state != EvaluationState.Cancelled;

    const [smoothedContent, setSmoothedContent] = useState<string>('');
    const intervalRef = useRef<NodeJS.Timeout>();

    // Gradually display streamed content so the output looks smoother
    useEffect(() => {
        if (isEvaluationContentStreaming) {
            clearInterval(intervalRef.current);
            var remainingContent = evaluation?.result?.content?.slice(smoothedContent.length) || '';
            intervalRef.current = setInterval(() => {
                setSmoothedContent((prevContent) => {
                    let updatedContent = prevContent;
                    if (remainingContent.length > 0) {
                        updatedContent += remainingContent[0] || '';
                        remainingContent = remainingContent.slice(1);
                    }
                    return updatedContent;
                });
            }, 1000 / (remainingContent.length || 1));
        }
        return () => {
            clearInterval(intervalRef.current);
        };
    }, [isEvaluationContentStreaming, evaluation?.result?.content, smoothedContent]);

    const displayContent = useMemo(() => {
        if (isEvaluationContentStreaming) {
            return smoothedContent;
        } else {
            return evaluation?.result?.content || '';
        }
    }, [isEvaluationContentStreaming, smoothedContent, evaluation?.result?.content]);

    return (
        <div className={classes.evaluation}>
            <Markdown>{displayContent ?? ''}</Markdown>
        </div>
    );
}
