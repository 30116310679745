import * as React from 'react';
import {Button, Text} from '@fluentui/react-components';
import {SkillsetsIcon} from '@/components/ui/icons';
import useClasses from '../../ConnectorModalContent.styles';
import {useTranslation} from 'react-i18next';
import {useIsUserPluginManagementAllowed} from '@/api/tenant';
import {MedeinaInfoLabel} from '@/components/ui/Buttons/MedeinaInfoLabel';

interface AddPluginComponentProps {
    category: string;
    onAddPluginClick: () => void;
    buttonRef: React.RefObject<HTMLButtonElement>;
}

export default function AddPluginComponent({
    category,
    onAddPluginClick,
    buttonRef,
}: AddPluginComponentProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');
    const {isUserPluginManagementAllowed} = useIsUserPluginManagementAllowed();

    const renderAddPluginButton = () => {
        return (
            <Button
                className={classes.connectServicesButton}
                icon={<SkillsetsIcon className={classes.connectionIcon} />}
                onClick={onAddPluginClick}
                appearance="subtle"
                size="medium"
                ref={buttonRef}
                disabled={!isUserPluginManagementAllowed()}
                data-testid="add-first-plugin-button"
            >
                {t('ContentModal.AddPlugin')}
            </Button>
        );
    };

    return (
        <div key={category} className={classes.emptyCategory}>
            <Text className={classes.emptySearchText}>Add a plugin</Text>
            <Text className={classes.emptySearchText2}>
                {t('ContentModal.AddPluginDescription')}
            </Text>
            <div className={classes.connectServices}>
                {isUserPluginManagementAllowed() ? (
                    renderAddPluginButton()
                ) : (
                    <MedeinaInfoLabel
                        labelContent={renderAddPluginButton()}
                        infoContent={t('ContentModal.AddPluginDisabledLabel')}
                    />
                )}
            </div>
        </div>
    );
}
