import {useQuery} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {SettingsScope, SkillsetRequest} from './skills.types';

export default function useGetSkillsetOAuthAuthorizationEndpoint({
    skillsetName,
    scope,
}: SkillsetRequest) {
    const {customFetch} = useFetch();
    const queryParams = `?scope=${scope ?? SettingsScope.User}`;
    return useQuery({
        // Cache for entire session, as this rarely changes.
        queryKey: ['skillset', skillsetName],
        queryFn: async () => {
            const response = await customFetch<Response>(
                `/skillsets/${skillsetName}/oauth2/authorize${queryParams}`,
                {
                    method: 'GET',
                },
            );
            return response;
        },
        enabled: false,
        refetchOnMount: false,
    });
}
