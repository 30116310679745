import {SortingDirection} from '@/api/api';
import {GetExportedUsageRequestParameters, SearchFacet} from '@/api/capacities/capacities.types';

export type HourlyCapacityUsageStatus = {
    startTime: Date;
    usageValue: number;
    usageStatus: CapacityUsageStatus;
};

export type DimensionalHourlyAggregate = {
    aggregateStartTime: string;
    usedCapacity: number;
    userId: string;
    userName: string;
    sessionId: string;
    invocationType: string;
    invocationCategory: string;
    source: string;
    skillSetNames: string[];
};

export interface UsageDashboardDataExportProps {
    capacityName: String;
    searchFilters: GetExportedUsageRequestParameters;
}

export type CapacityUsageStatus = {
    icon: JSX.Element;
    status: string;
};

export type DateRange = {
    startDate: Date;
    endDate: Date;
};

export type UsageDashboardState = {
    startDate: Date;
    endDate: Date;
    dateText: string;
    pageNumber: number;
    pageSize: number;
    searchFilters: SearchFacet[];
    needsFacetRefresh: boolean;
    needsHourlyDataRefresh: boolean;
    needsDimensionalDataRefresh: boolean;
    sortDirection: SortingDirection;
};

export type UserItem = {
    id: string;
    displayName: string;
};

export type SearchFilterValues = string[] | UserItem[];

export type UsageSearchFilter = {
    category: SearchCategory;
    values: SearchFilterValues;
};

export enum SearchCategory {
    CopilotExperience = 'Copilot experience',
    Users = 'Users',
    InvocationCategory = 'Invocation category',
    PluginUsed = 'Plugin used',
    InvocationType = 'Invocation type',
}
