import useClasses from './UsageDashboard.styles';
import {Button, Link, mergeClasses, Spinner} from '@fluentui/react-components';
import {DateTimeFormat} from '@/components/date';
import VStackedBarChart from './BarChart/VStackedBarChart';
import {
    ButtonLabels,
    ApiResponseDisplays,
    LAST_UPDATED,
    LEARN_MORE_LINK,
    UNITS_PER_HOUR,
    USAGE_MONITORING_DESCRIPTION,
    USAGE_MONITORING_TITLE,
    UNIT_SINGLE_TEXT,
    UNIT_PLURAL_TEXT,
    PAGE_LOADING,
    USAGE_DATA_LOADING,
    CUSTOM_CAPACITY_USAGE_SEARCH_DATE_RANGE,
} from './UsageDashboard.constants';
import DateFilterDropdown from './DateFilterDropdown/DateFilterDropdown';
import {useCallback, useMemo, useRef, useState} from 'react';
import {DateRange} from './UsageDashboard.types';
import {useGetUserInfo} from '@/api/app';
import {useGetAggregatedCapacityUsage, useGetCapacityByName} from '@/api/capacities';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {ApiResponseDisplay} from './ApiResponseDisplay/ApiResponseDisplay';
import {HourlyCapacityUsage} from '@/api/capacities/capacities.types';
import {subDays} from 'date-fns';
import {useTranslation} from 'react-i18next';
import MedeinaVariables from '@/util/variables';
import ChangeCapacity from '../ChangeCapacityDialog/ChangeCapacity';
import DataTableV1 from './DataTable/DataTableV1';
import {
    DateFilterChangedProps,
    DateFilterDropdownValues,
} from './DateFilterDropdown/DateFilterDropdown.types';

//TODO: Handle scenario when no capacity is linked to workspace
export default function UsageDashboard() {
    const classes = useClasses();
    const {t: tCommon} = useTranslation('common');
    const {t: tAdmin} = useTranslation('admin');
    const {data: user} = useGetUserInfo();

    // To get the capacity name from workspace and
    const {capacityName, isProviderLoaded, isWorkspaceCapacityEAP} = useWorkspaceState();
    // Start and end time for fetching usage data
    const [dateRange, setDateRange] = useState<DateRange>({
        startDate: subDays(new Date(), 1),
        endDate: new Date(),
    });
    const [dateSelectedOption, setDateSelectedOption] = useState(
        DateFilterDropdownValues.Last24Hours,
    );

    // Used to open and close the change capacity dialog
    const changeCapacityButtonRef = useRef<HTMLButtonElement>(null);
    const [isChangeCapacityDialogOpen, setChangeCapacityDialogOpen] = useState<boolean>(false);
    const performActionsOnCapacityDialogClosure = () => {
        setChangeCapacityDialogOpen(false);
        changeCapacityButtonRef.current?.focus();
    };

    const {
        data: capacity,
        isFetching: isGetCapacityNameFetching,
        isError: isGetCapacityNameError,
        isFetched: isGetCapacityNameFetched,
    } = useGetCapacityByName(capacityName || '', {
        enabled: !!capacityName,
    });
    const capacityUnits = useMemo(() => {
        return capacity?.properties?.numberOfUnits ?? 0;
    }, [capacity]);

    // API to fetch usage events for hours specified in the date range
    // We need to send date values in UTC, toISOString() converts it to UTC.
    const {
        data: hourlyCapacityUsageEventsData,
        isSuccess: isGetCapacityUsageEventsSuccess,
        isFetching: isGetCapacityUsageEventsFetching,
        isFetched: isGetCapacityUsageEventsFetched,
        isError: isGetCapacityUsageEventsError,
        dataUpdatedAt: hourlyCapacityUsageEventsDataUpdatedAt,
    } = useGetAggregatedCapacityUsage(
        {
            capacityResourceName: capacityName || '',
            startTime: dateRange.startDate.toISOString(),
            endTime: dateRange.endDate.toISOString(),
        },
        {enabled: !!capacityName},
    );

    // Used to display the last updated at
    const lastUpdatedAt = hourlyCapacityUsageEventsDataUpdatedAt || new Date();
    // Flags to show different states for loading, error and valid data presence
    const isHourlyCapacityUsageLoading =
        isGetCapacityNameFetching || isGetCapacityUsageEventsFetching;
    const isHourlyCapacityUsageError =
        !capacityName || isGetCapacityNameError || isGetCapacityUsageEventsError;
    const isHourlyCapacityUsageDataPresent =
        isGetCapacityUsageEventsSuccess && hourlyCapacityUsageEventsData.value.length > 0;

    // This will remove a flicker of loading state when the data is loaded for the first time
    const shouldShowLoadingState = !isProviderLoaded || isHourlyCapacityUsageLoading;

    const showChangeUnitsComponents = isProviderLoaded && !isWorkspaceCapacityEAP;

    // Check if the page is loaded completely. This takes into account all the dependencies
    // and ensures that the page is loaded only when all the data is fetched.
    const isDashboardLoaded = useMemo(() => {
        if (
            !isDashboardLoaded &&
            isProviderLoaded &&
            isGetCapacityNameFetched &&
            isGetCapacityUsageEventsFetched
        ) {
            return true;
        } else return false;
    }, [isGetCapacityNameFetched, isGetCapacityUsageEventsFetched]);

    const getCapacityUnitsText = () => {
        if (!capacityUnits) {
            return '';
        }
        const unitText = capacityUnits === 1 ? tAdmin(UNIT_SINGLE_TEXT) : tAdmin(UNIT_PLURAL_TEXT);
        return `${capacityUnits} ${unitText}`;
    };

    const getApiStatusClasses = useCallback(() => {
        if (
            shouldShowLoadingState ||
            isHourlyCapacityUsageError ||
            (isGetCapacityUsageEventsSuccess && hourlyCapacityUsageEventsData?.value.length === 0)
        ) {
            return mergeClasses(classes.apiStatusMessage, classes.apiStatusWithContent);
        } else {
            return mergeClasses(classes.apiStatusMessage, classes.apiStatusEmpty);
        }
    }, [
        shouldShowLoadingState,
        isHourlyCapacityUsageError,
        isGetCapacityUsageEventsSuccess,
        hourlyCapacityUsageEventsData,
        classes.apiStatusMessage,
        classes.apiStatusWithContent,
        classes.apiStatusEmpty,
    ]);

    const dateRangeOptionChanged = (props: DateFilterChangedProps) => {
        setDateSelectedOption(props.title);
    };

    return (
        <>
            {user?.isAdmin && (
                <div className={classes.root}>
                    <div className={classes.titleContainer}>
                        <h1 className={mergeClasses(classes.titleText, classes.titleTextContainer)}>
                            {tAdmin(USAGE_MONITORING_TITLE)}
                        </h1>
                        <div>
                            {tAdmin(LAST_UPDATED)} {<DateTimeFormat date={lastUpdatedAt} />}
                        </div>
                    </div>
                    <div className={classes.infoContainer}>
                        {tAdmin(USAGE_MONITORING_DESCRIPTION)}{' '}
                        <Link href={MedeinaVariables.ManageCapacityLearnUri} target="_blank">
                            {tAdmin(LEARN_MORE_LINK)}
                        </Link>
                    </div>
                    {!isDashboardLoaded && (
                        <div className={classes.loadingContainer}>
                            <Spinner
                                labelPosition="below"
                                aria-live="polite"
                                aria-label={tAdmin(PAGE_LOADING)}
                                size="medium"
                                label={`${tAdmin(PAGE_LOADING)}...`}
                            />
                        </div>
                    )}
                    {isDashboardLoaded && (
                        <>
                            {showChangeUnitsComponents && (
                                <>
                                    <div className={classes.capacityHeader}>
                                        {tAdmin(UNITS_PER_HOUR)}
                                    </div>
                                    <div
                                        className={mergeClasses(
                                            classes.capacityValue,
                                            classes.capacityValueContainer,
                                        )}
                                    >
                                        {getCapacityUnitsText()}
                                    </div>
                                </>
                            )}
                            <div className={classes.actionBtns}>
                                {showChangeUnitsComponents ? (
                                    <Button
                                        appearance="primary"
                                        onClick={() => setChangeCapacityDialogOpen(true)}
                                        ref={changeCapacityButtonRef}
                                        disabled={isGetCapacityNameFetching}
                                    >
                                        {tCommon(ButtonLabels.CHANGE_CAPACITY)}
                                    </Button>
                                ) : (
                                    <div />
                                )}
                                {/* The 'isVisible' prop is used to conditionally hide the component without resetting the state to default of Last24Hours*/}
                                <DateFilterDropdown
                                    dateRange={dateRange}
                                    setDateRange={setDateRange}
                                    isVisible={isGetCapacityUsageEventsSuccess}
                                    onOptionChanged={dateRangeOptionChanged}
                                    selectedOptionText={dateSelectedOption}
                                    minSearchStartDate={
                                        CUSTOM_CAPACITY_USAGE_SEARCH_DATE_RANGE.LAST_30_DAYS
                                    }
                                />
                            </div>
                            <div className={getApiStatusClasses()}>
                                {shouldShowLoadingState ? (
                                    <Spinner
                                        labelPosition="below"
                                        aria-live="polite"
                                        aria-label={tAdmin(USAGE_DATA_LOADING)}
                                        size="medium"
                                        label={`${tAdmin(USAGE_DATA_LOADING)}`}
                                    />
                                ) : isHourlyCapacityUsageError ? (
                                    <ApiResponseDisplay
                                        title={tAdmin(ApiResponseDisplays.ERROR.title)}
                                        subtitle={tAdmin(ApiResponseDisplays.ERROR.subtitle)}
                                        queryKeyForRefresh={[
                                            'capacities',
                                            capacityName,
                                            dateRange.startDate.toISOString(),
                                            dateRange.endDate.toISOString(),
                                        ]}
                                        isError
                                    />
                                ) : (
                                    isGetCapacityUsageEventsSuccess &&
                                    hourlyCapacityUsageEventsData.value.length === 0 && (
                                        <ApiResponseDisplay
                                            title={tAdmin(ApiResponseDisplays.NO_DATA.title)}
                                            subtitle={tAdmin(ApiResponseDisplays.NO_DATA.subtitle)}
                                            isError={false}
                                        />
                                    )
                                )}
                            </div>
                            {isHourlyCapacityUsageDataPresent && (
                                <>
                                    <VStackedBarChart
                                        data={
                                            hourlyCapacityUsageEventsData?.value as HourlyCapacityUsage[]
                                        }
                                    />
                                    <DataTableV1
                                        data={
                                            hourlyCapacityUsageEventsData?.value as HourlyCapacityUsage[]
                                        }
                                    />
                                </>
                            )}
                            {showChangeUnitsComponents && (
                                <ChangeCapacity
                                    isChangeCapacityDialogOpen={isChangeCapacityDialogOpen}
                                    onChangeCapacityDialogClose={
                                        performActionsOnCapacityDialogClosure
                                    }
                                />
                            )}
                        </>
                    )}
                </div>
            )}
        </>
    );
}
