import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

const skillRowPadding = {
    paddingLeft: tokens.spacingHorizontalL,
    paddingRight: tokens.spacingHorizontalL,
    paddingTop: tokens.spacingVerticalM,
    paddingBottom: tokens.spacingVerticalM,
};

const skillRowMargins = {
    marginLeft: tokens.spacingHorizontalM,
    marginRight: tokens.spacingHorizontalM,

    '@media (max-width: 479px)': {
        marginLeft: '0',
        marginRight: '0',
    },
};

export default makeStyles({
    deleteButton: {
        ...shorthands.margin('auto', tokens.spacingVerticalM),
    },
    stickyTop: {
        position: 'sticky',
    },
    sourceTabsContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    sourceTabContent: {
        display: 'grid',
        gridTemplateColumns: '1fr 4fr auto',
        overflowY: 'hidden', // Ensure tab content doesn't overflow beyond bottom of dialog
    },
    popoverTop: {
        marginLeft: tokens.spacingHorizontalM,
        marginTop: tokens.spacingVerticalNone,
        marginBottom: tokens.spacingVerticalS,
    },
    popoverTopText: {
        fontSize: '16px',
        fontWeight: tokens.fontWeightSemibold,
        lineHeight: '18px',
    },
    tabs: {
        marginBottom: tokens.spacingVerticalXS,
    },
    sourceContent: {
        overflowY: 'auto',
        overflowX: 'hidden', // Hide horizontal scrollbar
    },
    subText: {
        color: tokens.colorNeutralForeground3,
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase500,
    },
    subTextWrapper: {
        marginLeft: tokens.spacingHorizontalM,
    },
    btnSearchStart: {
        ...shorthands.borderRadius('50%'),
        ...shorthands.borderColor(tokens.colorTransparentStroke),
        backgroundColor: tokens.colorTransparentBackground,
    },
    btnSearchHidden: {
        display: 'none',
    },
    searchbar: {
        width: '272px',
        ...shorthands.padding(tokens.spacingVerticalNone, tokens.spacingHorizontalXS),
    },
    btnCancel: {
        ...shorthands.borderRadius('50%'),
        ...shorthands.borderColor(tokens.colorTransparentStroke),
        backgroundColor: tokens.colorTransparentBackground,
        ':hover': {
            ...shorthands.borderColor(tokens.colorTransparentStroke),
            backgroundColor: tokens.colorTransparentBackground, // Remove the hover background color
            color: 'inherit', // Inherit the text color on hover
        },
    },
    emptySearch: {
        ...shorthands.margin(0, 'auto'),
        marginTop: '20%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '70%',
    },
    emptySearchText: {
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightSemibold,
        marginBottom: tokens.spacingVerticalL,
        textAlign: 'center',
    },
    emptySearchText2: {
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightRegular,
        color: tokens.colorNeutralForeground4,
        textAlign: 'center',
        textOverflow: 'wrap',
    },
    emptyCategory: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.borderBottom('1px', 'solid', tokens.colorNeutralBackground1),
        ...shorthands.borderRadius(0, 0, tokens.borderRadiusXLarge, tokens.borderRadiusXLarge),
        marginLeft: tokens.spacingHorizontalM,
        marginRight: tokens.spacingHorizontalM,
        backgroundColor: tokens.colorNeutralBackground5,
        paddingTop: tokens.spacingVerticalXXL,
        paddingLeft: '15%',
        paddingRight: '15%',
        paddingBottom: tokens.spacingVerticalXXL,
    },
    connectServices: {
        alignSelf: 'center',
        marginTop: tokens.spacingVerticalXXL,
        marginBottom: tokens.spacingVerticalS,
    },
    connectServicesButton: {
        minWidth: '121px',
        maxWidth: '121px',
        ...shorthands.padding('6px'),
        ...shorthands.border('1px', 'solid', tokens.colorNeutralBackground6),
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
    },
    connectionIcon: {
        color: tokens.colorNeutralForeground1,
    },
    category: {
        marginBottom: tokens.spacingVerticalXXL,
    },
    skillRow: {
        ...shorthands.borderBottom('1px', 'solid', tokens.colorNeutralBackground1),
        display: 'grid',
        backgroundColor: tokens.colorNeutralBackground5,
        gridTemplateColumns: '1fr auto auto',
        ...skillRowPadding,
        ...skillRowMargins,

        '@media (max-width: 479px)': {
            gridTemplateColumns: '1fr auto auto',
            gridTemplateRows: 'auto auto',
            marginLeft: '0',
            marginRight: '0',
        },
    },
    skillRowLast: {
        ...shorthands.borderRadius(0, 0, tokens.borderRadiusXLarge, tokens.borderRadiusXLarge),
        marginBottom: tokens.spacingVerticalM,
    },
    fileOptions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gridColumnSpan: '1',

        '@media (max-width: 479px)': {
            marginRight: '0',
            marginTop: tokens.spacingHorizontalMNudge,
            gridRowStart: '2',
            gridColumnStart: '3',
            gridColumnEnd: '3',
        },
    },
    complianceMessage: {
        display: 'flex',
        alignItems: 'center',
    },
    menuCol: {
        marginTop: tokens.spacingVerticalMNudge,
        marginLeft: tokens.spacingHorizontalL,
    },
    secondaryAction: {
        gridColumnSpan: '1',

        '@media (max-width: 479px)': {
            gridRowStart: '2',
            gridColumnStart: '2',
            gridColumnEnd: '2',
        },
    },
    skillRowContent: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    linkSvgButton: {
        color: tokens.colorNeutralForeground2,
        '& svg': {
            fontSize: tokens.fontSizeBase300,
            marginBottom: '-2px',
            marginInlineStart: tokens.spacingHorizontalXS,
        },
    },
    switchCol: {
        gridColumnSpan: '1',
        marginTop: tokens.spacingVerticalXS,
        marginRight: tokens.spacingHorizontalM,

        '@media (max-width: 479px)': {
            marginRight: '0',

            gridRowStart: '2',
            gridColumnStart: '3',
            gridColumnEnd: '3',
        },
    },
    switch: {
        fontWeight: 'bold',
        display: 'flex',
        marginLeft: tokens.spacingHorizontalMNudge,

        '@media (max-width: 479px)': {
            marginLeft: '0',
        },
    },
    popoverSearch: {
        display: 'flex',
        marginTop: tokens.spacingVerticalXL,
        marginLeft: tokens.spacingHorizontalM,
        marginBottom: tokens.spacingVerticalXL,
    },
    connectButton: {
        minWidth: '65px',
        ...shorthands.padding('6px'),
        ...shorthands.border('1px', 'solid', tokens.colorNeutralBackground6),
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        marginTop: tokens.spacingVerticalXXS,
        marginLeft: tokens.spacingHorizontalXXS,
    },
    clickIntoButton: {
        marginLeft: tokens.spacingHorizontalL,
    },
    hrule: {
        ...shorthands.borderRadius('50%'),
        ...shorthands.borderColor(tokens.colorTransparentStroke),
        backgroundColor: tokens.colorTransparentBackground,
        pointerEvents: 'none',
        ...shorthands.padding(tokens.borderRadiusNone),
        paddingLeft: tokens.spacingHorizontalS,
        paddingRight: tokens.spacingHorizontalS,
    },
    // Via https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/alert_role
    // and https://www.a11yproject.com/posts/how-to-hide-content/
    visuallyHidden: {
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        overflowX: 'hidden',
        overflowY: 'hidden',
        height: '1px',
        position: 'absolute',
        whiteSpace: 'nowrap',
        width: '1px',
    },
    tabContent: {
        height: '600px',
    },
});
