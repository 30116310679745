import { makeStyles, mergeClasses, shorthands } from '@griffel/react';
import { useDividerStyles_unstable } from '@fluentui/react-divider';
const useBaseStyles = makeStyles({
    // Base styles
    root: {
        display: 'inline-flex',
        maxWidth: '1px',
        ...shorthands.padding('0', '12px')
    },
    vertical: {
        maxWidth: 'initial'
    }
});
/**
 * Apply styling to the ToolbarDivider slots based on the state
 */ export const useToolbarDividerStyles_unstable = (state)=>{
    useDividerStyles_unstable(state);
    const { vertical } = state;
    const toolbarDividerStyles = useBaseStyles();
    state.root.className = mergeClasses(toolbarDividerStyles.root, !vertical && toolbarDividerStyles.vertical, state.root.className);
    return state;
};
