import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useCreatePrompt} from '../prompts';
import {useCreateEvaluation} from '../evaluations';
import {CreateSessionWithPromptRequest, SourceType} from './sessions.types';
import useCreateSession from './useCreateSession';

export default function useCreateSessionWithPrompt() {
    const queryClient = useQueryClient();
    const {mutateAsync: createSession} = useCreateSession();
    const {mutateAsync: createPrompt} = useCreatePrompt();
    const {mutateAsync: createEvaluation} = useCreateEvaluation();

    return useMutation({
        mutationFn: async (body: CreateSessionWithPromptRequest) => {
            const {prompt: promptBody, ...sessionBody} = body;
            const session = await createSession({
                ...sessionBody,
                name: sessionBody.name ?? promptBody?.content ?? promptBody?.skillName ?? undefined,
                source: SourceType.Immersive,
            });
            // If a prompt was passed, also create a prompt and its evaluation too.
            if (session && promptBody) {
                const prompt = await createPrompt({
                    ...promptBody,
                    sessionId: session.sessionId,
                    source: SourceType.Immersive,
                });

                const evaluation = await createEvaluation({
                    sessionId: session.sessionId,
                    promptId: prompt.promptId,
                });
            }

            return session;
        },

        onSuccess: () => {
            queryClient.invalidateQueries(['sessions']);
        },
    });
}
