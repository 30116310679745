import {useEffect, useState} from 'react';
import useClasses from './NewHome.styles';
import {SkillDescriptor} from '@/api/skills';
import React from 'react';
import {useLayout} from '@/components/ui/Layout';
import {BackgroundAnimation, BackgroundImage} from '@/components/ui/Background';
import NewHomeSessions from './Sessions';
import ExploreCopilot from './ExploreCopilot';
import {PromptBar, ScreenName} from '@/components/ui';
import useAnalystHomeTour from '@/components/sections/tours/analyst-home/useAnalystHomeTour';
import useAdminPluginTour from '@/components/sections/tours/admin-plugins/useAdminPluginTour';

export default function Home() {
    const classes = useClasses();
    const {background} = useLayout();
    useAnalystHomeTour();
    useAdminPluginTour();

    const [defaultPromptSkill, setDefaultPromptSkill] = useState<SkillDescriptor | undefined>(
        undefined,
    );

    useEffect(() => {
        background?.setImage?.(BackgroundImage.triangles);
        // TODO: re-enable animation once we work out performance issues
        background?.setAnimation?.(BackgroundAnimation.none);

        return () => {
            background?.setImage?.(BackgroundImage.none);
            background?.setAnimation?.(BackgroundAnimation.none);
        };
    }, [background]);

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <NewHomeSessions />
                <ExploreCopilot />
            </div>
            <div className={classes.prompt}>
                <PromptBar
                    defaultSkill={defaultPromptSkill}
                    enableSkillsetConnector
                    enableSupportAssistance
                    screenName={ScreenName.Home}
                />
            </div>
        </div>
    );
}
