import type Fuse from 'fuse.js';
import type {SkillDescriptor} from '@/api/skills';
import type {PromptbookDescriptor} from '@/api/promptbooks';
import type {PreviewState} from '@/api/api.types';

export enum PromptBarMenuListType {
    Promptbooks = 'Promptbooks',
    SystemCapabilities = 'SystemCapabilities',
}

export interface PromptBarMenuProps {
    onSubmit?: (value: string) => void;
    onSkillSelect?: (skill: SkillDescriptor) => void;
    onPromptbookSelect?: (skill: PromptbookDescriptor) => void;
    onHide?: () => void;
    skillsets?: string[];
    open?: boolean;
}

export interface PromptBarMenuChildOptions {
    filter: string;
    skillsets?: string[];
    limit: number;
}

export interface PromptBarMenuChildProps<T> extends PromptBarMenuChildOptions {
    onSelect?: (item: T) => void;
    onShowAll?: () => void;
    shouldRepositionMenu: () => void;
    showAll: PromptBarMenuListType | null;
    tabIndex: number;
}

export interface PromptBarMenuChildListProps<T> extends PromptBarMenuChildProps<T> {
    onSelect?: (item: T) => void;
    onShowAll?: () => void;
    getName: (item: T) => string | null | undefined;
    getDescription: (item: T) => string | null | undefined;
    shouldRepositionMenu: () => void;
    noMatches: string;
    lists: PromptBarMenuLists<T>;
    header: React.ReactNode;
    getGroupLabel?: (key: string) => React.ReactNode;
}

export type PromptBarMenuLists<T> = {
    allList: Fuse<T>;
    filteredList: Array<Pick<Fuse.FuseResult<T>, 'item'>>;
    groupedList: {[key: string]: Array<T>};
    isLoading: boolean;
    skillsetPreviewStateMap?: {[key: string]: PreviewState | undefined};
};

export interface SkillData {
    skillName: string;
    displayName: string;
    placeholder: string;
    content: string;
}
