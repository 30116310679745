import Prompt from './Prompt';
import {PromptbookSectionProps} from './Prompt.types';
import useClasses from './PromptbookSection.styles';
import {useGetPromptbook, useGetPromptbookExecution} from '@/api/promptbooks';
import {Divider, mergeClasses} from '@fluentui/react-components';
import {useLayout} from '@/components/ui/Layout';
import {PromptbookIcon} from '@/components/ui/icons';
import useGetPrompts from '@/api/prompts/useGetPrompts';
import useSessionViewMode from '../sessions/useSessionViewMode';

export default function PromptbookSection({
    sessionId,
    promptbookExecutionId,
    isReadOnly,
    errorMessage,
    selection,
}: PromptbookSectionProps) {
    const classes = useClasses();
    const {contentArea} = useLayout();

    const {data: prompts} = useGetPrompts({sessionId});
    let promptbookPrompts = prompts?.value.filter(
        (prompt) => prompt.promptbookExecutionId === promptbookExecutionId,
    );

    const {data: promptbookExecution} = useGetPromptbookExecution({promptbookExecutionId});
    const promptbookId = promptbookExecution?.promptbookId || '';
    const {data: promptbookData} = useGetPromptbook({promptbookId});

    const promptbookTitle = promptbookData?.name;
    const {isFullWidth: contentModeFull} = useSessionViewMode();
    return (
        <div className={classes.scrollableList}>
            <div
                className={mergeClasses(classes.root, contentModeFull && classes.fullWidthRoot)}
                tabIndex={0}
            >
                <div className={classes.firstDiv}>
                    <PromptbookIcon className={classes.promptbookIcon} />
                </div>
                <div
                    data-tour-id="promptContent"
                    className={mergeClasses(
                        classes.content,
                        contentModeFull && classes.fullWidthContent,
                    )}
                >
                    <h2>{promptbookTitle}</h2>
                </div>
                <div className={classes.lastDiv}></div>
            </div>
            {promptbookPrompts?.map((prompt) => (
                <Prompt
                    key={prompt.promptId}
                    sessionId={prompt.sessionId}
                    promptId={prompt.promptId}
                    selection={selection}
                    isReadOnly={isReadOnly}
                    errorMessage={errorMessage}
                />
            ))}
            <div
                className={mergeClasses(classes.root, contentModeFull && classes.fullWidthRoot)}
                tabIndex={0}
            >
                <div className={classes.firstDiv}></div>
                <div
                    data-tour-id="promptContent"
                    className={mergeClasses(
                        classes.content,
                        contentModeFull && classes.fullWidthContent,
                    )}
                >
                    <Divider></Divider>
                </div>
                <div className={classes.lastDiv}></div>
            </div>
        </div>
    );
}
