import DataTable from './DataTable';
import {DimensionalHourlyAggregate} from '../UsageDashboard.types';
import {dimensionalAggregateTableColumns} from '../UsageDashboard.config';
import {Pagination} from '@/components/ui/Pagination/Pagination';
import {PaginationType} from '@/components/ui/Pagination/Pagination/Pagination.types';
import {ApiResponseDisplays, DEFAULT_PAGE_SIZE} from '../UsageDashboard.constants';
import {Spinner} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import {USAGE_DATA_LOADING} from '../UsageDashboard.constants';
import useClasses from './DataTable.styles';
import {ApiResponseDisplay} from '../ApiResponseDisplay/ApiResponseDisplay';
import {SortingDirection} from '@/api/api';
import {motion} from 'framer-motion';
import useDataTableV2Transitions from './useDataTableV2Transitions';

interface DataTableV2Props {
    data: DimensionalHourlyAggregate[];
    onPageNumberChange(pageNumber: number): void;
    totalDataCount: number;
    resetPagination: boolean;
    isTableLoading: boolean;
    sortDirection: SortingDirection;
    currentPage: number;
    onDateSortChanging: (sortDirection: SortingDirection) => Promise<void>;
}

export default function DataTableV2(props: DataTableV2Props) {
    const {t: tAdmin} = useTranslation('admin');
    const classes = useClasses();
    const {dataGridVariant} = useDataTableV2Transitions();
    const isDimensionalDataEmpty = !props.isTableLoading && props.data.length == 0;

    async function changeDateSort(e: any, setSortState: any, nextSortState: any) {
        try {
            await props.onDateSortChanging(nextSortState.sortDirection);
            setTimeout(() => {
                setSortState(nextSortState);
            }, 500);
        } catch (e) {
            console.log(e);
        }
    }

    function onTableSortChange(
        e: any,
        stateSetter: any,
        nextSortState: {sortColumn: string; sortDirection: SortingDirection},
    ) {
        if (Boolean(props?.onDateSortChanging)) {
            changeDateSort(e, stateSetter, nextSortState);
        }
    }
    return (
        <>
            {props.isTableLoading ? (
                <Spinner
                    labelPosition="below"
                    aria-live="polite"
                    aria-label={tAdmin(USAGE_DATA_LOADING)}
                    size="medium"
                    label={`${tAdmin(USAGE_DATA_LOADING)}`}
                    className={classes.loadingSpinner}
                />
            ) : isDimensionalDataEmpty ? (
                <motion.div
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    variants={dataGridVariant}
                    key="UsageDataTable"
                    className={classes.motionDiv}
                >
                    <ApiResponseDisplay
                        title={tAdmin(ApiResponseDisplays.NO_DIMENSIONAL_DATA.title)}
                        subtitle={tAdmin(ApiResponseDisplays.NO_DIMENSIONAL_DATA.subtitle)}
                        isError={false}
                    />
                </motion.div>
            ) : (
                <motion.div
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    variants={dataGridVariant}
                    key="UsageDataTable"
                >
                    <DataTable<DimensionalHourlyAggregate>
                        data={props.data}
                        columns={dimensionalAggregateTableColumns}
                        onSortChange={onTableSortChange}
                        sortingParameters={{
                            isSortable: true,
                            sortDirection: props.sortDirection,
                            sortColumn: [
                                {
                                    column: 'date',
                                    isAscending: false,
                                    shouldDisableDefaultSorting: true,
                                },
                            ],
                        }}
                    />
                </motion.div>
            )}

            {!isDimensionalDataEmpty && (
                <Pagination
                    mode={PaginationType.NumberDisplay}
                    onPageChange={(pageNumber: number) => props.onPageNumberChange(pageNumber)}
                    pageSize={DEFAULT_PAGE_SIZE}
                    recordCount={props.totalDataCount}
                    resetPagination={props.resetPagination}
                    disabled={props.isTableLoading}
                    hidden={props.isTableLoading}
                    overridenPageNumber={props.currentPage}
                ></Pagination>
            )}
        </>
    );
}
