import {
    Button,
    Popover,
    PopoverSurface,
    PopoverTrigger,
    PopoverProps,
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogContent,
    DialogActions,
} from '@fluentui/react-components';
import {DismissIcon, ShareIcon, SendIcon, LinkMultipleIcon} from '@/components/ui/icons';
import useClasses from './ExportInvestigationMailDialog.styles';
import {ExportInvestigationMailDialogProps} from './ExportInvestigationMailDialog.types';
import {useShareSession} from '@/api/sessions';
import {useCallback, useEffect, useState} from 'react';
import {LinkIcon} from '@/components/ui/icons';
import {useTourable} from '@/components/ui/Tour/useTourable';
import {MedeinaTelemetryEvent, useTrackEvent} from '@/api/telemetry';
import MedeinaFeatures from '@/util/features';
import PeoplePicker from '../sessions/PeoplePicker';
import {UserProfile} from '@/api/user/user.types';
import {useMsal} from '@azure/msal-react';
import useExportInvestigationSummaryEmail from '@/api/evaluations/useExportInvestigationSummaryEmail';
import {InvestigationExportMailRequest} from '@/api/evaluations/evaluations.types';

export * from './ExportInvestigationMailDialog.types';

// Component that we'll use to display the dialog to search and export the summary or investigation content
export default function ExportInvestigationMailDialog({
    sessionId,
    evaluationIds,
    sessionName,
    exportType,
    onClose = () => null,
    onSuccess = () => null,
    isOpen,
    onOpenChange,
}: ExportInvestigationMailDialogProps) {
    const tourRef = useTourable({id: 'shareButton'});
    const classes = useClasses();
    const [shareUrl, setShareUrl] = useState<string>('');
    const {mutate: shareSession} = useShareSession({sessionId});
    const {mutate: trackEvent} = useTrackEvent();
    const {mutate: sendEmail} = useExportInvestigationSummaryEmail({sessionId});
    const {instance} = useMsal();

    const handleOpenChange: PopoverProps['onOpenChange'] = useCallback(
        (e: any, data: {open: any}) => {
            shareSession(
                {sessionId},
                {
                    onSuccess: ({token}) => {
                        if (token && typeof window !== 'undefined') {
                            setShareUrl(
                                `${window.location.origin}/sessions/${sessionId}?st=${token}`,
                            );
                        }
                    },
                },
            );
        },
        [setShareUrl, shareSession, sessionId],
    );

    const handleCopyToClipboard = useCallback(() => {
        if (shareUrl && typeof window !== 'undefined') {
            window.navigator.clipboard.writeText(shareUrl);
            trackEvent({
                name: MedeinaTelemetryEvent.Sessions.ShareSession,
            });
        }
    }, [shareUrl]);

    useEffect(() => {
        if (shareUrl) {
            handleCopyToClipboard();
        }
    }, [shareUrl]);

    const PopoverContent = () => {
        return (
            <div className={classes.sharePopoverContainer}>
                <LinkIcon className={classes.sharePopoverIcon} />
                Link copied
            </div>
        );
    };

    const [userShareDetails, setUserShareDetails] = useState<UserProfile[]>([]);

    // Callback function to receive data from the child component
    const receiveDataFromPeoplePicker = (data: UserProfile[]) => {
        setUserShareDetails(data);
    };

    const handleSendEmail = () => {
        if (userShareDetails.length > 0) {
            const userRequests = userShareDetails.map((profile) => ({
                userId: profile.id,
                role: 'viewer',
                global: false,
                Email: profile.mail,
            }));

            const emailRequest: InvestigationExportMailRequest = {
                sessionId: sessionId,
                evaluationIds: evaluationIds,
                baseUrl: `${window.location.origin}/sessions/${sessionId}`,
                investigationName: sessionName,
                senderName: instance.getActiveAccount()?.name!,
                userRequest: userRequests,
                promptText: sessionName,
                summaryLink: `${window.location.origin}/sessions/${sessionId}`,
                evaluationLink: `${window.location.origin}/sessions/${sessionId}`,
                invitationType: exportType,
            };

            sendEmail(emailRequest);
            setUserShareDetails([]);
        }
    };

    if (!MedeinaFeatures.EmailToInvitedUser) {
        return (
            <Popover onOpenChange={handleOpenChange}>
                <PopoverTrigger disableButtonEnhancement>
                    <Button
                        ref={tourRef}
                        icon={<ShareIcon />}
                        appearance="transparent"
                        onClick={handleCopyToClipboard}
                        size="small"
                    >
                        Share
                    </Button>
                </PopoverTrigger>
                <PopoverSurface>
                    <PopoverContent />
                </PopoverSurface>
            </Popover>
        );
    } else {
        return (
            <Dialog open={isOpen} onOpenChange={() => onOpenChange(!isOpen)}>
                <DialogSurface className={classes.root}>
                    <DialogBody>
                        <DialogTitle
                            className={classes.header}
                            action={
                                <DialogTrigger action="close">
                                    <Button
                                        appearance="subtle"
                                        aria-label="close"
                                        icon={<DismissIcon />}
                                        onClick={onClose}
                                    />
                                </DialogTrigger>
                            }
                        >
                            Export : {sessionName}
                        </DialogTitle>
                        <DialogContent className={classes.content}>
                            <div>
                                <PeoplePicker
                                    className={classes.peoplepicker}
                                    sendDataToParent={receiveDataFromPeoplePicker}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions position="start">
                            <DialogTrigger disableButtonEnhancement>
                                <Button
                                    icon={<SendIcon />}
                                    className={classes.actionButton}
                                    appearance="primary"
                                    onClick={handleSendEmail}
                                >
                                    Send
                                </Button>
                            </DialogTrigger>
                            <DialogTrigger disableButtonEnhancement>
                                <Popover onOpenChange={handleOpenChange}>
                                    <PopoverTrigger disableButtonEnhancement>
                                        <Button
                                            icon={<LinkMultipleIcon />}
                                            className={classes.actionButton}
                                            appearance="secondary"
                                            onClick={handleCopyToClipboard}
                                        >
                                            Copy link
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverSurface>
                                        <PopoverContent />
                                    </PopoverSurface>
                                </Popover>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        );
    }
}
