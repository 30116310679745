import {useContext, useState} from 'react';
import {useGetPrompt} from '@/api/prompts';
import {DismissIcon} from '@/components/ui/icons';
import {
    Divider,
    Checkbox,
    Spinner,
    mergeClasses,
    Tooltip,
    Text,
    MessageBar,
    MessageBarBody,
    Link,
    MessageBarActions,
    Button,
    useRestoreFocusSource,
    useRestoreFocusTarget,
} from '@fluentui/react-components';
import {EvaluationLogs, Evaluation, EvaluationToolbar} from '@/components/sections/evaluations';
import {EvaluationState, useGetEvaluation, useIsEvaluationPinned} from '@/api/evaluations';
import useClasses, {promptHoverFocus} from './Prompt.styles';
import {EditMode, PromptProps} from './Prompt.types';
import {useMemo} from 'react';
import PromptContent from './PromptContent';
import {Context as TourContext} from '@/components/ui/Tour';
import {useTourable} from '@/components/ui/Tour/useTourable';
import {EvaluationProvider} from '../evaluations/EvaluationContext';
import PromptActionButtons from './PromptActionButtons';
import {useTranslation} from 'react-i18next';
import MedeinaFeatures from '@/util/features';
import EvaluationToolbarNew from '../evaluations/EvaluationToolbar/EvaluationToolbarNew';
import {ErrorMessage} from '@/api/errors.types';
import useViewportSize from '@/components/ui/Grid/useViewportSize';
import useSessionViewMode from '../sessions/useSessionViewMode';

export * from './Prompt.types';

export default function Prompt({
    sessionId,
    promptId,
    selection,
    isReadOnly,
    errorMessage,
}: PromptProps) {
    const {t} = useTranslation('session');
    const tourRef = useTourable({id: 'promptContent'});
    const classes = useClasses();
    const {activeTour} = useContext(TourContext);
    const [errMessage, setErrMessage] = useState<ErrorMessage | undefined>(errorMessage);
    const {data: prompt} = useGetPrompt({sessionId, promptId});
    const evaluationId = prompt?.latestEvaluationId;
    const isPinned = useIsEvaluationPinned({sessionId, evaluationId});
    const {data: evaluation} = useGetEvaluation({
        sessionId,
        promptId,
        evaluationId,
    });
    const restoreFocusSource = useRestoreFocusSource();
    const restoreFocusTarget = useRestoreFocusTarget();
    const {sm: isSmallScreen} = useViewportSize();

    // Set prompt bar type
    const [editMode, setEditMode] = useState<EditMode>(false);

    // Selection handling.
    const selected = useMemo(() => {
        return selection?.selectedRows.has(promptId) || false;
    }, [selection?.selectedRows, promptId]);

    const someSelected = useMemo(() => {
        return selection?.someRowsSelected || false;
    }, [selection?.someRowsSelected]);

    const checkboxLabel = useMemo(() => {
        return selected ? t('DeselectPromptAndEvaluation') : t('SelectPromptAndEvaluation');
    }, [selected, t]);

    const visuallyHiddenText = useMemo(() => {
        let content = '';
        // Leaving this commented in case we need it for accessibility, currently screen reader will announce this twice outloud when the outer wrapper is selected
        // if (prompt?.skillName) {
        //     // first test to see if it is one word and is cammelcased: e.g. "SkillNameIsOneWord"
        //     if (prompt?.skillName.match(/^[A-Z][a-z]+(?:[A-Z][a-z]+)*$/)) {
        //         // skill name might be cammelcased so we need to split it
        //         content = prompt?.skillName.split(/(?=[A-Z])/).join(' ');
        //     } else {
        //         content = prompt?.skillName;
        //     }
        // } else {
        //     content = prompt?.content;
        // }
        return content;
    }, [prompt]);
    const {isFullWidth: contentModeFull} = useSessionViewMode();
    return (
        <EvaluationProvider {...{sessionId, promptId, evaluationId}}>
            <div
                className={mergeClasses(classes.root, contentModeFull && classes.fullWidthRoot)}
                tabIndex={0}
            >
                <div
                    className={mergeClasses(
                        classes.checkbox,
                        someSelected && classes.selected,
                        promptHoverFocus,
                    )}
                >
                    <Tooltip
                        content={
                            <>
                                {checkboxLabel}
                                <div className={classes.visuallyHidden}>{visuallyHiddenText}</div>
                            </>
                        }
                        relationship="label"
                        positioning="below"
                    >
                        <Checkbox
                            size="medium"
                            checked={selected}
                            onClick={(e) => selection?.toggleRow(e, promptId)}
                            disabled={!!isReadOnly}
                            data-testid={'prompt-checkbox'}
                        />
                    </Tooltip>
                </div>
                <div
                    // ref={tourRef}
                    data-tour-id="promptContent"
                    className={mergeClasses(
                        classes.content,
                        contentModeFull && classes.fullWidthContent,
                        isPinned && classes.pinnedPrompt,
                    )}
                >
                    <PromptContent
                        sessionId={sessionId}
                        promptId={promptId}
                        editMode={editMode}
                        setEditMode={setEditMode}
                        setErrorMessage={setErrMessage}
                        restoreFocusSourceAttribute={restoreFocusSource}
                    />
                    {errMessage && (
                        <MessageBar intent="error">
                            <MessageBarBody>
                                {errMessage?.message}
                                {errMessage?.learnMoreText && errMessage?.learnMoreUri && (
                                    <>
                                        {' '}
                                        <Link href={errMessage.learnMoreUri}>
                                            {errMessage.learnMoreText}
                                        </Link>
                                    </>
                                )}
                            </MessageBarBody>
                            <MessageBarActions
                                containerAction={
                                    <Button
                                        onClick={() => setErrMessage(undefined)}
                                        aria-label={t('DismissButton')}
                                        appearance="transparent"
                                        icon={<DismissIcon />}
                                    />
                                }
                            />
                        </MessageBar>
                    )}
                    {evaluationId && !prompt?.__isQueued ? (
                        <>
                            <>
                                <EvaluationLogs {...{sessionId, promptId, evaluationId}} />
                            </>

                            {
                                <div className={classes.evaluation}>
                                    <Evaluation
                                        sessionId={sessionId}
                                        promptId={promptId}
                                        evaluationId={evaluationId}
                                        isReadOnly={isReadOnly}
                                    />
                                </div>
                            }

                            {/* Do not show the tool bar panel while loading */}
                            {!isReadOnly && evaluation?.state !== EvaluationState.Running && (
                                <>
                                    <Divider appearance="subtle" className={classes.divider} />
                                    {MedeinaFeatures.ShowNewEvaluationToolbar ? (
                                        <EvaluationToolbarNew
                                            onEdit={setEditMode}
                                            setErrMessage={setErrMessage}
                                        />
                                    ) : (
                                        <EvaluationToolbar
                                            {...{sessionId, promptId, evaluationId}}
                                        />
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <Spinner className={classes.spinner} />
                    )}
                </div>
                {!isReadOnly && !isSmallScreen && (
                    <PromptActionButtons
                        restoreFocusTargetAttribute={restoreFocusTarget}
                        onEdit={setEditMode}
                        setErrMessage={setErrMessage}
                    />
                )}
            </div>
        </EvaluationProvider>
    );
}
