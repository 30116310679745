import {NavigationBarHeight} from '@/components/App.styles';
import {makeStyles, shorthands} from '@fluentui/react-components';
import {tokens} from '@fluentui/react-components';
export const promptHoverFocus = 'promptHoverFocus';

export default makeStyles({
    promptbookIcon: {
        width: tokens.spacingHorizontalXL,
        color: tokens.colorBrandForeground1,
        height: 'auto',
    },
    scrollableList: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        width: '100%',
        justifyContent: 'center',
        position: 'relative', // Relative position for absolute positioning of sticky title
    },
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: tokens.spacingVerticalXXXL,
        width: '100%',
        [`&:hover .${promptHoverFocus}, &:focus-within .${promptHoverFocus}`]: {
            visibility: 'visible',
            opacity: 1,
        },
    },
    fullWidthRoot: {
        ...shorthands.padding(0, 0, 0, tokens.spacingHorizontalXXL),
        justifyContent: 'flex-start',
        overflowX: 'hidden',
    },
    content: {
        ...shorthands.borderRadius('10px'),
        ...shorthands.flex(1, 1, 'auto'),
        boxShadow: tokens.shadow8,
        // we add a margin to account for the border width, this helps align the content with the prompt bar
        maxWidth: '700px',
        marginLeft: '16px',
    },
    fullWidthContent: {
        maxWidth: '100%',
        marginRight: tokens.spacingHorizontalXXXL,
    },
    firstDiv: {
        width: '96px',
        display: 'flex',
        justifyContent: 'right',
        alignContent: 'center',
    },
    lastDiv: {
        width: '40px',
    },
});
